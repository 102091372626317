<template>
<v-menu v-model="menu" :disabled="!ConnectCRM"
        offset-y open-on-hover
        :close-on-content-click="false" :nudge-width="200" :nudge-left="100">
<template v-slot:activator="{ on, attrs }">
  <v-chip v-bind="attrs" v-on="on"
          label dark
          class="custom-transform-class text-none"
          :color="getActiveColor(!customer.archived)">
    {{getActive(!customer.archived)}}
  </v-chip>
</template>

<v-card>
  <v-card-text class="text-center">{{texts.statusDetails}}</v-card-text>

  <v-divider></v-divider>
  <v-card-text>
    <v-row>
      <v-col class="text-center">
        {{texts.statusInternal}}<br>
        <v-chip dark label ripple
                class="ma-2" :color="getActiveColor(!customer.archived)">
          <v-icon left>
            {{ !customer.archived ? "mdi-check-bold" : "mdi-close-thick" }}
          </v-icon>
          {{ !customer.archived ? texts.active : texts.inactive}}
        </v-chip>
      </v-col>
      <v-col class="text-center">
        {{texts.statusExternal}}<br>
        <v-chip dark label ripple
                class="ma-2" :color="getActiveColor(customer.active)">
          <v-icon left>
            {{ customer.active ? "mdi-check-bold" : "mdi-close-thick" }}
          </v-icon>
          {{ customer.active ? texts.active : texts.inactive}}
        </v-chip>
      </v-col>
    </v-row>
  </v-card-text>
</v-card>
</v-menu>
</template>

<script>
import {getJson} from "@/store/get";
import {mapGetters} from "vuex";

export default {
  name: "CustomerStatus",
  props: ["customer"],
  data() {
    return {
      menu: false,
      style: {
        active: "green darken-1",
        inactive: "red darken-3"
      },
      texts: {}
    }
  },
  created() {
    this.getTexts()
  },
  computed: {
    ...mapGetters({ User: "StateUser", ConnectCRM: "StateConnectCRM", }),
  },
  methods: {
    getActive(active){
      if (active)
        return this.texts.active
      else
        return this.texts.inactive
    },
    getActiveColor(active){
      if (active)
        return this.style.active
      else
        return this.style.inactive
    },
    getTexts() {
      if (this.User) {
        getJson("/text/customer_status/" + this.User.language.id).then((resp) => {
          if (resp?.status === 200) {
            this.texts = resp.data.CustomerStatusView[0]
          }
        })
      }
    }
  },
  watch: {
    "User": function () {
      this.getTexts()
    },
    "User.language": function () {
      this.getTexts()
    },
  },
}
</script>

<style scoped>
.custom-transform-class{
  text-transform: uppercase
}
</style>