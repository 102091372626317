<template>
  <v-dialog v-model="dialog" max-width="40%">
    <template v-slot:activator="{ on, attrs }">
      <v-btn v-bind="attrs" v-on="on"
             color="red" class="text-none custom-transform-class"
             :disabled="!Role.access_parameters || isAdmin()" :dark="Role.access_parameters && !isAdmin()" :text="Role.access_parameters && !isAdmin()"
             @click="reset()">
        {{ texts.delete }}
        <v-icon right>mdi-account-remove-outline</v-icon>
      </v-btn>
    </template>
    <v-card>
      <v-card-title class="mb-2 center-text">
        <span class="text-h5">{{ texts.delete_title }}</span>
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text class="my-4">
        <v-container>
          <v-row>
            {{ texts.confirm_delete }}: {{ user.firstname }} {{ user.lastname }}
          </v-row>
        </v-container>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions class="mt-2">
        <v-btn depressed large dark
               class="text-none custom-transform-class" color="red darken-1"
               :loading="loading" :disabled="!Role.access_parameters"
               @click="deleteUser()">
          {{ texts.delete }}
          <template v-slot:loader>
            <v-progress-circular light indeterminate
                                 :size="30" :width="3" color="white">
              {{timer}}
            </v-progress-circular>
          </template>
        </v-btn>
        <v-btn depressed large dark
               class="text-none custom-transform-class" color="blue darken-1"
               @click="dialog = false">{{ texts.cancel }}</v-btn>
        <v-spacer></v-spacer>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters } from "vuex";
import { deleteJson } from "@/store/delete";

export default {
  name: "DeleteUser",
  props: ['user', 'texts'],
  data() {
    return {
      grantedColor: "green accent-4",
      errorColor: "red accent-4",
      showGranted: false,
      showError: false,
      loading: true,
      loadingColor: "blue",
      timer: 4,

      dialog: false,
    };
  },
  computed: {
    ...mapGetters({ Role: "StateRole" })
  },
  created() {
    this.reset()
  },
  methods: {
    reset(){
      this.resetTimer()
    },

    resetTimer(){
      this.loading = true
      this.timer = 4
      this.timerClock()
    },
    timerClock() {
        if (this.timer > 1) {
          this.timer -= 1
          setTimeout(() => {this.timerClock()}, 600)
        } else {
          this.loading = false
        }
    },
    isAdmin() {
      return this.user.role.is_admin
    },
    deleteUser() {
      const deletedUser = {
        id: this.user.ID,
      }

      deleteJson("/user/" + deletedUser.id, deletedUser).then((resp) => {
        if (resp && resp.status === 200) {
          this.showGranted = true
          this.$emit("user-deleted", deletedUser.id)
        } else {
          this.showError = true
        }
      }).catch(() => {}).then(() => {
        this.loading = false
        setTimeout(() => {this.showGranted = false}, 1500)
        setTimeout(() => {this.showError = false}, 1500)
      })

      this.dialog = false
    }
  },
};
</script>
<style scoped>
.custom-transform-class{
  text-transform: uppercase
}
</style>