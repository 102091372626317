<template>
	<div>
		<div style="width: 650px;box-sizing: border-box;margin: 30px auto auto;padding: 25px" color="digibackground">
			<div style="color: #2247d9;font-weight: bold;margin-bottom: 7px">{{texts.export_to_csv_format}}</div>
			<div>
				<div style="color: #8d8d8d;margin-bottom: 0">{{ texts.start_and_end_dates}}</div>
				<div style="display: flex;">
					<div style="align-self: center; margin-top: 10px">
						<v-menu
							v-model="datePicker1"
              offset-y
              transition="scale-transition" min-width="auto"
              :close-on-content-click="false"
							:nudge-right="40">
							<template v-slot:activator="{ on, attrs }">
								<v-text-field
                    v-bind="attrs" v-on="on"
                    v-model="date"
                    readonly clearable
                    prepend-icon="mdi-calendar" style="width: 200px"
                    :label="texts.start_date"/>
							</template>
							<v-date-picker
								v-model="date"
								:locale="fixedLocale"
								@input="datePicker1 = false"/>
						</v-menu>
					</div>
					<div style="align-self: center; margin-left: 10px; margin-bottom: 8px">
						<v-checkbox
							v-model="checkbox1"
							hide-details
							class="shrink mb-2"
							:label="texts.entire_day"/>
					</div>
					<div v-if="!checkbox1" style="align-self: center; margin-left: 10px; margin-top: 10px">
						<v-menu
							v-model="timePicker1"
              offset-y
              ref="menu" transition="scale-transition" max-width="290px" min-width="290px"
							:close-on-content-click="false" :nudge-right="40" :return-value.sync="time">
							<template v-slot:activator="{ on, attrs }">
								<v-text-field
                    v-bind="attrs" v-on="on"
                    v-model="time"
                    readonly
                    prepend-icon="mdi-clock-time-four-outline"/>
							</template>
							<v-time-picker
								v-if="timePicker1"
								v-model="time"
								full-width
								@click:minute="$refs.menu.save(time)"/>
						</v-menu>
					</div>
				</div>
				<div style="display: flex;">
					<div style="align-self: center; margin-top: 10px">
						<v-menu
							v-model="datePicker2"
              offset-y
              transition="scale-transition" min-width="auto"
							:close-on-content-click="false" :nudge-right="40">
							<template v-slot:activator="{ on, attrs }">
								<v-text-field
                    v-bind="attrs" v-on="on"
                    v-model="date2"
                    readonly clearable
                    prepend-icon="mdi-calendar" style="width: 200px"
                    :label="texts.end_date"/>
							</template>
							<v-date-picker
								v-model="date2"
								:locale="fixedLocale"
								@input="datePicker2 = false"/>
						</v-menu>
					</div>
					<div style="align-self: center; margin-left: 10px; margin-bottom: 8px">
						<v-checkbox
							v-model="checkbox2"
							hide-details
							class="shrink mb-2"
							:label="texts.entire_day"/>
					</div>
					<div v-if="!checkbox2" style="align-self: center; margin-left: 10px; margin-top: 10px">
						<v-menu
                v-model="timePicker2"
                offset-y
                ref="menu2" transition="scale-transition" max-width="290px" min-width="290px"
                :close-on-content-click="false" :nudge-right="40" :return-value.sync="time2">
							<template v-slot:activator="{ on, attrs }">
								<v-text-field
                    v-bind="attrs" v-on="on"
                    v-model="time2"
                    readonly
                    prepend-icon="mdi-clock-time-four-outline"/>
							</template>
							<v-time-picker
								v-if="timePicker2"
								v-model="time2"
								full-width
								@click:minute="$refs.menu2.save(time2)"/>
						</v-menu>
					</div>
				</div>
				<div>{{ texts.data }}</div>
				<v-radio-group v-model="radioGroup" row>
          <v-radio :label="texts.clients" value="Clients"></v-radio>
          <v-radio :label="texts.sales" value="Sales"></v-radio>
          <v-radio v-if="Config.contractsTab" :label="texts.contracts" value="Contracts"></v-radio>
          <v-radio :label="texts.validations" value="Validations"></v-radio>
          <v-radio v-if="Config.exportTransactions" :label="texts.transactions" value="Transactions"></v-radio>
          <v-radio v-if="Config.exportActivations" :label="texts.activations" value="Activations"></v-radio>
				</v-radio-group>
				<v-btn color="primary" class="pa-2" @click="getData" :disabled="!(date !== null && date2 !== null)" :loading="loadingData">
					<v-icon class="mr-2">mdi-tray-arrow-down</v-icon>
					<span class="text-none">{{ texts.export }}</span>
				</v-btn>
			</div>
		</div>
    <v-dialog width="500" v-model="dialogIsActive">
      <template>
        <v-card>
          <v-card-text style="text-align: center; font-size: 1.1em;">
            {{ texts.time_limit }}
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn @click="dialogIsActive = false">
              {{ texts.close }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </template>
    </v-dialog>
	</div>
</template>

<script>
import {postJson} from "@/store/post";
import {mapGetters} from "vuex";
import {getJson} from "@/store/get";

export default {
	name: "Export",
	data() {
		return {
			texts: {},
			fixedLocale: null,
			radioGroup: 'Clients',
			checkbox1: true,
			checkbox2: true,
			date: null,
			date2: null,
			datePicker1: false,
			datePicker2: false,
			time: null,
			time2: null,
			timePicker1: false,
			timePicker2: false,
			data: null,
			loadingData: false,
			loaded: false,
      dialogIsActive: false
		}
	},
	computed: {
		...mapGetters({ User: "StateUser", Locale: "StateLocale", Config: "StateConfig"})
	},
	created() {
		this.fixedLocale = this.Locale.replace('_', '-')
		this.getTexts()
	},
	methods: {
		getTexts() {
			if (this.User) {
				getJson("/text/export/" + this.User.language.id).then((resp) => {
					if (resp?.status === 200) {
						this.texts = resp.data.ExportView[0]
					}
				})
			}
		},
		getData() {
			if (this.loadingData) {
				return
			}
			this.loadingData = true

			let dataToSend = {
				"specific-date": this.date,
				"second-specific-date": this.date2,
				"export-data": this.radioGroup
			}
			if (!this.checkbox1 && this.time != null) {
				dataToSend["specific-time"] = this.time
			}
			if (!this.checkbox2 && this.time2 != null) {
				dataToSend["second-specific-time"] = this.time2
			}

			this.loadingData = true
      if (!this.Config.exportFromSFTP) {
        postJson("/statistics/export", dataToSend).then((resp) => {
          if (resp?.status === 200) {
            let csvArray = []
            let csvContent = []
            let headersArray = []
            let dataArray = []
            if (this.radioGroup === "Sales" || this.radioGroup === "Contracts" || this.radioGroup === "Clients") {
              headersArray = resp.data.mvad_export.headers
              dataArray = resp.data.mvad_export.data
            } else if (this.radioGroup === "Validations") {
              headersArray = resp.data.mdu_export.headers
              dataArray = resp.data.mdu_export.data
            }
            csvArray.push(headersArray)
            dataArray.forEach((item) => {
              csvArray.push(item)
            })
            csvContent = csvArray.join("\n")
            this.downloadCsv(csvContent, "Mobeepass_export_"
                + this.radioGroup.toLowerCase() + "_"
                + dataToSend["specific-date"].split('-').join('') + "_"
                + dataToSend["second-specific-date"].split('-').join('')
                + ".csv", "text/csv;charset=utf-8;")
          }
          this.loadingData = false
        })
      }
      else if (this.Config.exportFromSFTP) {
        let currentDate = new Date();
        let previousDate = new Date(this.date)
        let previousDate2 = new Date(this.date2)
        let diff = currentDate.getTime() - previousDate.getTime()
        let diffDays = Math.floor(diff / (1000 * 60 * 60 * 24))
        let diff2 = currentDate.getTime() - previousDate2.getTime()
        let diffDays2 = Math.floor(diff2 / (1000 * 60 * 60 * 24))
        if (diffDays <= 31 && diffDays2 <= 31) {
          postJson("/statistics/export-sftp", dataToSend, "arraybuffer").then((resp) => {
            if (resp?.status === 200) {
              const url = window.URL.createObjectURL(new Blob([resp.data], {type: "application/zip"}));
              const link = document.createElement('a');
              link.href = url;
              let fileName = "Mobeepass_export_"
                  + this.radioGroup.toLowerCase() + "_"
                  + dataToSend["specific-date"].split('-').join('') + "_"
                  + dataToSend["second-specific-date"].split('-').join('')
                  + ".zip"
              link.setAttribute('download', fileName);
              document.body.appendChild(link);
              link.click();
            }
            this.loadingData = false
          })
        }
        else {
          this.dialogIsActive = true
          this.loadingData = false
        }
      }
		},
		downloadCsv(content, filename, contentType) {
			const blob = new Blob([content], { type: contentType })
			const url = URL.createObjectURL(blob)
			let pom = document.createElement('a')
			pom.href = url
			pom.setAttribute('download', filename)
			pom.click();
		},
	},
	watch: {
		"User.language": function() {
			this.fixedLocale = this.Locale.replace('_', '-')
			this.getTexts()
		}
	}
}
</script>

<style scoped>

</style>
