<template>
  <v-card height="100%"
          class="mx-0  my-auto pa-2 transition-swing card rounded-0 overflow-y-auto overflow-x-hidden" elevation="0"
          style="overflow: hidden !important; scrollbar-width: none;-ms-overflow-style: none; position: relative!important;">
    <v-card-title class="blue--text text-center pt-0 pb-4 px-2">
      <v-row>
        <v-col cols="7" class="px-0" >
          <p v-if="customer['first-name']" style="word-break: break-word">{{ customer["first-name"].split(" ")[0] }} - {{ customer["last-name"] }}</p>
        </v-col>
        <v-col style="min-width: 60px; max-width: 80px;" class="px-2"><customer-status :customer="customer"/></v-col>
        <v-col class="px-0"><customer-actions :wallets="wallets" :customer="customer" :texts="texts"/></v-col>
      </v-row>
    </v-card-title>
    <v-row>
      <v-col>
        <v-card-text class="pb-0 pl-1 pr-0" style="display: inline-flex!important;"><div class="pr-1">{{texts.clientId}}: </div><format-clipboard :text="getClientId()" match="10"></format-clipboard></v-card-text>
        <v-card-text v-if="Config.idMobib" class="py-0 pl-1 pr-0" style="display: inline-flex!important;"><div class="pr-1">{{texts.mobibId}}: </div><format-clipboard :hide_icon="true" :text="getRemoteCustomerReference()" match="3"></format-clipboard></v-card-text>
        <v-card-text v-if="Config.idTec && isLocalToService()" class="pt-0 pl-1 pr-0" style="display: inline-flex!important;"><div class="pr-1">{{texts.tecId}}: </div><format-clipboard :text="customer.pto_internal_reference ? String(customer.pto_internal_reference.String) : texts.unknown" match="3"></format-clipboard></v-card-text>
      </v-col>
      <v-col cols="2" v-if="customerCompanyIcon"><v-img class="my-8"
          max-height="400"
          max-width="422"
          :src="getImgSrc(getCustomerCompanyIcon())"></v-img></v-col>
    </v-row>

    <v-spacer></v-spacer>
    <v-card-text v-if="customer.birthdate!=='' && isLocalToService()" class="pb-0 pl-1 pr-0">
      {{ iso8601ToStandard(customer.birthdate) }}
      <v-chip pill ripple small
              style="user-select: none;" class="mx-2 mb-1 font-weight-bold"
              :color="get26Color(customer.birthdate)" :dark="test26(customer.birthdate)">{{get26(customer.birthdate)}}</v-chip>
    </v-card-text>
    <v-card-text class="py-0 pl-1 pr-0" v-if="isLocalToService()">{{customer.email}}</v-card-text>
    <v-card-text v-if="customer['phone-number'] != '' && isLocalToService()" class="pt-0 pl-1 pr-0"><format-clipboard :text="customer['phone-number']" match="2"></format-clipboard></v-card-text>
    <v-spacer></v-spacer>

    <v-card-text v-if="Config.subscription" class="pb-0 pl-1 pr-0" >{{texts.abo}}: {{!!customer.subscription ? customer.subscription : texts.no_subscription }}</v-card-text>
    <v-card-text v-if="isLocalToService()" class="py-0 pl-1 pr-0" style="display: inline-flex!important;"><div>{{texts.total}}: {{(customer.total !== 0 ? (displayMoney(customer.total) + "€").replace('.', ',') : texts.no_order)}}</div></v-card-text>

    <v-card-text class="pt-0 pl-1 pr-0">{{texts.creationDate}}: {{ utcToStandard(getCreation_date()) }}</v-card-text>

    <v-divider></v-divider>

    <v-card-text class="text-h6 px-1" style="padding-bottom: 4px !important;padding-top: 4px !important;">{{texts.wallets}} : {{wallets.length}}</v-card-text>

    <v-list class="py-0 pr-0 pl-1 overflow-y-auto" style="max-height: 570px; direction: rtl; text-align: left; scrollbar-width: thin;">
      <v-list-item class="px-0 ma-0"
                   v-for="(item, i) in wallets" :key="item.calypsoNumber">
        <wallet-card fluid
                     :class="i===0? 'px-0 mt-0 mb-1': 'px-0 my-1'"  @clicked="walletClicked" :wallet="item" :customer="customer"
                     :invalidatable="i===0 && selectedCalypsoNumber===item.calypsoNumber" :selected="selectedCalypsoNumber===item.calypsoNumber" :texts="texts" :status="status"></wallet-card>
      </v-list-item>
    </v-list>

  </v-card>

</template>

<script>
import {getJson} from "@/store/get";
import * as utils from "@/utils";
import CustomerStatus from "@/components/customer/CustomerStatus";
import WalletCard from "@/components/customer/WalletCard";
import FormatClipboard from "@/components/FormatClipboard";
import {mapGetters} from "vuex";
import {bus} from "@/components/customer/bus";
import CustomerActions from "@/components/customer/CustomerActions";

export default {
  name: "CustomerCard",
  components:{
    CustomerActions,
    CustomerStatus,
    WalletCard,
    FormatClipboard,
  },
  data() {
    return {
      logos: [],
      bmc: require("@/assets/bmc.png"),
      delijn: require("@/assets/delijn.png"),
      sncb: require("@/assets/sncb.png"),
      stib: require('@/assets/stib.png'),
      tec: require("@/assets/tec.png"),
      tisseo: require("@/assets/tisseo.png"),
      rla: require("@/assets/rla.png"),
      customer: {},
      selectedCalypsoNumber: null,
      raw_wallets: null,
      wallets: [],
      texts: {},
      status: [],
      hasOrder: false,
    }
  },
  created() {
    this.getTexts()
    this.getCustomer()
    this.getLogos()
  },
  computed: {
    ...mapGetters({ User: "StateUser", Role: "StateRole", SearchW: "StateSearch", SearchOne: "StateSearchOne",
      SearchTwo: "StateSearchTwo", SearchBirth: "StateSearchBirth", Config: "StateConfig" }),
    access_delete_customer() {
      return this.Role && this.Role.access_delete_customer
    },
    customerCompanyIcon() {
      return this.Config.customerCompanyIcon
    }
  },
  methods: {
    getLogos() {
      getJson("/logos").then((resp) => {
        if (resp?.status === 200) {
          this.logos = resp.data.LogoArray
        }
      })
    },
    getImgSrc(company_id) {
      if (this.Config.customerCompanyIcon) {
        let i = 0
        for (i=0; i<this.logos.length; i++) {
            if (this.logos[i].ServiceID === parseInt(this.$route.params.service_id) &&
                this.logos[i].CompanyID === company_id) {
                return this[this.logos[i].Logo]
            }
        }
        return this[this.Config.logo]
      }
    },
    walletClicked(data) {
      this.selectedCalypsoNumber = data
      bus.$emit("updateCalypsoNumber", this.selectedCalypsoNumber)
    },
    test26(birthdate) {
      const tmp = new Date(birthdate)
      const diffTime = Math.abs(tmp - new Date());
      const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
      return Math.ceil(diffDays / 365) < 26
    },
    displayMoney(cts) {
      let resp = ""+cts/100
      if (resp.indexOf('.') !== -1 && resp.indexOf('.') === resp.length-2) {
        resp += "0"
      }
      return resp
    },
    get26(birthdate) {
      if (this.Config.app === "rla") {
        const diffTime = new Date() - new Date(birthdate);
        const years = new Date(diffTime).getFullYear() - 1970;
        if (years < 18) {
          return "-18"
        } else if (18 <= years && years < 26) {
          return "18-25"
        } else if (26 <= years && years < 65) {
          return "26-64"
        } else {
          return "65+"
        }
      } else {
        if (this.test26(birthdate)) {
          return "-26"
        } else {
          return "+26"
        }
      }
    },
    get26Color(birthdate) {
      if (this.test26(birthdate)) {
        return "cyan"
      } else {
        return "amber"
      }
    },
    formatWalletArray() {
      this.wallets = JSON.parse(JSON.stringify( this.raw_wallets ))

      if (this.wallets?.length > 0) {
        this.wallets = this.wallets.sort(function (a, b) {
          return (new Date(b.creationDate)) - (new Date(a.creationDate));
        });

        this.wallets?.forEach((el) => {
          if (el?.purchases && parseInt(el.purchases) !== 0) this.hasOrder = true
        })
        if (this.SearchW === "idPurchase") {
          this.wallets?.forEach((el) => {
            if (el.purchased_history_coupon_list) {
              el.purchased_history_coupon_list.forEach((ph) => {
                if ("" + ph.purchased_history_id === this.SearchOne) {
                  this.selectedCalypsoNumber = ph.calypso_number
                }
              })
            }
          })
        } else if (this.SearchW === "authNumber") {
          this.wallets?.forEach((el) => {
            if (el.purchased_history_coupon_list) {
              el.purchased_history_coupon_list.forEach((ph) => {
                if (ph.authorization_number.String === this.SearchOne) {
                  this.selectedCalypsoNumber = ph.calypso_number
                }
              })
            }
          })
        } else if (this.SearchW === "hexaWallet") {
          this.wallets?.forEach((el) => {
            if (el.calypsoNumber === this.SearchOne) {
              this.selectedCalypsoNumber = el.calypsoNumber
            }
          })
        } else if (this.SearchW === "deciWallet") {
          this.wallets?.forEach((el) => {
            if (el.calypsoNumber === parseInt(this.SearchOne, 10).toString(16)) {
              this.selectedCalypsoNumber = el.calypsoNumber
            }
          })
        } else {
          this.selectedCalypsoNumber = this.wallets[0].calypsoNumber
        }
        bus.$emit("updateCalypsoNumber", this.selectedCalypsoNumber)
      }
    },
    getWalletCard(walletCardArray) {
      if (this.$route.params.service_id !== ""+this.Config.ServiceId) {
        this.raw_wallets = walletCardArray
      } else {
        getJson('/wallet-card/' + this.$route.params.service_id + '/' + this.$route.params.client_id).then((resp) => {
          if (resp?.status === 200) {
            this.raw_wallets = resp.data
          }
        })
      }
    },
    getTexts() {
      if (this.User) {
        getJson("/text/card/" + this.User.language.id).then((resp) => {
          if (resp && resp.status === 200) {
            this.texts = resp.data.CardView[0]
          }
        })
        getJson("/text/calypso_status/" + this.User.language.id).then((resp) => {
          if (resp && resp.status === 200) {
            this.status = resp.data.CalypsoStatusView
          }
        })
      }
    },
    getCustomer() {
      getJson('/customer/' + this.$route.params.service_id + '/' + this.$route.params.client_id).then((resp) => {
        if (resp && resp.status === 200) {
          this.customer = resp.data
          this.getWalletCard(resp.data.WalletCardArray)
        }
      }).catch((err) => {
        console.log(err)
      })
    },
    getClientId() {
        if (this.$route.params.service_id === ""+this.Config.ServiceId) {
            return this.customer['client_id']
        } else {
            return this.customer?.CustomerCardView?.client_id
        }
    },
    getRemoteCustomerReference() {
        if (this.$route.params.service_id === ""+this.Config.ServiceId) {
            return this.customer.remote_customer_reference ? String(this.customer.remote_customer_reference.String) : this.texts.unknown
        } else {
            return this.customer?.CustomerCardView?.remote_customer_reference ? String(this.customer.CustomerCardView.remote_customer_reference.String) : this.texts.unknown
        }
    },
    getCustomerCompanyIcon() {
        if (this.$route.params.service_id === ""+this.Config.ServiceId) {
            return this.customer.company_id
        } else {
            return this.customer?.CustomerCardView?.company_id
        }
    },
    getCreation_date() {
        if (this.$route.params.service_id === ""+this.Config.ServiceId) {
            return this.customer.creation_date
        } else {
            return this.customer?.CustomerCardView?.creation_date
        }
    },
    isLocalToService() {
        return this.$route.params.service_id === ""+this.Config.ServiceId
    },
    utcToStandard(utc) {
      return utils.utcToStandard(utc)
    },
    iso8601ToStandard(t) {
      return utils.iso8601ToStandard(t)
    }
  },
  watch: {
    "User.language": function () {
      this.getTexts()
    },
    raw_wallets() {
      this.formatWalletArray()
    }
  },
}
</script>

<style scoped>
.custom-transform-class{
  text-transform: uppercase
}
.card {
  overflow-y: auto !important;
}
</style>