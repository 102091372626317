export function GetLanguage(languages, user){
    let i = 0
    let response = ""
    if (languages == null) {
        return
    }
    for (i; i< languages.length; i++) {
        if (languages[i].id === user.language) {
            response += languages[i].text
        }
    }
    return response
}

export function GetLanguageId(languages, tmpUser, user){
    let i = 0
    if (languages == null) {
        return
    }

    for (i; i< languages.length; i++) {
        if (languages[i].text === tmpUser.language) {
            return languages[i].id
        }
    }
    return user.language
}

export function GetRoleId(tmpRoles, tmpUser){
    let i = 0
    if (tmpRoles == null) {
        return
    }
    for (i; i< tmpRoles.length; i++) {
        if (tmpRoles[i].name === tmpUser.role) {
            return tmpRoles[i].ID
        }
    }
    return tmpUser.role_id
}

export function GetRoles(tmpRoles) {
    let response = []
    let i = 0
    if (tmpRoles == null) {
        return
    }
    for (i; i< tmpRoles.length; i++) {
        if (tmpRoles[i].name !== "admin") {
            response.push({text: tmpRoles[i].name})
        }
    }
    return response
}

export function GetCompanies(tmpCompanies) {
    let response = []
    if (tmpCompanies) {
        tmpCompanies.forEach((el) => {
            response.push({value: el.id, text: el.name})
        })
    }
    return response
}

export function GetRole(tmpRoles, user){
    let i = 0
    let response = ""
    if (tmpRoles == null) {
        return
    }
    for (i; i< tmpRoles.length; i++) {
        if (tmpRoles[i].ID === user.role_id) {
            response += tmpRoles[i].name
        }
    }
    return response
}
