<template>
  <v-dialog v-model="dialog" max-width="40%">
    <template v-slot:activator="{ on, attrs }">
      <v-btn v-bind="attrs" v-on="on"
             color="red" class="text-none custom-transform-class"
             @click="reset()" :disabled="isAdmin()" :dark="!isAdmin()" :text="!isAdmin()">
        {{ texts.delete }}
      </v-btn>
    </template>
    <v-card>
      <v-card-title class="mb-2 center-text">
        <span class="text-h5">Suppression de rôle</span>
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text class="my-4">
        <v-container>
          <v-row v-if="lock">
            {{ texts.alert_delete }}
          </v-row>
          <v-row v-else>
            {{ texts.confirm_delete }}: {{ role.name }}
          </v-row>
        </v-container>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions class="mt-2">
        <v-btn depressed large
               class="text-none custom-transform-class" color="red darken-1"
               @click="deleteRole()" :loading="loading" :disabled="lock" :dark="!lock">
          {{ texts.delete }}
          <template v-slot:loader>
            <v-progress-circular indeterminate
                                 :size="30" light :width="3" color="white">
              {{timer}}
            </v-progress-circular>
          </template>
        </v-btn>
        <v-btn depressed large dark
               class="text-none custom-transform-class" color="blue darken-1"
               @click="dialog = false">{{ texts.cancel }}</v-btn>
        <v-spacer></v-spacer>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters } from "vuex";
import { deleteJson } from "@/store/delete";
import { getJson } from "@/store/get";

export default {
  name: "DeleteRole",
  props: ['role', 'texts'],
  data() {
    return {
      grantedColor: "green accent-4",
      errorColor: "red accent-4",
      showGranted: false,
      showError: false,
      loading: true,
      loadingColor: "blue",
      timer: 4,
      lock: true,

      dialog: false,

      access_statistics: false,
      access_searches: false,
      access_imei: false,
      access_invitations: false,
      access_download: false,
      access_parameters: false,
      access_transaction_id: false,
      access_delete_account: false,
      access_advanced_search: false,
      access_card_number_hex: false,
      access_graphics: false,
      access_oversight: false,
      access_invalidate_contract: false,
      access_invalidate_card: false,
      access_role: false,
    };
  },
  computed: {
    ...mapGetters({ User: "StateUser", Role: "StateRole" })
  },
  created() {
    this.reset()
  },
  methods: {
    reset(){
      setTimeout(this.isRoleDeletable,0)
      this.resetTimer()
    },
    isRoleDeletable() {
      if (this.role.ID) {
        getJson("/role/deletable/" + this.role.ID).then((resp) => {
          if (resp && resp.status === 200) {
            if (resp.data.message === "role can be deleted") {
              this.lock = false
            } else {
              this.lock = true
              this.loading = false
            }
          }
        })
      }
    },
    resetTimer(){
      this.loading = true
      this.timer = 4
      this.timerClock()
    },
    timerClock() {
        if (this.timer > 1) {
          this.timer -= 1
          setTimeout(() => {this.timerClock()}, 1000)
        } else {
          this.loading = false
        }
    },
    isAdmin() {
      return this.role.name === 'admin'
    },
    deleteRole() {
      let deletedRole = {
        ID: this.role.ID,
      }

      deleteJson("/role/" + deletedRole.ID, null).then((resp) => {
        if (resp?.status === 200) {
          this.showGranted = true
          this.$emit("role-deleted", deletedRole.ID)
        } else {
          this.showError = true
        }
      }).catch(() => {}).then(() => {
        this.loading = false
        setTimeout(() => {this.showGranted = false}, 1500)
        setTimeout(() => {this.showError = false}, 1500)
      })

      this.dialog = false
    }
  },
};
</script>
<style scoped>
.custom-transform-class{
  text-transform: uppercase
}
</style>