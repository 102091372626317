<template>
  <v-card class="overflow-y-auto px-0 py-1 mx-0 transition-swing" elevation="0">
    <v-card-text class="px-0">
      <v-data-table
          :headers="headers"
          :items="items"
          :items-per-page="10"
          sort-by="contract_validity_start_date"
          sort-desc
          :no-data-text="texts.no_data"
          :footer-props="{
               'items-per-page-text':texts.items_per_page,
               'pageText': texts.page_text
          }">

        <template v-slot:item.product_name="{ item }">
          <v-chip small class="font-weight-medium" label color="cyan darken-2" dark>
            {{ item.product_name }}
          </v-chip>
        </template>
        <template v-slot:item.contract_validity_start_date="{ item }">
          <div label
               class="font-weight-medium" color="white">{{ DateUtcToStandard(item.contract_validity_start_date) }}</div>
        </template>
        <template v-slot:item.counter_count="{ item }">
          <div label
               class="font-weight-medium" color="white">{{ item.counter_count }}</div>
        </template>
        <template v-slot:item.contract_validity_end_date="{ item }">
          <div label
               class="font-weight-medium" color="white">
            {{ DateUtcToStandard(item.contract_validity_end_date) != "01/01/1"?
              DateUtcToStandard(item.contract_validity_end_date) : '-' }}
          </div>
        </template>
        <template v-slot:item.usage_end_date="{ item }">
          <div label
               class="font-weight-medium" color="white">{{ DateUtcToStandard(item.usage_end_date) }}</div>
        </template>
        <template v-slot:item.contract_status="{ item }">
          <v-chip small label class="font-weight-medium"
                  :color="getContractStatusColor(item.contract_status)"
                  :dark="getContractStatusDark(item.contract_status)">
            {{ getContractStatus(item.contract_status) }}
          </v-chip>
        </template>
        <template v-slot:item.tariff_id="{ item }">
          {{ item.tariff_id }}
        </template>
        <template v-slot:item.contract_serial_number="{ item }">
          <format-clipboard :text="item.contract_serial_number" match="3"/>
        </template>
        <template v-slot:item.remote_control="{ item }">
          <invalidate-contract :calypso_number="calypsoNumber" :texts="texts" :contract="item"/>
        </template>
      </v-data-table>
    </v-card-text>
  </v-card>
</template>

<script>
import {getJson} from "@/store/get";
import {mapGetters} from "vuex";
import {utcToStandard} from "@/utils";
import FormatClipboard from "@/components/FormatClipboard";
import InvalidateContract from "@/components/customer/Invalidations/Contract";

export default {
  name: "Contracts",
  props: ["calypsoNumber", "displayed"],
  components:{
    FormatClipboard,
    InvalidateContract,
  },
  data() {
    return {
      contract_status: [],
      texts: {},
      headers: [],
      items: [],
      contractSwitch: false,
    }
  },
  created() {
    this.getTexts()
    this.getContract()
  },
  computed: {
    isLocalToService() {
      return this.$route.params.service_id === ""+this.Config.ServiceId
    },
    ...mapGetters({ User: "StateUser", Locale: "StateLocale", Role: "StateRole", ContractSwitch: 'StateContractSwitch', Config: "StateConfig" }),
  },
  methods: {
    getContractStatusColor(status){
      let response = ""
      this.contract_status.forEach((el) => {
        if (el.contract === status) {
          response = el.color
        }
      })
      return response
    },
    getContractStatusDark(status){
      let response = ""
      this.contract_status.forEach((el) => {
        if (el.contract === status) {
          response = el.dark
        }
      })
      return response
    },
    getContractStatus(statusId) {
      let resp = ""
      this.contract_status.forEach((status) => {
        if (status.contract === statusId) {
          resp = status.label
          return
        }
      })
      return resp
    },
    DateUtcToStandard(utc) {
      return utcToStandard(utc);
    },
    getTexts(){
      if (this.User) {
        getJson('/text/contracts/' + this.User.language.id).then((resp) => {
          if (resp && resp.status === 200) {
            this.texts = resp.data.ContractsView[0]
            this.headers= [
              { text: this.texts.contract, align: 'start', sortable: true, value: 'product_name', /*width: "6%"*/ },
            ]
            if (this.Config.tariffId) {
                this.headers.push({ text: this.texts.tariff_id, align: 'center', sortable: true, value: 'tariff_id'/*, width: "14%" */})
            }
            this.headers.push({ text: this.texts.validity_start_date, align: 'start', sortable: true, value: 'contract_validity_start_date', /*width: "10%"*/ },
              { text: this.texts.counter_count, align: 'start', sortable: true, value: 'counter_count', /*width: "5%"*/ },
              { text: this.texts.validity_end_date, align: 'start', sortable: true, value: 'contract_validity_end_date', /*width: "6%"*/ },
              { text: this.texts.validity_limit_date, align: 'start', sortable: true, value: 'usage_end_date', /*width: "6%"*/ },
              { text: this.texts.status, align: 'start', sortable: true, value: 'contract_status', /*width: "12%"*/ },
              { text: this.texts.serial_number, align: 'start', sortable: true, value: 'contract_serial_number', /*width: "10%"*/ },
              { text: this.texts.purchase_history_id, align: 'start', sortable: true, value: 'purchase_history_id', /*width: "10%"*/ },
            )
            if (this.Role && this.Role.access_invalidate_contract && this.isLocalToService) {
              this.headers.push(
                  { text: this.texts.remote_control, align: 'start', sortable: false, value: 'remote_control' },
              )
            }
            this.contract_status = resp.data.ContractStatusView
          }
        })
      }
    },
    getContract(){
      if (this.calypsoNumber !== null && this.User) {
        let locale = "en_EN"
        if (this.Locale !== null) {
          locale = this.Locale.substring(0,2)
        }
        let url = ''
        if (this.contractSwitch) {
          url += '/contracts/current/'
        } else {
          url += '/contracts/'
        }
        getJson(url + this.$route.params.service_id + '/' + this.calypsoNumber + '/' + locale).then((resp) => {
          if (resp.status === 200) {
            this.items = resp.data
            if (this.items.length > 0) {
              this.items.forEach((el) => {
                if (el.contract_validity_start_date === "0001-01-01T00:00:00Z") {
                  el.contract_validity_start_date = el.purchase_date
                }
              })
            }
          }
        }).catch(() => {
          this.items = []
        })
      }
    }
  },
  watch: {
    "User.language": function () {
      this.getTexts()
      this.getContract()
    },
    "calypsoNumber": function () {
      if (this.displayed) {
        this.getContract()
      }
    },
    "displayed": function () {
      if (this.displayed) {
        this.getContract()
      }
    },
    "ContractSwitch": function () {
      this.contractSwitch = this.ContractSwitch
      this.getContract()
    },
  },
}
</script>

<style scoped>

</style>