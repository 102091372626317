<template>
  <v-col>
    <v-data-table :headers="headers" :items="data" :items-per-page="-1"
                  :group-by="Config.displayAggregatedMonthStats"
                  :footer-props="{
              'items-per-page-text':texts.items_per_page,
              'pageText': texts.page_text
          }">
      <template v-slot:top>
        <table style="width:100%;">
          <tr>
            <td colspan="10" class="pa-2 text-center" style="border-bottom: 1px #E0E0E0 solid!important;">
              <div class="text-center font-weight-medium" style="font-size: x-large">
                {{ texts.from }} {{ firstDate }} {{ texts.to_lowercase }} {{ lastDate }}
              </div>
            </td>
          </tr>
          <tr>
            <td class="pa-3" style="border-bottom: 1px #E0E0E0 solid!important;">
              <v-row>
                <v-col cols="3" class="font-weight-bold px-4">{{ installations.name }}</v-col>
                <v-col cols="1" class="text-center font-weight-bold pl-2 pr-4" style="color: #0275d8;">{{ installations.sales }}</v-col>
                <v-col cols="8" ></v-col>
              </v-row>
            </td>
          </tr>
        </table>
      </template>
      <template v-slot:group="{ items, expand }">
        <tr @click="toggle(items[0].contract_tariff_provider)" :digi="expand">
          <td  class="text-xs-right" style="display: flex; align-content: center">
            <div style="display: block" class="ma-auto">
              <span v-if="User.display_stats_tooltips">
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <span v-bind="attrs" v-on="on">
                    <v-img  width="40" :src="getImgSrc(items[0][Config.displayAggregatedMonthStats])"
                            :title="items[0][Config.displayAggregatedMonthStats]" transition="scale-transition"></v-img>
                  </span>
                </template>
                <span>{{ Config.displayAggregatedMonthStats }}: {{ items[0][Config.displayAggregatedMonthStats] }}</span>
              </v-tooltip>
              </span>
              <span v-else>
              <v-img  width="40" :src="getImgSrc(items[0][Config.displayAggregatedMonthStats])"
                      :title="items[0][Config.displayAggregatedMonthStats]" transition="scale-transition"></v-img>
            </span>
            </div>
            <v-icon style="align-self: center" dense>{{items[0].hide? 'mdi-chevron-right': 'mdi-chevron-down' }}</v-icon>
          </td>
          <td v-for="(header, hI) in headers.slice(1)" :key="items[0].contract_tariff_provider + '-' + header.value + '-' + hI">
            <div v-if="header.value !== 'logo' && isDate(header.value)" :class="getClassByItemAndHeader(items[0])" :style="getStyleByItemTypeAndHeader(items[0], header)" :align="header.align">
              {{displayTotalSum(items, header.value)}}
            </div>
            <div v-else-if="header.value !== 'logo'" :class="getClassByItemAndHeader(items[0])" :style="getStyleByItemTypeAndHeader(items[0], header)" :align="header.align">
              {{displayTotalSum(items, header.value)}}
            </div>
          </td>
        </tr>
        <tr v-for="(item, index) in items" :key="item.contract_tariff_provider + '-' + index" v-show="!item.hide" style="background: #f9f9f9;">
          <td v-for="(header, hI) in headers" :key="item.contract_tariff_provider + '-' + index + '-' + header.value + '-' + hI">
            <div :class="getClassByItemAndHeader(item)" :style="getStyleByItemTypeAndHeader(item, header)" :align="header.align">{{ item[header.value] }}</div>
          </td>
        </tr>
      </template>
      <template v-slot:body.append="{ headers }">
        <tr>
          <td v-for="(header, headerIndex) in headers" :key="headerIndex">
            <div :class="getClassBasedOnItemAndHeader(total, header)" :style="getStyleBasedOnItemAndHeader(total, header)">
              {{ total[header.value] }}
            </div>
          </td>
        </tr>
      </template>
    </v-data-table>
  </v-col>
</template>

<script>
import {mapGetters} from "vuex";
import * as utils from "@/utils";

export default {
  name: "MonthAggregatedDataTable",
  props: {
    headers: {
      type: Array,
      value: [],
    },
    rawdata: {
      type: Array,
      value: [],
    },
    contractTariffProviderLogos: {
      type: Array,
      value: [],
    },
    texts: {
      type: Object
    },
    installations: {
      type: Object
    },
    total: {
      type: Object
    },
    firstDate: {
      type: String
    },
    lastDate: {
      type: String
    }
  },
  data() {
    return {
      data: [],
    }
  },
  created() {
    this.data = this.rawdata
    this.data.forEach(el => {
      el.hide = true
    })
  },
  computed: {
    ...mapGetters({User: "StateUser", Config: "StateConfig"})
  },
  methods: {
    displayTotalSum(items, key) {
      let sum = 0;
      items.forEach(item => {
        if (item[key]) {
          if (key === 'sales_percentage' || key === 'money_amount' || key === 'money_percentage' || key === 'validations_percentage' || key === 'validations_with_connection_percentage') {
            sum = sum + parseFloat((item[key].slice(0, item[key].length-1).replace(',', '.')))
          } else {
            sum = sum + parseFloat(item[key])
          }
        }
      })
      if (key === 'sales_percentage' || key === 'money_amount' ||  key === 'money_percentage' || key === 'validations_percentage' || key === 'validations_with_connection_percentage') {
        if (key !== 'money_amount' && sum > 100) {
          sum = 100
        }
        sum = sum.toFixed(2)
        if (key === 'money_amount') {
          sum += " €"
        } else {
          sum += " %"
        }
        sum = sum.replace('.', ',')
      }
      return sum
    },
    getStyleByItemTypeAndHeader(item, header)  {
      let style = "";
      if (header.value === 'money_amount' || header.value === 'money_amount_percentage')
        style += "color: #4bce67;"
      else if (header.value === 'total' || header.value === 'total_percentage')
        style += "color: #0275d8;"
      if ((('type' in item) && item.type === 'total') ||
          (header.value.match(/^\d/) && ('type' in item) && item.type === 'data'))
        style += "font-weight: bold;"
      if (('type' in item) && item.type === 'sumup_sales')
        style += "font-style: italic;"
      return style
    },
    getClassByItemAndHeader(item) {
      let classStyle = "";
      if (item && (item.type === 'data' || item.type === 'separator' || item.type === 'total' || item.type === 'installations'))
        classStyle += "font-weight-medium "
      if (('type' in item) && item.type === 'sumup_sales')
        classStyle += "font-italic "
      return classStyle
    },
    isDate(value) {
      return value.split('-').length === 3
    },
    toggle(contract_tariff_provider) {
      this.data.forEach(el => {
        if (el.contract_tariff_provider === contract_tariff_provider) {
          if (el.hide === undefined) {
            el.hide = true
          } else {
            el.hide = !el.hide;
          }
        }
      });
      this.data = JSON.parse(JSON.stringify(this.data))
    },
    getClassBasedOnItemAndHeader(item, header) {
      let classStyle = "";
      if (item && item.type === 'installations')
        classStyle += "font-weight-medium "
      if (item && item.type === 'total')
        classStyle += "font-weight-bold "
      if (header && header.value !== 'name') {
        classStyle += "text-center "
        if (item && item.type === 'data')
          classStyle += "font-weight-bold "
        else if (item && item.type === 'separator')
          classStyle += "font-weight-medium "
      } else {
        if (item && (item.type === 'data' || item.type === 'separator')) {
          classStyle += "font-weight-medium "
        }
      }
      return classStyle
    },
    getImgSrc(contractTariffProvider) {
      for(let i=0; i<this.contractTariffProviderLogos?.length; i++) {
        if (this.contractTariffProviderLogos[i]?.ContractTariffProvider === contractTariffProvider) {
          return utils.networks[this.contractTariffProviderLogos[i]?.Logo]
        }
      }
      return utils.networks[this.Config.logo]
    },
    getStyleBasedOnItemAndHeader(item, header) {
      let style = "";
      if (header.value !== 'name') {
        if (header.value === 'sales' || header.value === 'sales_percentage')
          style += "color: #0275d8;"
        else if (header.value === 'money_amount' || header.value === 'money_percentage')
          style += "color: #4bce67;"
        else if (header.value === 'total' || header.value === 'total_percentage')
          style += "color: #0275d8;"
      } else if (header.value === 'name' && item && (item.type === 'installations' || item.type === 'separator')) {
        style += "font-size: large"
      }
      return style
    },
  },
  watch: {
    rawdata() {
      this.data = this.rawdata
      this.data.forEach(el => {
        el.hide = true
      })
    }
  }
}
</script>
