<template>
  <v-data-table hide-default-footer
                class="pa-0"
                :headers="headers" :items="data" :disable-sort="true" :items-per-page="-1">
    <template v-slot:item="{ item }">
      <tr>
        <td v-for="(header, headerIndex) in headers" :key="headerIndex">
          <div v-if="'type' in item && item.type === 'separator' && header.value === 'name'" class="my-3 font-weight-bold" :align="header.align">
            <div>
              {{ getFirstLine(item, header) }}
            </div>
            <div>
              {{ getSecondLine(item, header) }}
            </div>
          </div>
          <div v-else-if="(header.value === 'tariff_id' && item.type === 'data')">
            <div class="text-center">
              {{ item[header.value] }}
            </div>
          </div>
          <div v-else-if="(header.value !== 'name' && header.value !== 'tariff_id' && item.type === 'data' && header.value !== 'logo')
          || (header.value !== 'name'  && header.value !== 'tariff_id' && header.value !== 'total_percentage' && item.type === 'total' && header.value !== 'logo')" :align="header.align">
            <div :style="getStyleBasedOnItemAndHeader(item, header)">
              {{ getValidations(item, header) }}
            </div>
            <div :style="getStyleBasedOnItemAndHeader(item, header)" >
              {{ getValidationsWithConnection(item, header) }}
            </div>
          </div>
          <div v-else-if="(header.value === 'logo' && shouldDisplayLogo(item))" :align="header.align">
            <v-img width="40" :src="getImgSrc(item.contract_tariff_provider)" transition="scale-transition"></v-img>
          </div>
          <div v-else :style="getStyleBasedOnItemAndHeader(item, header)" :align="header.align">
            {{ item[header.value] }}
          </div>
        </td>
      </tr>
    </template>
  </v-data-table>
</template>

<script>
import {mapGetters} from "vuex";
import * as utils from "@/utils";

export default {
  name: "ValidationsDataTable",
  props: {
    headers: {
      type: Array,
      value: [],
    },
    data: {
      type: Array,
      value: [],
    },
    contractTariffProviderLogos: {
      type: Array,
      value: [],
    }
  },
  data () {
    return {
    }
  },
  created() {
  },
  computed: {
    ...mapGetters({Config: "StateConfig"})
  },
  methods: {
    getFirstLine(item, header) {
      return this.getPropFromItemHeaderValue(item, header, "value", "first_line")
    },
    getSecondLine(item, header) {
      return this.getPropFromItemHeaderValue(item, header, "value", "second_line")
    },
    getValidations(item, header) {
      return this.getPropFromItemHeaderValue(item, header, "value", "validations")
    },
    getValidationsWithConnection(item, header) {
      return this.getPropFromItemHeaderValue(item, header, "value", "validations_with_connection")
    },
    getPropFromItemHeaderValue(item, header, value, prop) {
      if (header[value] != null && item[header[value]] != null) {
        return item[header[value]][prop]
      } else {
        console.error("error validations data table: ", item, header, value, prop)
        return ''
      }
    },
    getImgSrc(contractTariffProvider) {
      let i = 0
      for (i=0; i<this.contractTariffProviderLogos.length; i++) {
        if (this.contractTariffProviderLogos[i]?.ContractTariffProvider === contractTariffProvider) {
          return this.contractTariffProviderLogos[i].Image
        }
      }
      return utils.networks[this.Config.logo]
    },
    shouldDisplayLogo(item) {
      return item.contract_tariff_provider !== undefined
    },
    getStyleBasedOnItemAndHeader(item, header) {
      let style = "";
      if (header.value === 'total' || header.value === 'total_percentage')
        style += "color: #0275d8;"
      if (header.value !== 'name' && ('type' in item) && (item.type === 'data'))
        style += "font-weight: bold;"
      if (header.value === 'name' && ('type' in item) && (item.type === 'data'))
        style += "font-weight: 400;"
      if (('type' in item) && item.type === "total")
        style += "font-weight: bold;"
      return style
    }
  }
}
</script>

<style scoped>

</style>
