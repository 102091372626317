<template>
  <v-dialog v-model="dialog" max-width="60%">
    <template v-slot:activator="{ on, attrs }">
      <v-btn v-bind="attrs" v-on="on"
             depressed
             color="primary" class="custom-transform-class text-none"
             :disabled="!Role.access_parameters">
        <v-icon left>mdi-account-plus-outline</v-icon>
        {{ texts.button_new }}
      </v-btn>
    </template>
    <v-card>
      <v-card-title class="mb-2 center-text">
        {{ texts.create_title }}
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text class="my-4">
        <v-container>
          <v-row>
            <v-col cols="6">
              <v-text-field v-model="tmpUser.email"
                            required outlined
                            :label="texts.email" ></v-text-field>
            </v-col>
            <v-col cols="6">
              <v-select v-model="tmpUser.company"
                        required outlined
                        :label="texts.company" :items="getCompanies"></v-select>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="6">
              <v-text-field v-model="tmpUser.firstname"
                            required outlined
                            :label="texts.firstname"></v-text-field>
            </v-col>
            <v-col cols="6">
              <v-text-field v-model="tmpUser.lastname"
                            required outlined
                            :label="texts.lastname"></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="6">
              <v-text-field v-model="tmpUser.phone"
                            required outlined
                            :label="texts.phone"></v-text-field>
            </v-col>
            <v-col cols="6">
              <v-select v-model="tmpUser.language"
                        required outlined
                        :label="texts.language_label" :items="languages"></v-select>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-select v-model="tmpUser.role"
                        required outlined
                        :label="texts.role" :items="getRoles"></v-select>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions class="mt-2">
        <v-btn depressed large
               class="text-none custom-transform-class" color="blue darken-4"
               :disabled="lockButton()" :dark="!lockButton()"
               @click="createAndInviteUser()">
          <v-icon left>mdi-account-arrow-right-outline</v-icon>
          {{ texts.create_invite_user }}</v-btn>
        <v-btn depressed large outlined
               class="text-none custom-transform-class" color="blue darken-3"
               :disabled="lockButton() || !Role.access_parameters" :dark="!lockButton()"
               @click="createUser()">
          <v-icon left>mdi-account-plus-outline</v-icon>
          {{ texts.create_user }}</v-btn>
        <v-spacer></v-spacer>
        <v-btn depressed large dark
               class="text-none custom-transform-class" color="blue darken-3"
               @click="dialog = false">{{ texts.cancel }}</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters } from "vuex";
import { postJson } from "@/store/post";
import {GetLanguage, GetLanguageId, GetRoleId, GetRoles, GetCompanies} from "@/components/parameters/User/common";

export default {
  name: "CreateUser",
  props: ['texts', 'languages', 'roles', 'companies'],
  data() {
    return {
      grantedColor: "green accent-4",
      errorColor: "red accent-4",
      showGranted: false,
      showError: false,
      loading: false,
      loadingColor: "blue",

      dialog: false,

      name: null,
      tmpRoles: null,
      tmpCompanies: null,

      tmpUser: {
        id: null,
        email: null,
        lastname: null,
        firstname: null,
        company: null,
        phone: null,
        language: null,
        role: null,
      },

      resetUser: {
        id: null,
        email: null,
        lastname: null,
        firstname: null,
        company: null,
        phone: null,
        language: null,
        role: null,
      }
    };
  },
  computed: {
    ...mapGetters({ User: "StateUser", Role: "StateRole" }),
    getRoles(){
      return GetRoles(this.tmpRoles)
    },
    getCompanies() {
      return GetCompanies(this.tmpCompanies)
    }
  },
  created() {
    setTimeout(() => {
      this.tmpRoles = this.roles
      this.tmpCompanies = this.companies
      this.tmpUser.language = GetLanguage(this.languages, this.tmpUser)
    }, 200)
  },
  methods: {
    lockButton() {
      if (this.tmpUser.email == null || this.tmpUser.email === "" ||
          this.tmpUser.lastname == null || this.tmpUser.lastname === "" ||
          this.tmpUser.firstname == null || this.tmpUser.firstname === "" ||
          this.tmpUser.language == null || this.tmpUser.language === "" ||
          this.tmpUser.role == null || this.tmpUser.role === "") {
        return true
      } else {
        return false
      }
    },
    createUser() {
     this.postUser(null)
    },

    createAndInviteUser() {
      this.postUser("invite")
    },

    postUser(code) {
      let newUser = {
        email: this.tmpUser.email,
        lastname: this.tmpUser.lastname,
        firstname: this.tmpUser.firstname,
        company_id: this.tmpUser.company,
        phone: this.tmpUser.phone,
        language_id: GetLanguageId(this.languages, this.tmpUser, this.tmpUser),
        role_id:  GetRoleId(this.tmpRoles, this.tmpUser),
      }

      if (newUser.phone === "-") {
        newUser.phone = null
      }
      if (newUser.company === "-") {
        newUser.company = null
      }

      let route = "/user/"
      if (code !== null) {
        route += code
      }

      postJson(route, newUser).then((resp) => {
        if (resp && resp.status === 200) {
          this.showGranted = true
          this.$emit("user-created", resp.data)
        } else {
          this.showError = true
        }
      }).catch(() => {}).then(() => {
        this.loading = false
        setTimeout(() => {this.showGranted = false}, 1500)
        setTimeout(() => {this.showError = false}, 1500)
      })

      this.tmpUser = JSON.parse(JSON.stringify(this.resetUser))
      this.dialog = false
    },
  },
  watch: {
    roles() {
      this.tmpRoles = this.roles
    },
    companies() {
      this.tmpCompanies = this.companies
    }
  }
};
</script>
<style scoped>
.custom-transform-class{
  text-transform: uppercase
}
</style>