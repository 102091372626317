import Vuex from "vuex";
import Vue from "vue";
import createPersistedState from "vuex-persistedstate";
import auth from "@/store/modules/auth";
import statistics from "@/store/modules/statistics"

// Load Vuex
Vue.use(Vuex);

// Create store
export default new Vuex.Store({
  modules: {
    auth,
    statistics
  },
  plugins: [createPersistedState({
    storage: window.sessionStorage,
  })],
});
