<template>
  <div>
  <v-container v-if="User" fluid style="width: 50%">
    <v-row class="mt-6">
      <v-col class="text-left">
        <div>
          <p class="text-h4">{{ texts.title }}</p>
        </div>
      </v-col>
      <v-col class="text-right">
        <create-role :texts="texts"
                     v-on:role-created="items.push($event)"></create-role>
      </v-col>

    </v-row>
    <v-row>
      <v-col align="center">
        <v-data-table hide-default-footer
                      :headers="headers" :items="items">
          <template v-slot:item.user="{ item }">
            {{ item.firstname }} {{ item.name }}
          </template>
          <template v-slot:item.role="{ item }">
            {{ getRole(item.role) }}
          </template>
          <template v-slot:item.update="{ item }">
            <update-role :role="item" :texts="texts"
                         v-on:role-updated="updateRole($event)"></update-role>
          </template>
          <template v-slot:item.delete="{ item }">
            <delete-role :role="item" :texts="texts"
                         v-on:role-deleted="deleteRole($event)"></delete-role>
          </template>
        </v-data-table>
      </v-col>
    </v-row>

    <granted-dialog :show_granted="showGranted" :text="texts.granted" :color="grantedColor"/>
    <granted-dialog :show_granted="showError" :text="texts.error" :color="errorColor"/>
  </v-container>
  </div>
</template>

<script>
import GrantedDialog from "@/components/GrantedDialog";
import { mapGetters } from "vuex";
import {getJson} from "@/store/get";
import CreateRole from "@/components/parameters/Role/CreateRole";
import UpdateRole from "@/components/parameters/Role/UpdateRole";
import DeleteRole from "@/components/parameters/Role/DeleteRole";

export default {
  name: "Roles",
  components: {
    DeleteRole,
    GrantedDialog,
    UpdateRole,
    CreateRole
  },
  data() {
    return {
      grantedColor: "green accent-4",
      errorColor: "red accent-4",
      showGranted: false,
      showError: false,
      loading: false,
      loadingColor: "blue",
      languages: null,
      roles: null,

      items: [],

      texts: {
        title: "All roles",
        button_new: "New user",
        granted: "Ok",
        error: "Not ok"
      },
    };
  },
  computed: {
    ...mapGetters({ User: "StateUser" }),
    headers() {
      return  [
        { text: this.texts.name, align: 'start', sortable: false, value: 'name', width: '70%'  },
        { text: '', align: 'start', sortable: false, value: 'update', width: '15%'  },
        { text: '', align: 'start', sortable: false, value: 'delete', width: '15%'  },
      ]
    }
  },
  created() {
    this.getLanguages()
    this.getTexts()
    this.getUsers()
    this.getRoles()
  },
  methods: {
    updateRole(data) {
      let r = null
      if (this.items != null) {
        for(let i =0; i<this.items.length; i++) {
          if (this.items[i].id === data.id) {
            r = i
          }
        }
        this.items.splice(r, 1, data)
      }
    },
    deleteRole(id) {
      this.items = this.items.filter(function(el) { return el.ID !== id })
    },
    getRole(role) {
      let i = 0
      let response = ""
      if (this.roles == null) {
        return
      }
      for (i; i< this.roles.length; i++) {
        if (this.roles[i].id === role) {
          response += this.roles[i].name
        }
      }
      return response
    },
    getRoles() {
      getJson("/roles").then((resp) => {
        if (resp.status === 200) {
          this.roles = resp.data
        }
      })
    },
    getLanguages() {
      getJson("/text").then((resp) => {
        if (resp.status === 200) {
          this.languages = []
          resp.data.Languages.forEach(el => {
            this.languages.push({
              id: el.id,
              text: el.language,
              lang: el.lang
            })
          })
        }
      })
    },
    getTexts() {
      if (this.User === null) {
        return
      }
      getJson("/text/roles/"+this.User.language.id).then((resp) =>{
        if (resp && resp.status === 200) {
          this.texts = resp.data.RolesView[0]
        }
      })
    },
    getUsers() {
      getJson("/roles").then((resp) =>{
        if (resp.status === 200) {
          this.items = resp.data
        }
      })
    }
  },
  watch: {
    "User.language": function () {
      this.getTexts()
    },
  },
};
</script>
<style scoped>
.custom-transform-class{
  text-transform: uppercase
}
</style>