<template>
  <v-container fluid fill-height class="login" style="background-color: #f8f9fa">
    <v-row v-if="!showGranted">
      <v-col align="center">

        <div class="logo pa-4 ma-4">
          <v-img
              max-height="400"
              max-width="422"
              :src="getImgSrc()"></v-img>
        </div>

        <v-card :loading="loading" class="mx-auto my-12 py-0" max-width="40%" elevation="0" style="background-color: #f8f9fa">
          <v-card-title class="justify-center text-h2 mt-3"><div style="word-break: break-word">{{ text.connexion }}</div></v-card-title>

          <v-spacer/>

          <v-card-text>
            <v-container>
              <v-row align="center">
                  <v-col cols="12"><v-text-field @keyup.enter="login" :label="text.email" type="text" name="email" v-model="form.email" ></v-text-field></v-col>
              </v-row>
              <v-row align="center">
                <v-col cols="12"><v-text-field @keyup.enter="login" :label="text.password" type="password" name="password" v-model="form.password" /></v-col>
              </v-row>
              <v-row align="start" no-gutters v-if="displayRememberMe()">
                <v-col style="position: relative!important">
                  <v-checkbox v-model="umi" :label="text.remember_me"></v-checkbox>
                </v-col>
              </v-row>
              <v-row align="center" no-gutters>
                <v-col class="pa-2">
                  <v-btn class="mt-4 text-capitalize" outlined large color="blue" type="submit" @click="login" @keyup.enter="login" :disabled="disableButton">{{ text.submit }}</v-btn>
                  <p v-if="showError" id="error" class="red--text font-weight-bold">{{ text.error }}</p>
                </v-col>
              </v-row>
              <v-row align="center">
                <v-col class="pa-0">
                  <v-btn small plain class="mx-0 text--black text-none" to="/forget-password">{{ text.forgot }}</v-btn>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
        </v-card>

      </v-col>
    </v-row>

    <granted-dialog :show_granted="showGranted" :text="text.granted" :color="showColor" />
  </v-container>
</template>

<script>
import GrantedDialog from "@/components/GrantedDialog";
import {mapActions, mapGetters} from "vuex";
import {getJson} from "@/store/get";
import Vue from "vue";

export default {
  name: "Login",
  components: {
    GrantedDialog
  },
  data() {
    return {
      bmc: require("@/assets/bmc.png"),
      delijn: require("@/assets/delijn.png"),
      sncb: require("@/assets/sncb.png"),
      stib: require('@/assets/stib.png'),
      tec: require("@/assets/tec.png"),
      tisseo: require("@/assets/tisseo.png"),
      rla: require("@/assets/rla.png"),
      nam: require("@/assets/nam.png"),
      loading: false,
      loadingColor: "blue",
      text: {
        connexion: "Log In",
        email: "Email",
        password: "Password",
        submit: "Log in",
        granted: "Login granted",
        error: "Email or password incorrect",
        forgot: "Forgot password ?"
      },
      form: {
        email: "",
        password: "",
      },
      showError: false,
      showGranted: false,
      showColor: "success",
      umi: false,
    };
  },
  computed: {
    ...mapGetters({ RememberMe: "StateRememberMe", Config: "StateConfig" }),
    disableButton() {
      return (this.form.password.length === 0)
    }
  },
  created() {
    if (window.localStorage.getItem('vuex')) {
      try {
        this.umi = JSON.parse(window.localStorage?.vuex).auth.token !== null
      } catch (e) {
        this.umi = false
      }
    }
    this.getTexts()
  },
  methods: {
    ...mapActions(["LogIn"]),
    displayRememberMe(){
      return this.Config?.displayRememberMe
    },
    getImgSrc() {
      if (this.Config) {
        return this[this.Config.logo]
      } else {
        return null
      }
    },
    login() {
      if (this.disableButton) {
        return
      }
      this.loading = this.loadingColor
      let user = {
        "email": this.form.email,
        "password": this.form.password,
        "isPersistent" : this.umi
      }
      if (!this.Config.displayRememberMe) {
        this.umi = true
        user.isPersistent = true
      }
      this.showError = false;

      this.LogIn(user).then((resp) => {
        if (resp?.status === 200) {
          this.showError = false
          this.loading = false
          setTimeout(() => {
            this.$router.push({path: "/"}).then(() => {

            }).catch((err) => {
              console.log(err)
            })
          }, 0)
        } else {
          this.loading = false
          this.showError = true
        }
      }).catch ((err) => {
        console.log(err)
      })
    },
    getTexts() {
      getJson("/text/login/"+navigator.language.split('-')[0]).then((resp) => {
        if (resp?.status === 200) {
          this.text = resp.data.LoginView[0]

          Vue.nextTick(() => {
            document.title = this.Config?.app?.toUpperCase() + ' - ' + this.text.title;
          })
        }
      })
    }
  },
  watch: {
    umi() {
      if (this.umi) {
        window.sessionStorage.removeItem('vuex')
      } else {
        window.localStorage.removeItem('vuex')
      }
    }
  }
};
</script>

<style scoped>
.logo{
  position: absolute;
  top: 50%;
  left: 5%;
  max-width: 25%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}
</style>
