import store from '@/store';
import {postJson} from "@/store/post";

let PID = 0;

export async function login({commit}, user) {
  return postJson('/auth/login', user).then((resp) => {
    if (resp !== undefined) {
      if (PID !== 0) {
        clearInterval(PID)
      }

      if (resp.data.msg === "welcome") {
        return false
      }

      if (resp.status === 200) {
        let timer = (resp.data.timer - 2) * 60 * 1000
        commit('setUser', resp.data.user)
        commit('setToken', resp.data.user.token)
        commit('setLocale', resp.data.user.language.locale)
        commit('setRole', resp.data.role)
        commit('setConnectToCRM', resp.data.connectionToCRM)

        if (user.isPersistent) {
          window.localStorage.setItem('vuex', window.sessionStorage.getItem('vuex'))
        }
        PID = setInterval(loginAuto, timer, {commit})
      } else {
        return false
      }
    }
    return resp
  })
}

export function logout({commit}){
  commit('setUser', null)
  commit('setToken', null)
  commit('setRole', null)
  commit('setLocale', null)
  window.sessionStorage.clear()
  window.localStorage.clear()
  clearInterval(PID)
}

export function loginAuto({commit}) {
  postJson('/auth/login', store.getters.StateAuth).then((resp) => {
    if (resp?.status === 200 && resp.data.msg !== undefined) {
      commit('setToken', resp.data?.user?.token)
    }
  })
}
