<template>
  <v-container fluid fill-height class="search pt-1 pb-0">
    <v-row class="fill-height">
      <v-col cols="2" class="py-0 px-0"><customer-card></customer-card></v-col>
      <v-col cols="10" class="py-0"><tab-panel></tab-panel></v-col>
    </v-row>
  </v-container>
</template>

<script>
import CustomerCard from "@/components/customer/CustomerCard";
import TabPanel from "@/components/customer/TabPanel";

export default {
  name: "Customer",
  components: {
    CustomerCard,
    TabPanel
  },
}
</script>
