<template>
  <v-container class="mt-6" style="min-width: 85%;">
    <v-row>
      <v-col>
        <v-row>
          <v-col>
            <p class="ma-0 text-h2">
              {{ texts.month_statistics }}
            </p>
            <p class="ma-0 mt-3 text-h2" style="color: #b8b8b8">
              {{ date }}
            </p>
          </v-col>
          <v-col align-self="end">
            <div style="display: flex; justify-content: flex-start">
              <div style="align-self: end;margin-right: 15px;">
                <v-menu
                    v-model="menu"
                    offset-y
                    ref="menu" max-width="290px" min-width="auto" transition="scale-transition"
                    :close-on-content-click="false" :return-value.sync="monthSelector">
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                        v-bind="attrs" v-on="on"
                        v-model="monthSelector"
                        readonly
                        prepend-icon="mdi-calendar" style="width: 200px"/>
                  </template>
                  <v-date-picker
                      v-model="monthSelector"
                      no-title scrollable
                      type="month"
                      :locale="fixedLocale">
                    <v-spacer/>
                    <v-btn
                        text
                        color="primary"
                        @click="menu = false">{{ texts.cancel }}</v-btn>
                    <v-btn
                        text
                        color="primary"
                        @click="$refs.menu.save(monthSelector)">OK</v-btn>
                  </v-date-picker>
                </v-menu>
              </div>
              <div style="align-self: center">
                <v-btn color="green" @click="getOffset(monthSelector)" outlined>{{ texts.search }}</v-btn>
              </div>
            </div>
          </v-col>
        </v-row>
      <v-row>
        <v-col style="padding-left: 70px" class="text-center">
          <v-chip class="px-5 mx-3" v-for="(week, index) in weeks" :key="index" @click="setOffset(week.offset)" outlined style="margin-left: 5px">
            {{ texts.week_of }} {{ week.date }}
          </v-chip>
        </v-col>
      </v-row>
        <v-row>
          <MonthAggregatedDataTable v-if="Config.displayAggregatedMonthStats" :headers="headers" :rawdata="data" :contractTariffProviderLogos="contractTariffProviderLogos"
              :texts="texts" :installations="installations" :total="total" :firstDate="firstDate" :lastDate="lastDate"></MonthAggregatedDataTable>
          <MonthDataTable v-else :headers="headers" :data="data" :contractTariffProviderLogos="contractTariffProviderLogos"
              :texts="texts" :installations="installations" :total="total" :firstDate="firstDate" :lastDate="lastDate"></MonthDataTable>
          <v-overlay dark absolute
                     opacity="0.15" :z-index="0" :value="waiting">
            <v-progress-circular
                indeterminate
                color="blue lighten-1"
                :size="70" :width="5"
            ></v-progress-circular>
          </v-overlay>
        </v-row>
      </v-col>
    </v-row>
    <v-row justify="space-between">
      <v-col cols="2">
        <v-btn outlined class="custom-transform-class text-none" @click="decreaseOffset">
          {{ texts.previous_month }}
        </v-btn>
      </v-col>
      <v-col cols="2" class="text-right">
        <v-btn outlined class="text-none" @click="increaseOffset">
          {{ texts.next_month }}
        </v-btn>
      </v-col>
    </v-row>
</v-container>
</template>

<script>
import {mapGetters} from "vuex";
import {postJson} from "@/store/post";
import {getJson} from "@/store/get";
import MonthDataTable from "@/components/statistics/MonthDataTable.vue";
import MonthAggregatedDataTable from "@/components/statistics/MonthAggregatedDataTable.vue";

export default {
    name: 'Month',
    components: {
        MonthDataTable,
        MonthAggregatedDataTable
    },
    data() {
        return {
            contractTariffProviderLogos: [],
            tec: require("@/assets/tec.png"),
            bmc: require("@/assets/bmc.png"),
            stib: require('@/assets/stib.png'),
            sncb: require("@/assets/sncb.png"),
            delijn: require("@/assets/delijn.png"),
            tisseo: require("@/assets/tisseo.png"),
            rla: require("@/assets/rla.png"),
            nam: require("@/assets/nam.png"),
            menu: null,
            fixedLocale: null,
            texts: {},
            date: null,
            offset: 0,
            firstDate: null,
            lastDate: null,
            monthSelector: null,
            weeks: null,
            numberFormatCurrencyOptions: { style: 'currency', currency: 'EUR' },
            numberFormatPercentageOptions: { style: 'percent', minimumFractionDigits: 1, maximumFractionDigits: 2 },
            data: [],
            total: {},
            installations: {},
            buss: require("@/assets/networks/buss.png"),
            cars_regionaux: require("@/assets/networks/cars_regionaux.png"),
            couralin: require("@/assets/networks/couralin.png"),
            evalys: require("@/assets/networks/evalys.png"),
            idelis: require("@/assets/networks/idelis.png"),
            libeo: require("@/assets/networks/libeo.png"),
            mobius: require("@/assets/networks/mobius.png"),
            peribus: require("@/assets/networks/peribus.png"),
            rbus: require("@/assets/networks/rbus.png"),
            transcom: require("@/assets/networks/transcom.png"),
            trema: require("@/assets/networks/trema.png"),
            tut: require("@/assets/networks/tut.png"),
            vitalis: require("@/assets/networks/vitalis.png"),
            yelo: require("@/assets/networks/yelo.png"),
            waiting: false,
        }
    },
    computed: {
        ...mapGetters({ User: "StateUser", Locale: "StateLocale", Config: "StateConfig"}),
      headers(){
        let headerArray = [
          { text: this.texts.products, value: "name", sortable: true, align: "start", class: "font-weight-medium", width: "16.66%"}
        ]
        if (this.Config.displayContractTariffProviderLogo) {
          headerArray.push({ text: '', value: "logo", sortable: false, align: "center" })
        }
        headerArray.push({ text: this.texts.sales, value: "sales", sortable: true, align: "center", width: "8%" })
        headerArray.push({ text: "%", value: "sales_percentage", sortable: true, align: "center", sort: this.sortPercentage})
        headerArray.push({ text: this.texts.amount, value: "money_amount", sortable: true, align: "center", sort: this.sortEuros })
        headerArray.push({ text: "%", value: "money_percentage", sortable: true, align: "center", sort: this.sortPercentage })
        headerArray.push({ text: this.texts.validations, value: "validations", align: "center", sortable: true })
        headerArray.push({ text: "%", value: "validations_percentage", sortable: true, align: "center", sort: this.sortPercentage })
        headerArray.push({ text: this.texts.validations_with_connection, value: "validations_with_connection", sortable: true, align: "center" })
        headerArray.push({ text: "%", value: "validations_with_connection_percentage", sortable: true, align: "center", sort: this.sortPercentage })
        return headerArray
      }
    },
    created() {
      this.fixedLocale = this.Locale.replace('_', '-')
      this.getTexts()
      this.getContractTariffProviderLogos()
      this.getData()
    },
    methods: {
      getContractTariffProviderLogos() {
        if (!this.Config.displayContractTariffProviderLogo) {
          return
        }
        getJson("/contract-tariff-provider-logos").then((resp) => {
          if (resp?.status === 200) {
            this.contractTariffProviderLogos = resp.data.ContractTariffProviderLogoArray
            if (this.contractTariffProviderLogos === undefined) {
              this.contractTariffProviderLogos = []
            }
          }
        })
      },
      sortClean(a, b, pattern, locale) {
        let c = 0
        let d = 0
        if (locale && this.fixedLocale === 'en-US') {
          c = parseFloat(a.split(pattern)[1].split(',').join('.'))
          d = parseFloat(b.split(pattern)[1].split(',').join('.'))
        } else {
          c = parseFloat(a.split(pattern)[0].split(',').join('.'))
          d = parseFloat(b.split(pattern)[0].split(',').join('.'))
        }
        return d - c
      },
      sortPercentage(a, b) {
        return this.sortClean(a, b, '%', false)
      },
      sortEuros(a, b) {
        return this.sortClean(a, b, '€', true)
      },
      getTexts() {
        if (this.User) {
          getJson("/text/specific_month_statistics/" + this.User.language.id).then((resp) => {
            if (resp?.status === 200) {
              this.texts = resp.data.SpecificMonthStatisticsView[0]
              this.texts.to_lowercase = this.texts.to.toLowerCase()
            }
          })
        }
      },
      getOffset(yearMonth) {
        postJson("/statistics/month-offset", {"year-month": yearMonth}).then((resp) => {
          if (resp?.status === 200)
            this.offset = resp.data.statistics.date_information.offset
        })
      },
      getData() {
        this.waiting = true
        postJson("/statistics/specific-month/"+this.offset, {"language": this.Locale}).then((resp) => {
          if (resp?.status === 200) {
            let date = resp.data.statistics.date_information.year.toString() + '-' + resp.data.statistics.date_information.month.toString() + '-01'
            this.date = new Date(date).toLocaleDateString(this.fixedLocale, {month:'long', year:'numeric'})
            this.firstDate = new Date(resp.data.statistics.date_information.first_date).toLocaleDateString(this.fixedLocale, {weekday: 'long', day: "2-digit", month:'long' })
            this.lastDate = new Date(resp.data.statistics.date_information.last_date).toLocaleDateString(this.fixedLocale, {weekday: 'long', day: "2-digit", month:'long', year:'numeric'})
            this.weeks = resp.data.statistics.specific_month_statistics.weeks_information
            this.weeks.forEach((item) => {
              item.date = new Date(item.date).toLocaleDateString(this.fixedLocale, { day: "2-digit", month:'long' })
            })

            let sumUpPart = { name: this.texts.total, type: 'total' }
            this.installations = { name: this.texts.installations, sales: resp.data.statistics.specific_month_statistics.installations, type: 'installations' }

            this.data = []
            if (resp.data.statistics.specific_month_statistics.products_information != null) {
              let js_this = this // le this dans le sort n'est pas le this global, il est écrasé. Du coup on coutourne.
              let products_information_sorted = resp.data.statistics.specific_month_statistics.products_information.sort(function (a, b) {
                return a[js_this.Config.displayAggregatedMonthStats] * 1000 + a.display_priority > b[js_this.Config.displayAggregatedMonthStats] * 1000 + b.display_priority;
              })
              products_information_sorted.forEach((item) => {
                let dataPart = { type: 'data' }
                dataPart.contract_tariff_provider = item.contract_tariff_provider
                dataPart.company_id = item.company_id
                dataPart.name = item.name
                dataPart.sales = item.sales
                dataPart.sales_percentage = Intl.NumberFormat(this.fixedLocale, this.numberFormatPercentageOptions).format(item.sales_percentage)
                dataPart.money_amount = Intl.NumberFormat(this.fixedLocale, this.numberFormatCurrencyOptions).format(item.money_amount)
                dataPart.money_percentage = Intl.NumberFormat(this.fixedLocale, this.numberFormatPercentageOptions).format(item.money_amount_percentage)
                dataPart.validations = item.validations
                dataPart.validations_percentage = Intl.NumberFormat(this.fixedLocale, this.numberFormatPercentageOptions).format(item.validations_percentage)
                dataPart.validations_with_connection = item.validations_with_connection
                dataPart.validations_with_connection_percentage = Intl.NumberFormat(this.fixedLocale, this.numberFormatPercentageOptions).format(item.validations_with_connection_percentage)
                this.data.push(dataPart)
              })
            }

            sumUpPart.sales = resp.data.statistics.specific_month_statistics.total_sales
            sumUpPart.money_amount = Intl.NumberFormat(this.fixedLocale, this.numberFormatCurrencyOptions).format(resp.data.statistics.specific_month_statistics.total_money_amount)
            sumUpPart.validations = resp.data.statistics.specific_month_statistics.total_validations
            sumUpPart.validations_with_connection = resp.data.statistics.specific_month_statistics.total_validations_with_connection
            this.total = sumUpPart
            //this.data.push(sumUpPart)
          }
        }).then(() => {
          this.waiting = false
        })
      },
      increaseOffset() {
          ++this.offset
      },
      decreaseOffset() {
          --this.offset
      },
      setOffset(offset) {
        this.$store.commit('setOffset', offset)
        this.$router.push({ path: '/statistics/week' })
      }
    },
    watch: {
      offset() {
          this.getData()
      },
      "User.language": function () {
        this.fixedLocale = this.Locale.replace('_', '-')
        this.getTexts()
        this.getData()
      }
    }
}
</script>
