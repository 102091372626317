<template>
  <v-hover v-slot="{ hover }">
    <div v-ripple
         class="text-capitalize px-0" style="user-select: none; overflow-wrap: break-word;"
         @click.stop="copyText()">
      <span v-if="parenthesis">(</span>
      <span v-for="(item, index) in formatPhone()" :key="index"
            :class="formatPhone().length-1 !== index? 'pr-1': ''">{{item}}</span>
      <span v-if="parenthesis">)</span>
      <v-icon v-if="text !== '-' && !hide_icon"
              small
              style="display: inline !important" :color="!hover ? 'grey lighten-3' : 'grey darken-2'">mdi-clipboard-multiple-outline</v-icon>
      <v-snackbar class="pa-0" style="z-index: 40000 !important;"
                  v-model="snackbar"
                  light absolute
                  elevation="5" timeout="300" min-width="10px" max-width="100px">Copié
        <v-icon x-small color="success">mdi-check-bold</v-icon>
      </v-snackbar>
    </div>
  </v-hover>
</template>

<script>
export default {
  props: ["text", "match", "hide_icon", "parenthesis"],
  name: "FormatClipboard",

  data() {
    return {
      internalText: "",
      internalMatch: !this.match ? "2" : this.match,
      snackbar: false,
      hover: false,
    }
  },

  methods: {
    formatPhone() {
      if (this.text === undefined || this.text === "" || this.text === "Inconnu") {
        return "-"
      }
      if (this.text) {
        this.internalText = String(this.text).replace(/\s/g, '')
      }
      const tmp = ".{1," + this.internalMatch + "}"
      if (this.internalText){
        return this.internalText.match(new RegExp(tmp, 'g'))
      }
      else {
        return ""
      }
    },
    copyText() {
      navigator.clipboard.writeText(this.internalText);
      this.snackbar = true
      document.activeElement.blur();
    },
  }
}
</script>
