<template>
  <v-col>
    <v-data-table :headers="headers" :items="data" :items-per-page="-1"
                  :footer-props="{
              'items-per-page-text':texts.items_per_page,
              'pageText': texts.page_text
          }">
      <template v-slot:top>
        <table style="width:100%;">
          <tr>
            <td colspan="10" class="pa-2 text-center" style="border-bottom: 1px #E0E0E0 solid!important;">
              <div class="text-center font-weight-medium" style="font-size: x-large">
                {{ texts.from }} {{ firstDate }} {{ texts.to_lowercase }} {{ lastDate }}
              </div>
            </td>
          </tr>
          <tr>
            <td class="pa-3" style="border-bottom: 1px #E0E0E0 solid!important;">
              <v-row>
                <v-col cols="3" class="font-weight-bold px-4">{{ installations.name }}</v-col>
                <v-col cols="1" class="text-center font-weight-bold pl-2 pr-4" style="color: #0275d8;">{{ installations.sales }}</v-col>
                <v-col cols="8" ></v-col>
              </v-row>
            </td>
          </tr>
        </table>
      </template>
      <template v-slot:item="{ item }">
        <tr>
          <td v-for="(header, headerIndex) in headers" :key="headerIndex">
            <div :class="getClassBasedOnItemAndHeader(item, header)" :style="getStyleBasedOnItemAndHeader(item, header)">
              {{ item[header.value] }}
            </div>
            <div v-if="header.value === 'logo'">
              <v-img alt="Logo" contain :src="getImgSrc(item.contract_tariff_provider)" transition="scale-transition" width="40"/>
            </div>
          </td>
        </tr>
      </template>
      <template v-slot:body.append="{ headers }">
        <tr>
          <td v-for="(header, headerIndex) in headers" :key="headerIndex">
            <div :class="getClassBasedOnItemAndHeader(total, header)" :style="getStyleBasedOnItemAndHeader(total, header)">
              {{ total[header.value] }}
            </div>
          </td>
        </tr>
      </template>
    </v-data-table>
  </v-col>
</template>

<script>
import {mapGetters} from "vuex";
import * as utils from "@/utils";

export default {
  name: "MonthDataTable",
  props: {
    headers: {
      type: Array,
      value: [],
    },
    data: {
      type: Array,
      value: [],
    },
    contractTariffProviderLogos: {
      type: Array,
      value: [],
    },
    texts: {
      type: Object
    },
    installations: {
      type: Object
    },
    total: {
      type: Object
    },
    firstDate: {
      type: String
    },
    lastDate: {
      type: String
    }
  },
  computed: {
    ...mapGetters({Config: "StateConfig"})
  },
  methods: {
    getClassBasedOnItemAndHeader(item, header) {
      let classStyle = "";
      if (item && item.type === 'installations')
        classStyle += "font-weight-medium "
      if (item && item.type === 'total')
        classStyle += "font-weight-bold "
      if (header && header.value !== 'name') {
        classStyle += "text-center "
        if (item && item.type === 'data')
          classStyle += "font-weight-bold "
        else if (item && item.type === 'separator')
          classStyle += "font-weight-medium "
      } else {
        if (item && (item.type === 'data' || item.type === 'separator')) {
          classStyle += "font-weight-medium "
        }
      }
      return classStyle
    },
    getImgSrc(contractTariffProvider) {
      for (let i=0; i<this.contractTariffProviderLogos.length; i++) {
        if (this.contractTariffProviderLogos[i].ContractTariffProvider === contractTariffProvider) {
          return utils.networks[this.contractTariffProviderLogos[i].Logo]
        }
      }
      return utils.networks[this.Config.logo]
    },
    getStyleBasedOnItemAndHeader(item, header) {
      let style = "";
      if (header.value !== 'name') {
        if (header.value === 'sales' || header.value === 'sales_percentage')
          style += "color: #0275d8;"
        else if (header.value === 'money_amount' || header.value === 'money_percentage')
          style += "color: #4bce67;"
        else if (header.value === 'total' || header.value === 'total_percentage')
          style += "color: #0275d8;"
      } else if (header.value === 'name' && item && (item.type === 'installations' || item.type === 'separator')) {
        style += "font-size: large"
      }
      return style
    },
  }
}
</script>
