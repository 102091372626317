<template>
  <v-container fluid>
    <v-card elevation="0" color="digibackground">
      <v-card-title>{{texts.title}}</v-card-title>
      <v-card-text class="px-0">
        <v-data-table
            fixed-header hide-default-footer
            class="elevation-0" style="background-color: #f8f9fa;"
            :headers="headers"
            :items="getItems()"
            :items-per-page="200"
            :loading="loading"
            :no-data-text="getInfoText()"
            :loading-text="texts.loading">
          <template v-slot:item.service_id="{ item }">
              <v-img alt="Logo" contain :src="getImgSrc(item.service_id)" transition="scale-transition" width="40"/>
          </template>
          <template v-slot:item.id_client="{ item }">
            <p class="font-weight-medium my-auto">
              <format-clipboard :text="item.id_client" match="10"></format-clipboard>
            </p>
          </template>
          <template v-slot:item.id_mobib="{ item }">
            <p class="font-weight-medium my-auto">
              <format-clipboard :text="item.id_mobib" :match="Config.app === 'tec'? '3' : '100'"></format-clipboard>
            </p>
          </template>
          <template v-slot:item.id_tec="{ item }">
            <p class="font-weight-medium my-auto">
              <format-clipboard :text="item.id_tec" match="3"></format-clipboard>
            </p>
          </template>
          <template v-slot:item.creation_date="{ item }">
            {{ utcToStandard(item.creation_date) }}
          </template>
          <template v-slot:item.phone_number="{ item }">
            <format-clipboard :text="item.phone_number" match="2"></format-clipboard>
          </template>
          <template v-slot:item.birthdate="{ item }">
            {{ iso8601ToStandard(item.birthdate) }}
          </template>

          <template v-slot:item.username="{ item }">
            <p class="text-wrap my-auto">
              {{item.username}}
            </p>
          </template>

          <template v-slot:item.details="{ item }">
            <v-btn :disabled="!customerCardAccess()" class="my-3 px-3 py-0 mx-auto text-capitalize" outlined large :style="{color: customerCardAccess() ? '#2196F3' : 'grey'}"
                   type="submit" :href="'/customer/'+item.service_id+'/'+item.id_client">{{ texts.details }}</v-btn>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import {getJson} from "@/store/get";
import {mapGetters} from "vuex";
import * as utils from "@/utils";
import FormatClipboard from "@/components/FormatClipboard";

export default {
  name: "SearchResult",
  props: [],
  components:{
    FormatClipboard
  },
  data() {
    return {
      tec: require("@/assets/tec.png"),
      bmc: require("@/assets/bmc.png"),
      activePicker: null,
      isButtonShown: false,
      menu: false,
      select: null,
      search: 0,
      states: [],
      loading: false,
      items: [],
      error: false,
      texts: {
        title: null,
        id_client: null,
        id_mobib: null,
        id_tec: null,
        creation_date: null,
        first_name: null,
        last_name: null,
        email: null,
        phone_number: null,
        birthdate: null,
        details: null,
        no_result: null,
        too_many_result: null,
        username: null,
        remoteTransactionRef: null
      }
    }
  },
  created() {
    this.getText()
    this.goSearch(this.$route.params.search, this.$route.params.param1, this.$route.params.param2)
  },
  computed:{
    ...mapGetters({ User: "StateUser", Config: "StateConfig", Role: "StateRole" }),

    headers() {
      let headers = []
      if (this.Config.displayServiceId) {
          headers.push({ text: this.texts.id_service, align: 'start', sortable: true, value: 'service_id',  class: "grey lighten-5"})
      }
      if (this.Config.remoteTransactionRef) {
        headers.push({ text: this.texts.remoteTransactionRef, align: 'start', sortable: true, value: 'remoteTransactionRef',  class: "grey lighten-5"})
      }
      if (this.Config.idClient) {
        headers.push({ text: this.texts.id_client, align: 'start', sortable: true, value: 'id_client',  class: "grey lighten-5"})
      }
      if (this.Config.idMobib) {
        headers.push({ text: this.texts.id_mobib, align: 'center', sortable: true, value: 'id_mobib', class: "grey lighten-5"})
      }
      if (this.Config.idTec) {
        headers.push({ text: this.texts.id_tec, align: 'center', sortable: true, value: 'id_tec', class: "grey lighten-5"})
      }
      if (this.Config.login) {
        headers.push({ text: this.texts.username, align: 'center', sortable: true, value: 'username', class: "grey lighten-5"})
      }
      headers.push({ text: this.texts.creation_date, align: 'start', sortable: true, value: 'creation_date', class: "grey lighten-5"})
      if (this.Config.fullName) {
        headers.push({ text: this.texts.first_name, align: 'start', sortable: true, value: 'first_name', class: "grey lighten-5"})
        headers.push({ text: this.texts.last_name, align: 'start', sortable: true, value: 'last_name', class: "grey lighten-5"})
      }
      if (this.Config.email) {
        headers.push({ text: this.texts['email'], align: 'start', sortable: true, value: 'email', class: "grey lighten-5"})
      }
      if (this.Config.phone) {
        headers.push({ text: this.texts.phone_number, align: 'start', sortable: false, value: 'phone_number', class: "grey lighten-5"})
      }
      if (this.Config.nameBirth) {
        headers.push({ text: this.texts.birthdate, align: 'center', sortable: true, value: 'birthdate', class: "grey lighten-5"})
      }
      headers.push({ text: '', align: 'center', sortable: false, value: 'details', class: "grey lighten-5"})
      return headers
    }
  },
  methods: {
    autoRedirect() {
      if (this.items.length === 1 && this.Config.searchAutoRedirect) {
        setTimeout(() => {
          if (this.items[0].service_id === 0) {
            this.$router.push("/customer/"+this.Config.ServiceId +"/"+ this.items[0].id_client).then(() => {
            }).catch(() => {})
          } else {
            this.$router.push("/customer/"+this.items[0].service_id +"/"+ this.items[0].id_client).then(() => {
            }).catch(() => {})
          }
        }, 0)
      }
    },
    getImgSrc(serviceId) {
      if (serviceId === 170)
            return this.tec
      else if (serviceId === 201)
          return this.bmc
      else
          return this[this.Config.ServiceId]
    },
    goSearch(search, one, two){
      this.$route.params.search = search
      this.$route.params.param1 = one
      this.$route.params.param2 = two
      if (one === "void") {
        one = ""
      }
      if (two === "void") {
        two = ""
      }
      this.loading = true
      getJson('/search/' + search + '/' + one + '/' + two, null)
          .then((resp) => {
            if (resp?.status === 200) {
              if (resp.data) {
                if (resp.data.error != null) {
                  this.items = []
                  this.error = true
                } else {
                  if (resp.data.length === undefined) {
                    this.items = [resp.data]
                    if (this.items[0].id_client === "") {
                      this.items = []
                    }
                  } else {
                    if (resp.data) {
                      this.items = resp.data
                    }
                    if (this.items[0].id_client === "") {
                      this.items = []
                    }
                  }
                  this.error = false
                }
              }
              this.loading = false
              this.autoRedirect()
            } else if (resp?.status === 204) {
              this.items = []
              this.error = false
              this.loading = false
            } else if (resp?.status === 400) {
              console.log(resp?.data)
              this.$router.push("/").then(() => {}).catch(() => {})
            }
          }).catch ((err) => {
            console.log(err)
          })
    },
    getItems() {
      if (this.items.length > 19 && this.Config.app === "tec") {
        return []
      } else {
        return this.items
      }
    },
    getInfoText() {
      if (this.error) {
        return this.texts.too_many_result
      }
      if (this.items.length === 0) {
        return this.texts.no_result
      }
      return ""
    },
    utcToStandard(utc){
      return utils.utcToStandard(utc)
    },
    iso8601ToStandard(t){
      return utils.iso8601ToStandard(t)
    },
    getText() {
      if (this.User) {
        getJson("/text/search_result/" + this.User.language.id).then((resp) => {
          if (resp.status === 200) {
            this.texts = resp.data.SearchResultView[0]
          }
        })
      }
    },
    customerCardAccess() {
      return this.Role?.access_customer_card
    },
  },
  watch: {
    "User": function () {
      this.getText()
    },
    "User.language": function () {
      this.getText()
    },
    watch: {
      '$route.params': function() {
        this.goSearch(this.$route.params.search, this.$route.params.param1, this.$route.params.param2)
      },
    }
  },
}
</script>

<style scoped>
tbody {
  overflow: auto;
}
</style>