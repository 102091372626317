<template>
  <v-dialog v-model="dialog" transition="dialog-transition" max-width="800">
    <template v-slot:activator="{ on, attrs }">
      <v-btn v-bind="attrs" v-on="on"
             plain
             color="red darken-1" class="text-none"
             :disabled="!Role.access_delete_customer"
             >
        <v-icon class="mr-3">mdi-close-circle-outline</v-icon>
        {{texts.deleteCustomer}}
      </v-btn>
    </template>

    <v-card>
      <v-card-title class="mb-2 center-text">
        {{ texts.warning_title }}
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text class="my-4">
        {{test}}
      </v-card-text>
      <v-card-text class="my-4">
        <v-alert color="red" elevation="3" type="error">{{texts.warning_revert}}.</v-alert>
      </v-card-text>
      <v-card-text>
        <v-text-field required outlined
                      color="red" v-model="ground" :label="texts.tec_id" ></v-text-field>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions class="mt-2">
        <v-btn depressed large outlined
               class="text-none custom-transform-class" color="red"
               @click="invalidateClient()" :disabled="lockButton()" :dark="!lockButton()">
          {{ texts.warning_confirm }}</v-btn>
        <v-spacer/>
        <v-btn depressed large outlined dark
               class="text-none custom-transform-class" color="blue darken-3"
               @click="dialog = false">{{ texts.warning_cancel }}</v-btn>
      </v-card-actions>
    </v-card>

  </v-dialog>
</template>

<script>
import {mapGetters} from "vuex";
import {postJson} from "@/store/post";
import {utcToStandard} from "@/utils";

export default {
  name: "Customer",
  props: ['texts', 'client'],
  data() {
    return {
      dialog: false,
      ground: null,
    };
  },
  computed: {
    ...mapGetters({ User: "StateUser", Role: "StateRole" }),
    test() {
      if (this.texts.warning_body == null || this.client["last-name"] == null) {
        return ""
      }
      return this.texts.warning_body.split("${client}")
          .join(this.client["first-name"] + " " + this.client["last-name"].toUpperCase())
    }
  },
  created() {},
  methods: {
    lockButton() {
      return (this.ground === null || this.ground === "" || this.ground.split(" ").filter((a) => a).length < 1)
    },
    DateUtcToStandard(utc) {
      return utcToStandard(utc);
    },
    invalidateClient() {
      let c = {
        ground: this.ground,
        remote_customer_reference: this.client.remote_customer_reference.String,
      }
      postJson("/actions/invalidate/client/"+this.$route.params.client_id, c).then((resp) => {
        if (resp && resp.status && resp.status === 200) {
          console.log(resp)
        } else if (resp && resp.status && resp.status === 204) {
          console.log(resp)
        }
      }).catch(() => {})
      this.dialog = false
    },

  },
};
</script>
<style scoped>
.custom-transform-class{
  text-transform: uppercase
}
</style>