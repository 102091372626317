<template>
  <search />
</template>

<script>
import Search from "@/components/Search";

export default {
  name: 'Home',

  components: {
    Search
  },
}
</script>
