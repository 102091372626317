<template>
  <v-data-table hide-default-footer
                class="pa-0" :items-per-page="-1"
                :headers="headers" :items="data.slice(2, data.length-2)"  :disable-sort="true"
                :group-by="Config.displayPurchasedAggregatedWeekStats">
    <template v-slot:body.prepend="{ headers }">
      <tr v-for="(item, i) in data.slice(0,2)" :key="i">
        <td v-for="(header, headerIndex) in headers" :key="i + '-' + headerIndex">
          <div v-if="header.value !=='logo'">
            <div v-if="header.value.match(/^\d/)">
              <div :class="getClassByItemAndHeader(item)" :style="getStyleByItemTypeAndHeader(item, header)" :align="header.align">
                {{ item[header.value] }}
              </div>
            </div>
            <div v-else :class="getClassByItemAndHeader(item)" :style="getStyleByItemTypeAndHeader(item, header)" :align="header.align">
              {{ item[header.value]}}
            </div>
          </div>
          <div v-else>
            <div v-if="shouldDisplayLogo(item)">
              <v-img width="40" :src="getImgSrc(item.contract_tariff_provider)" transition="scale-transition"></v-img>
            </div>
          </div>
        </td>
      </tr>
    </template>

    <template v-slot:group="{ items, expand }">
      <tr @click="toggle(items[0][Config.displayPurchasedAggregatedWeekStats])" :digi="expand">
        <td  class="text-xs-right" style="display: flex; align-content: center">
          <div style="display: block" class="ma-auto">
            <span v-if="User.display_stats_tooltips">
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <span v-bind="attrs" v-on="on">
                  <v-img  width="40" :src="getImgSrc(items[0].contract_tariff_provider)"
                          transition="scale-transition"></v-img>
                </span>
              </template>
              <span>{{ Config.displayPurchasedAggregatedWeekStats }}: {{ items[0][Config.displayPurchasedAggregatedWeekStats] }}</span>
            </v-tooltip>
            </span>
            <span v-else>
              <v-img  width="40" :src="getImgSrc(items[0].contract_tariff_provider)"
                      transition="scale-transition"></v-img>
            </span>
          </div>
          <v-icon style="align-self: center" dense>{{items[0].hide? 'mdi-chevron-right': 'mdi-chevron-down' }}</v-icon>
        </td>
        <td v-for="(header, hI) in headers.slice(1)" :key="items[0][Config.displayPurchasedAggregatedWeekStats] + '-' + header.value + '-' + hI">
          <div v-if="header.value !== 'logo' && isDate(header.value)" :class="getClassByItemAndHeader(items[0])" :style="getStyleByItemTypeAndHeader(items[0], header)" :align="header.align">
            {{displayTotalSum(items, header.value)}}
          </div>
          <div v-else-if="header.value !== 'logo'" :class="getClassByItemAndHeader(items[0])" :style="getStyleByItemTypeAndHeader(items[0], header)" :align="header.align">
            {{displayTotalSum(items, header.value)}}
          </div>
        </td>

      </tr>
      <tr v-for="(item, index) in items" :key="item[Config.displayPurchasedAggregatedWeekStats] + '-' + index" v-show="!item.hide" style="background: #f9f9f9;">
        <td v-for="(header, hI) in headers" :key="item[Config.displayPurchasedAggregatedWeekStats] + '-' + index + '-' + header.value + '-' + hI">
          <div :class="getClassByItemAndHeader(item)" :style="getStyleByItemTypeAndHeader(item, header)" :align="header.align">{{ item[header.value] }}</div>
        </td>
      </tr>
    </template>

    <template v-slot:body.append="{ headers }">
      <tr v-for="(item, i) in data.slice(-2)" :key="item.type + '-' + i">
        <td v-for="(header, headerIndex) in headers" :key="item.type + '-' + i + '-' + headerIndex">
          <div v-if="header.value !=='logo'">
            <div v-if="header.value.match(/^\d/)">
              <div :class="getClassByItemAndHeader(item)" :style="getStyleByItemTypeAndHeader(item, header)" :align="header.align">
                {{ item[header.value] }}
              </div>
            </div>
            <div v-else :class="getClassByItemAndHeader(item)" :style="getStyleByItemTypeAndHeader(item, header)" :align="header.align">
              <div v-if="(header.value === 'total_percentage' || header.value === 'money_amount_percentage') && i === 1">100 %</div>
              <div v-else>{{ item[header.value]}}</div>
            </div>
          </div>
        </td>
      </tr>
    </template>
  </v-data-table>
</template>

<script>
import {mapGetters} from "vuex";
import * as utils from "@/utils";

export default {
  name: "PurchasesAggregatedDataTable",
  props: {
    headers: {
      type: Array,
      value: [],
    },
    rawdata: {
      type: Array,
      value: [],
    },
    contractTariffProviderLogos: {
      type: Array,
      value: [],
    }
  },
  data() {
    return {
      data: [],
    }
  },
  created() {
    this.data = this.rawdata
    this.data.forEach(el => {
      el.hide = true
    })
  },
  computed: {
    ...mapGetters({User: "StateUser", Config: "StateConfig"})
  },
  methods: {
    isDate(value) {
      return value.split('-').length === 3
    },
    displayTotalSum(items, key) {
      let sum = 0;
      items.forEach(item => {
        if (item[key]) {
          if (key === 'total_percentage' || key === 'money_amount_percentage' || key === 'money_amount') {
            sum = sum + parseFloat((item[key].slice(0, item[key].length-1).replace(',', '.')))
          } else {
            sum = sum + parseFloat(item[key])
          }
        }
      })
      if (key === 'total_percentage' || key === 'money_amount_percentage') {
        if (sum > 99.98) {
          sum = 100
        }
      }
      if (key === 'total_percentage' || key === 'money_amount_percentage' || key === 'money_amount') {
        sum = sum.toFixed(2)
      }
      if (key === 'total_percentage' || key === 'money_amount_percentage') {
        sum += " %"
      }
      if (key === 'money_amount') {
        sum += " €"
      }
      if (key === 'total_percentage' || key === 'money_amount_percentage' || key === 'money_amount') {
        sum = sum.replace('.', ',')
      }
      return sum
    },
    toggle(param) {
      this.data.forEach(el => {
        if (el[this.Config.displayPurchasedAggregatedWeekStats] === param) {
          if (el.hide === undefined) {
            el.hide = true
          } else {
            el.hide = !el.hide;
          }
        }
      });
      this.data = JSON.parse(JSON.stringify(this.data))
    },
    getImgSrc(contractTariffProvider) {
      let i = 0
      for (i=0; i<this.contractTariffProviderLogos.length; i++) {
        if (this.contractTariffProviderLogos[i]?.ContractTariffProvider === contractTariffProvider) {
          return this.contractTariffProviderLogos[i].Image
        }
      }
      return utils.networks[this.Config.logo]
    },
    shouldDisplayLogo(item) {
      return item[this.Config.displayPurchasedAggregatedWeekStats] !== undefined
    },
    getStyleByItemTypeAndHeader(item, header)  {
      let style = "";
      if (header.value === 'money_amount' || header.value === 'money_amount_percentage')
        style += "color: #4bce67;"
      else if (header.value === 'total' || header.value === 'total_percentage')
        style += "color: #0275d8;"
      if ((('type' in item) && item.type === 'total') ||
          (header.value.match(/^\d/) && ('type' in item) && item.type === 'data'))
        style += "font-weight: bold;"
      if (('type' in item) && item.type === 'sumup_sales')
        style += "font-style: italic;"
      return style
    },
    getClassByItemAndHeader(item) {
      let classStyle = "";
      if (item && (item.type === 'data' || item.type === 'separator' || item.type === 'total' || item.type === 'installations'))
        classStyle += "font-weight-medium "
      if (('type' in item) && item.type === 'sumup_sales')
        classStyle += "font-italic "
      return classStyle
    }
  },
  watch: {
    rawdata() {
      this.data = this.rawdata
      this.data.forEach(el => {
        el.hide = true
      })
    }
  }
}
</script>
