<template>
  <v-container fluid fill-height class="login">
    <v-row>
      <v-col align="center">
        <v-card class="mx-auto my-12 py-0" max-width="40%" elevation="0" :loading="loading">
          <v-card-title class="justify-center text-h2 mt-3 text-wrap">{{ texts.forgotPassword }} ?</v-card-title>

          <v-spacer/>

          <v-card-text>
            <v-container>
              <v-row align="center">
                <v-col cols="12">
                  <v-text-field :label="texts.email" type="email" name="email" v-model="form.email"></v-text-field>
                </v-col>
              </v-row>
              <v-row align="center">
                <v-col class="pa-2">
                  <v-btn
                      outlined large
                      class="my-4 text-capitalize" color="blue" type="submit"
                      :disabled="buttonDisabled"
                      @click="login">{{ texts.submit }}</v-btn>
                  <p
                      v-if="showError"
                      id="error" class="red--text font-weight-bold">{{ texts.error }}</p>
                </v-col>
              </v-row>
              <v-row>
                <v-col class="pa-0">
                  <v-btn
                      small plain
                      class="mx-0 text--black text-lowercase" to="/login">{{ texts.login }}</v-btn>
                </v-col>
              </v-row>

            </v-container>
          </v-card-text>
        </v-card>

      </v-col>
    </v-row>

    <v-dialog
        v-model="showGranted"
        transition="dialog-top-transition" max-width="55%">
      <v-card>
        <v-toolbar max-height="40" color="green accent-4"></v-toolbar>
        <v-card-text>
          <div class="text-center text-h2 pa-8">{{ texts.granted }}</div>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import { mapActions } from "vuex";
import {getJson} from "@/store/get";

export default {
  name: "ForgottenPassword",
  components: {},
  data() {
    return {
      loading: false,
      loadingColor: "blue",
      texts: {
        login: "Back to login",
        forgotPassword: "Forgotten passord",
        email: "Email",
        submit: "Send me the instructions",
        granted: "Instructions send by email",
        error: "Incorrect email"
      },

      form: {
        email: "",
      },
      showError: false,
      showGranted: false
    };
    },
  computed: {
    buttonDisabled(){
      return !this.validateEmail(this.form.email)
    }
  },
  created() {
    this.getText()
  },
  methods: {
    ...mapActions(["ResetPassword"]),
    getText() {
      getJson("/text/forgotten_password/"+navigator.language.split('-')[0]).then((resp) => {
        if (resp && resp.status === 200) {
          this.texts = resp.data.ForgottenPasswordView[0]
        }
      })
    },
    login() {
      this.loading = this.loadingColor
      let user = {
        "email": this.form.email
      }
      this.showError = false;
      this.ResetPassword(user).then((resp) => {
        if (resp.status === 200) {
          this.showError = false
          this.loading = false
          this.showGranted = true

          setTimeout(() => {
            this.$router.push("/login").then(() => {

            }).catch((err) => {
              console.log(err)
            })
          }, 1000)
        }
      }).catch (() => {
        this.loading = false
        this.showError = true
      })
    },
    validateEmail(email) {
      const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(email);
    }
  },
};
</script>

<style scoped>
</style>
