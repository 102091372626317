<template>
  <v-container :fluid="!nav_bar" :fill-height="!nav_bar" class="search" :style="nav_bar? 'height: 64px!important': ''">
    <v-row :style="nav_bar? 'height: 64px!important': ''">
      <v-col :align="!nav_bar? 'center' : ''" :style="nav_bar? 'height: 64px!important': ''" class="py-0">
        <v-card :style="'background-color: rgba(0,0,0,0)'"
                :class="nav_bar? 'd-flex flex-row': 'mx-3 my-12 py-0 d-flex flex-row'"
                :min-width="nav_bar? '30%': '650'" :max-width="nav_bar? '60%': '50%'" elevation="0">
          <v-select
              v-model="select"
              hide-details outlined single-line
              :style="nav_bar? 'max-width: 50%!important;': ''"
              :items="states"
              :menu-props="{
                closeOnClick: false,
                closeOnContentClick: false,
                disableKeys: true,
                openOnClick: false,
                maxHeight: 604,
              }"
              :label="texts.label"
              :dense="nav_bar" :class="nav_bar? 'mt-3': ''"
              :prepend-icon="!nav_bar? 'mdi-magnify': ''"
              @change="searchIsChanged"
          ></v-select>
          <v-text-field
              v-if="inputs.one.isShown"
              v-model="inputs.one.text"
              clearable single-line outlined
              hide-details="auto"
              :dense="nav_bar" :class="nav_bar? 'mt-3 mx-3': 'mx-3'"
              :label="nav_bar ? '': inputs.one.label"
              :rules="activeRules"
              @keyup.enter="searchCustomers"/>
          <v-text-field
              v-if="inputs.two.isShown"
              v-model="inputs.two.text"
              clearable single-line outlined
              hide-details="auto"
              :dense="nav_bar"
              :class="nav_bar? 'mt-3 mx-3': 'mx-3'"
              :label="inputs.two.label"
              @keyup.enter="searchCustomers"/>
          <v-menu
              v-if="inputs.birth.isShown"
              v-model="menu"
              offset-y
              min-width="auto" transition="scale-transition"
              :close-on-content-click="false" :nudge-right="40">
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                  v-bind="attrs" v-on="on"
                  v-model="inputs.birth.date"
                  readonly clearable
                  class="mx-3" prepend-inner-icon="mdi-calendar"
                  :style="nav_bar? 'margin-top: 6px!important;': ''"
                  :label="nav_bar && inputs.birth.date.length>0? '': 'Date'"/>
            </template>
            <v-date-picker
                v-model="inputs.birth.date"
                min="1900-01-01"
                :active-picker.sync="activePicker"
                :max="(new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10)"
                :first-day-of-week="1" :locale="getLocale"
                @input="menu = false"/>
          </v-menu>
          <v-btn
              v-if="isButtonShown"
              outlined
              color="primary" type="submit"
              style="height: auto!important; padding: 2px 23px!important"
              :disabled="!isButtonDisabled"
              :class="nav_bar? 'mt-3 mx-auto text-capitalize': 'mx-auto text-capitalize'"
              :small="nav_bar" :x-large="!nav_bar"
              @click="searchCustomers">
            {{ nav_bar? '' : texts.label }}
            <v-icon v-if="nav_bar" color="digilogoprimary">mdi-magnify</v-icon>
          </v-btn>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import {getJson} from "@/store/get";
import {mapActions, mapGetters} from "vuex";
import {getTheme} from "@/utils";

export default {
  name: "Search",
  props: ["nav_bar"],
  data() {
    return {
      activePicker: null,
      isButtonShown: false,
      menu: false,
      select: null,
      search: 0,
      states: [],
      inputs: {
        state: "",
        one: {
          isShown: false,
          text: "",
          label: ""
        },
        two: {
          isShown: false,
          text: "",
          label: ""
        },
        birth: {
          isShown: false,
          date: null
        }
        },
      activeRules: [],
      rules: {
        required: value => !!value || '',
        email: value => {
          const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          return !!pattern.test(value) || ''
        },
        number: value => {
          const pattern = /^(.*)$/
          return !!pattern.test(value) || ''
        }
        },
      texts: {
        label: null,
        client: null,
        fullName: null,
        nameBirth: null,
        firstnameBirth: null,
        idMobib: null,
        idClient: null,
        idTec: null,
        wallet: null,
        deciWallet: null,
        hexaWallet: null,
        purchase: null,
        idPurchase: null,
        authNumber: null,
        login: null,
        email: null,
        phone: null,
        remoteTransactionRef: null,
      }
    }
  },
  created() {
    this.$vuetify.theme.dark = this.User?.theme
    this.getText()
    this.inputs.one.text = this.SearchOne
    this.inputs.two.text = this.SearchTwo
    this.inputs.birth.date = this.SearchBirth
    if (this.inputs.one.text === null) {
      this.inputs.one.text = ""
    }
    if (this.inputs.two.text === null) {
      this.inputs.two.text = ""
    }
    if (this.inputs.birth.date === null) {
      this.inputs.birth.date = ""
    }
  },
  computed:{
    ...mapGetters({ User: "StateUser", SearchW: "StateSearch", SearchOne: "StateSearchOne",
      SearchTwo: "StateSearchTwo", SearchBirth: "StateSearchBirth", ConnectCRM: "StateConnectCRM", Config: "StateConfig" }),

    isButtonDisabled() {
      if (this.inputs.state === "first") {
        if (this.inputs.one.text === null) { return false }
        for (let i=0; i<this.activeRules?.length; i++) {
          if (!this.activeRules[i](this.inputs.one.text)) {
            return false
          }
        }
        return (this.inputs.one.text.length > 0)
      } else if (this.inputs.state === "texts") {
        if (this.inputs.one.text === null) { return false }
        return (this.inputs.one.text.length > 0 || this.inputs.two.text.length > 0)
      } else if (this.inputs.state === "textBirth") {
        if (this.inputs.one.text === null) { return false }
        return (this.inputs.one.text.length > 0 || this.inputs.birth.date)
      } else {
        return false
      }
      },
    getLocale() {
      return this.$store.getters.StateLocale.toLowerCase().replace('_', '-')
    },
  },
  methods: {
    ...mapActions(["UpdateSearch"]),
    getTheme(color) {
      return getTheme(this.$vuetify, color)
    },
    searchCustomers() {
      if (!this.isButtonDisabled) {
        return
      }
      let one = this.inputs.one.text
      let two = this.inputs.two.text
      if (this.search === "nameBirth" || this.search === "firstnameBirth" )
        two = this.inputs.birth.date
      if (one === "") {
        one = "void"
      }
      if (two === "") {
        two = "void"
      }

      if (this.search === "idMobib") {
        this.$router.push("/customer/" + this.one)
      }

      this.$router.push("/result/" + this.search + "/" + one + "/" + two).then(() => {}).catch(() => {})
    },
    showFirstInput() {
      this.inputs.two.isShown = false
      this.inputs.birth.isShown = false
      this.inputs.one.label = ""
      this.inputs.state = "first"
      this.inputs.one.isShown = true
      this.isButtonShown = true
    },
    showBothTexts () {
      this.inputs.birth.isShown = false
      this.inputs.state = "texts"
      this.inputs.one.label = this.texts.fullName.split("/")[0]
      this.inputs.two.label = this.texts.fullName.split("/")[1]
      this.inputs.one.isShown = true
      this.inputs.two.isShown = true
      this.isButtonShown = true
    },
    showTextBirth() {
      this.inputs.two.isShown = false
      this.inputs.state = "textBirth"
      this.inputs.one.label = this.texts[this.search].split("/")[0]
      this.inputs.two.label = this.texts[this.search].split("/")[1]
      this.inputs.one.isShown = true
      this.inputs.birth.isShown = true
      this.isButtonShown = true
    },
    searchIsChanged() {
      this.activeRules = [this.rules.required]
      if (this.texts.fullName === this.select) {
        this.search = "fullName"
        this.showBothTexts()
      }
      else if (this.texts.nameBirth === this.select) {
        this.search = "nameBirth"
        this.showTextBirth()
      }
      else if (this.texts.firstnameBirth === this.select) {
        this.search = "firstnameBirth"
        this.showTextBirth()
      }
      else if (this.texts.idMobib === this.select) {
        this.search = "idMobib"
        this.showFirstInput()
        this.activeRules.push(this.rules.number)
      }
      else if (this.texts.idClient === this.select) {
        this.search = "idClient"
        this.showFirstInput()
        this.activeRules.push(this.rules.number)
      }
      else if (this.texts.idTec === this.select) {
        this.search = "idTec"
        this.showFirstInput()
        this.activeRules.push(this.rules.number)
      }
      else if (this.texts.deciWallet === this.select) {
        this.search = "deciWallet"
        this.showFirstInput()
        this.activeRules.push(this.rules.number)
      }
      else if (this.texts.hexaWallet === this.select) {
        this.search = "hexaWallet"
        this.showFirstInput()
      }
      else if (this.texts.idPurchase === this.select) {
        this.search = "idPurchase"
        this.showFirstInput()
        this.activeRules.push(this.rules.number)
      }
      else if (this.texts.authNumber === this.select) {
        this.search = "authNumber"
        this.showFirstInput()
      }
      else if (this.texts.login === this.select) {
        this.search = "login"
        this.showFirstInput()
      }
      else if (this.texts.email === this.select) {
        this.search = "email"
        this.showFirstInput()
        this.activeRules.push(this.rules.email)
      }
      else if (this.texts.phone === this.select) {
        this.search = "phone"
        this.showFirstInput()
      }
      else if (this.texts.remoteTransactionRef === this.select) {
        this.search = "remoteTransactionRef"
        this.showFirstInput()
      }
    },
    updateSearch() {
      this.select = this.texts[this.search]
      this.searchIsChanged()
    },
    getText() {
      if (this.User) {
        getJson("/text/search/" + this.User.language.id).then((resp) => {
          if (resp?.status === 200) {
            this.texts = resp?.data?.SearchView[0]
            this.updateStates()
            this.select = this.texts[this.SearchW]
            this.searchIsChanged()
          }
        })
      }
    },
    updateStates() {
      this.states = []
      this.states.push({
        disabled: false,
        divider: true,
        header: this.texts.client.toUpperCase()
      })
      if (this.ConnectCRM) {
        this.addToStates("fullName")
        this.addToStates("nameBirth")
        this.addToStates("firstnameBirth")
      }
      if (this.Config.remoteTransactionRef) {
        this.addToStates("remoteTransactionRef")
      }
      this.addToStates("idMobib")
      this.addToStates("idClient")
      this.addToStates("idTec")
      if (this.ConnectCRM) {
        this.addToStates("login")
      }
      if (this.ConnectCRM) {
        this.addToStates("email")
        this.addToStates("phone")
      }
      this.states.push({
        disabled: false,
        divider: true,
        header: this.texts.wallet.toUpperCase()
      })
      this.addToStates("deciWallet")
      this.addToStates("hexaWallet")
      this.states.push({
        disabled: false,
        divider: true,
        header: this.texts.purchase.toUpperCase()
      })
      this.addToStates("idPurchase")
      this.addToStates("authNumber")
    },
    addToStates(key) {
      if (this.Config[key]) {
        this.states.push({
          text: this.texts[key],
          disabled: false,
          divider: false
        })
      }
    }
  },
  watch: {
    "User.language": function () {
      this.getText()
      this.select = this.texts[this.SearchW]
    },
    menu (val) {
      val && setTimeout(() => (this.activePicker = 'YEAR'))
    },
    "search": function () {
      this.inputs.one.text = ""
      this.inputs.two.text = ""
      this.UpdateSearch(this.search)
    },
    SearchW (val) {
      this.search = val
      this.updateSearch()
    },
    SearchOne (val) {
      this.inputs.one.text = val
    },
    SearchTwo (val) {
      this.inputs.two.text = val
    },
    SearchBirth (val) {
      this.inputs.birth.date = val
    },
    "inputs.one.text": function() {
      if (this.inputs.one.text === null) {
        this.inputs.one.text = ""
      }
      this.$store.commit("setSearchOne", this.inputs.one.text)
    },
    "inputs.two.text": function() {
      if (this.inputs.two.text === null) {
        this.inputs.two.text = ""
      }
      this.$store.commit("setSearchTwo", this.inputs.two.text)
    },
    "inputs.birth.date": function() {
      if (this.inputs.birth.date === null) {
        this.inputs.birth.date = ""
      }
      this.$store.commit("setSearchBirth", this.inputs.birth.date)
    }
  },
}
</script>
