<template>
	<v-container v-if="data" style="min-width:85%;" class="ma-auto">
		<v-row class="mt-6">
      <v-hover>
        <template v-slot:default="{ hover }">
			<v-card style="min-width: 100% !important;" class="ma-1 transition-swing rounded-lg" :class="`elevation-${hover ? 3 : 1}`">
				<v-container>
					<v-row>
						<v-col class="text-left">
							<h3 class="font-weight-light">
								<p class="mb-0">{{ texts.total_production }}</p>
								<span class="font-weight-light text--secondary" style="font-size: 1em">{{ data.statistics.dashboard.total_production }} {{texts.days}}</span>
							</h3>
						</v-col>
						<v-col class="text-left">
							<p class="text-subtitle-2 mb-0">
								{{ data.mvad_dashboard.lifetime_data.successful_purchases.purchases.toLocaleString(fixedLocale) }} {{ texts.purchase }}
							</p>
							<p class="text-h4 mb-0">
								{{ data.mvad_dashboard.lifetime_data.successful_purchases.amount.toLocaleString(fixedLocale, {minimumFractionDigits: 2}) }}
								<span class="text-h6 ml-2">€</span>
							</p>
						</v-col>
						<v-col class="text-right">
							<p class="text-subtitle-2 mb-0">{{ texts.installation }}</p>
							<p class="text-h4 mb-0">{{ data.mvad_dashboard.lifetime_data.installations.installations.toLocaleString(fixedLocale) }}</p>
						</v-col>
					</v-row>
				</v-container>
				<v-container>
					<v-row>
						<v-col offset="4" cols="5">
							<v-container fluid class="mx-0 px-0">
								<v-row>
									<v-col>
										<p class="text-subtitle-2 mb-0">{{ texts.first_validation }}</p>
										<p class="text-h4 mb-0">{{ data.mdu_dashboard.lifetime_data.validations.first_validations.toLocaleString(fixedLocale) }}</p>
									</v-col>
									<v-col>
										<p class="text-subtitle-2 mb-0">{{ texts.total_passenger }}</p>
										<p class="text-h4 mb-0">{{ data.mdu_dashboard.lifetime_data.total_passengers_first_validations.toLocaleString(fixedLocale) }}</p>
									</v-col>
								</v-row>
								<v-row>
									<v-col>
										<p class="text-subtitle-2 mb-0">{{ texts.connection_validation }}</p>
										<p class="text-h4 mb-0">{{ data.mdu_dashboard.lifetime_data.validations.connection_validations.toLocaleString(fixedLocale) }}</p>
									</v-col>
									<v-col>
										<p class="text-subtitle-2 mb-0">{{ texts.total_passenger}}</p>
										<p class="text-h4 mb-0">{{ data.mdu_dashboard.lifetime_data.total_passengers_connection_validations.toLocaleString(fixedLocale) }}</p>
									</v-col>
								</v-row>
							</v-container>
						</v-col>
					</v-row>
				</v-container>
			</v-card>
        </template>
      </v-hover>
		</v-row>

		<v-row>
			<v-container class="mt-6 mx-1 pa-0" style="min-width: 100% !important;">
				<v-row class="justify-center">
					<v-col>
            <v-hover>
              <template v-slot:default="{ hover }">
                <v-card :class="`elevation-${hover ? 3 : 1}`" class="rounded-lg">
							<v-container>

								<v-row>
									<v-col>
										<p class="mb-0">{{ dayDateToDisplay }}</p>
										<p class="text--secondary text-subtitle-2">({{ data.statistics.dashboard.day_data.percentage }}{{ texts.percentage_of_day }})</p>
									</v-col>
								</v-row>
								<v-row>
									<v-col>
										<div>
											<p class="text-subtitle-1 ma-0">{{data.mvad_dashboard.day_data.successful_purchases.purchases.toLocaleString(fixedLocale)}} {{ texts.purchase }}</p>
											<p class="text-h4">{{data.mvad_dashboard.day_data.successful_purchases.amount.toLocaleString(fixedLocale, {minimumFractionDigits: 2})}} <span class="text-h5">€</span></p>
										</div>
									</v-col>
									<v-col>
										<div class="text-right">
											<p class="text-subtitle-1 ma-0">{{ texts.installation }}</p>
											<p class="text-h4">{{data.mvad_dashboard.day_data.installations.installations.toLocaleString(fixedLocale)}}</p>
										</div>
									</v-col>
								</v-row>
								<v-row>
									<v-col>
										<div>
											<p class="text-subtitle-1 ma-0">{{ texts.first_validation }}</p>
											<p class="text-h4">{{data.mdu_dashboard.day_data.first_validations.toLocaleString(fixedLocale)}}</p>
										</div>
									</v-col>
									<v-col>
										<div class="text-right">
											<p class="text-subtitle-1 ma-0">{{ texts.connection_validation }}</p>
											<p class="text-h4">{{data.mdu_dashboard.day_data.connection_validations.toLocaleString(fixedLocale)}}</p>
										</div>
									</v-col>
								</v-row>
							</v-container>
                </v-card>
              </template>
          </v-hover>
					</v-col>
					<v-col style="height: 370px; width: 84%; margin: 0 auto;">
            <v-hover>
              <template v-slot:default="{ hover }">
                <v-card :class="`elevation-${hover ? 3 : 1}`" class="rounded-lg">
							<v-container>
								<v-row>
									<v-col>
										<p class="mb-0">{{ texts.from }} {{ weekDateToDisplay }}</p>
										<p class="text--secondary text-subtitle-2">({{ data.statistics.dashboard.week_data.percentage }}{{ texts.percentage_of_week }})</p>
									</v-col>
								</v-row>
								<v-row>
									<v-col>
										<div>
											<p class="text-subtitle-1 ma-0">{{data.mvad_dashboard.week_data.successful_purchases.purchases.toLocaleString(fixedLocale)}} {{ texts.purchase }}</p>
											<p class="text-h4">{{data.mvad_dashboard.week_data.successful_purchases.amount.toLocaleString(fixedLocale, {minimumFractionDigits: 2})}} <span class="text-h5">€</span></p>
										</div>
									</v-col>
									<v-col>
										<div class="text-right">
											<p class="text-subtitle-1 ma-0">{{ texts.installation }}</p>
											<p class="text-h4">{{data.mvad_dashboard.week_data.installations.installations.toLocaleString(fixedLocale)}}</p>
										</div>
									</v-col>
								</v-row>
								<v-row>
									<v-col>
										<div>
											<p class="text-subtitle-1 ma-0">{{ texts.first_validation }}</p>
											<p class="text-h4">{{data.mdu_dashboard.week_data.first_validations.toLocaleString(fixedLocale)}}</p>
										</div>
									</v-col>
									<v-col>
										<div class="text-right">
											<p class="text-subtitle-1 ma-0">{{ texts.connection_validation }}</p>
											<p class="text-h4">{{data.mdu_dashboard.week_data.connection_validations.toLocaleString(fixedLocale)}}</p>
										</div>
									</v-col>
								</v-row>
							</v-container>
                </v-card></template></v-hover>
					</v-col>
					<v-col style="height: 370px; width: 84%; margin: 0 auto;">
            <v-hover>
              <template v-slot:default="{ hover }">
                <v-card :class="`elevation-${hover ? 3 : 1}`" class="rounded-lg">
							<v-container>
								<v-row>
									<v-col>
										<p class="mb-0">{{ texts.from }} {{ monthDateToDisplay }}</p>
										<p class="text--secondary text-subtitle-2">({{ data.statistics.dashboard.month_data.percentage }}{{ texts.percentage_of_month }})</p>
									</v-col>
								</v-row>
								<v-row>
									<v-col>
										<div>
											<p class="text-subtitle-1 ma-0">{{data.mvad_dashboard.month_data.successful_purchases.purchases.toLocaleString(fixedLocale)}} {{ texts.purchase }}</p>
											<p class="text-h4">{{data.mvad_dashboard.month_data.successful_purchases.amount.toLocaleString(fixedLocale, {minimumFractionDigits: 2})}} <span class="text-h5">€</span></p>
										</div>
									</v-col>
									<v-col>
										<div class="text-right">
											<p class="text-subtitle-1 ma-0">{{ texts.installation }}</p>
											<p class="text-h4">{{data.mvad_dashboard.month_data.installations.installations.toLocaleString(fixedLocale)}}</p>
										</div>
									</v-col>
								</v-row>
								<v-row>
									<v-col>
										<div>
											<p class="text-subtitle-1 ma-0">{{ texts.first_validation }}</p>
											<p class="text-h4">{{data.mdu_dashboard.month_data.first_validations.toLocaleString(fixedLocale)}}</p>
										</div>
									</v-col>
									<v-col>
										<div class="text-right">
											<p class="text-subtitle-1 ma-0">{{ texts.connection_validation }}</p>
											<p class="text-h4">{{data.mdu_dashboard.month_data.connection_validations.toLocaleString(fixedLocale)}}</p>
										</div>
									</v-col>
								</v-row>
							</v-container>
                </v-card></template></v-hover>
					</v-col>
				</v-row>
			</v-container>
		</v-row>
	</v-container>
</template>

<script>
import {postJson} from "@/store/post";
import {getJson} from "@/store/get";
import {mapGetters} from "vuex";
export default {
  name: 'Dashboard',
  data() {
    return {
      texts: {},
      data: null,
      fixedLocale: null,
      dayDateToDisplay: "",
      weekDateToDisplay: "",
      monthDateToDisplay: "",
      dateOptions: { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' }
    }
  },
  created() {
    this.fixedLocale = this.Locale.replace('_', '-')
    this.getTexts()
    this.getData()
  },
  computed: {
      ...mapGetters({ User: "StateUser",
                      Locale: "StateLocale" }),
  },
  methods: {
    getTexts() {
      if (this.User) {
        getJson("/text/dashboard/" + this.User.language.id).then((resp) => {
          if (resp?.status === 200) {
              this.texts = resp.data.DashboardView[0]
          }
        })
      }
    },
    getData() {
      postJson("/statistics/dashboard", {"timezone": "Europe/Paris"}).then((resp) => { //TODO: Replace timezone by User's
        if (resp.status === 200) {
          this.data = resp.data
          this.updateDateToDisplay()
        }
      })
    },
    updateDateToDisplay() {
      if (this.fixedLocale) {
        let tmpArray
        tmpArray = (new Date(this.data.statistics.dashboard.day_data.date)).toLocaleDateString(this.fixedLocale, this.dateOptions).split(' ')
        if (this.fixedLocale === 'fr-FR') {
          tmpArray[0] = tmpArray[0].charAt(0).toUpperCase() + tmpArray[0].slice(1)
          tmpArray[2] = tmpArray[2].charAt(0).toUpperCase() + tmpArray[2].slice(1)
        }
        this.dayDateToDisplay = tmpArray.join(' ')

        tmpArray = (new Date(this.data.statistics.dashboard.week_data.date)).toLocaleDateString(this.fixedLocale, this.dateOptions).split(' ')
        if (this.fixedLocale === 'fr-FR') {
          tmpArray[0] = tmpArray[0].charAt(0).toUpperCase() + tmpArray[0].slice(1)
          tmpArray[2] = tmpArray[2].charAt(0).toUpperCase() + tmpArray[2].slice(1)
        }
        this.weekDateToDisplay = tmpArray.join(' ')

        tmpArray = (new Date(this.data.statistics.dashboard.month_data.date)).toLocaleDateString(this.fixedLocale, this.dateOptions).split(' ')
        if (this.fixedLocale === 'fr-FR') {
          tmpArray[0] = tmpArray[0].charAt(0).toUpperCase() + tmpArray[0].slice(1)
          tmpArray[2] = tmpArray[2].charAt(0).toUpperCase() + tmpArray[2].slice(1)
        }
        this.monthDateToDisplay = tmpArray.join(' ')
      }
    }
	},
  watch: {
		"User": function () {
			this.getTexts()
		},
		"User.language": function () {
			this.fixedLocale = this.Locale.replace('_', '-')
			this.getTexts()
			this.updateDateToDisplay()
		},
  },
}
</script>
