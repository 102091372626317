import Vue from "vue";
import VueRouter from "vue-router";
import store from "@/store";
import Home from "@/views/Home.vue";
import Login from "@/views/Login";
import User from "@/views/User";
import SearchResult from "@/views/SearchResult";
import ForgottenPassword from "@/views/ForgottenPassword";
import Customer from "@/views/Customer";
import Statistics from "@/views/Statistics";
import Dashboard from "@/components/statistics/Dashboard";
import Week from "@/components/statistics/Week";
import Month from "@/components/statistics/Month";
import Graphics from "@/components/statistics/Graphics";
import ResetPassword from "@/views/ResetPassword";
import Parameters from "@/views/Parameters";
import Users from "@/components/parameters/User/Users";
import Roles from "@/components/parameters/Role/Roles";
import Invitation from "@/views/Invitation";
import Export from "@/components/statistics/Export";
import Companies from "@/components/parameters/Company/Companies";
import Conf from "@/views/Conf.vue";
import * as check from "@/router/roles";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
    meta: { requiresAuth: true },
  },
  {
    path: '*',
    redirect: '/'
  },
  {
    path: "/login",
    name: "Login",
    component: Login,
    meta: { guest: true },
  },
  {
    path: "/user",
    name: "User",
    component: User,
    meta: { requiresAuth: true },
  },
  {
    path: "/forget-password",
    name: "ForgottenPassword",
    component: ForgottenPassword,
    meta: { guest: true },
  },
  {
    path: "/reset-password/:token",
    name: "ResetPassword",
    component: ResetPassword,
    meta: { guest: true },
  },
  {
    path: "/invitation/:token",
    name: "Invitation",
    component: Invitation,
    meta: { guest: true },
  },
  {
    path: "/result/:search/:param1/:param2",
    name: "SearchResult",
    component: SearchResult,
    meta: { requiresAuth: true },
  },
  {
    path: "/customer/:service_id/:client_id",
    name: "Customer",
    component: Customer,
    meta: { requiresAuth: true },
    beforeEnter: check.checkAccessCustomerCard,
  },
  {
    path: "/conf",
    name: "Conf",
    component: Conf,
    meta: { requiresAuth: true },
  },
  {
    path: "/statistics",
    name: "Statistics",
    component: Statistics,
    meta: { requiresAuth: true },
    children: [
      {
        path: 'dashboard',
        component: Dashboard
      },
      {
        path: 'week',
        component: Week
      },
      {
        path: 'month',
        component: Month
      },
      {
        path: 'graphics',
        component: Graphics
      },
      {
        path: 'exports',
        component: Export,
        beforeEnter: check.checkAccessExport
      }
    ]
  },
  {
    path: "/parameters",
    name: "Parameters",
    component: Parameters,
    meta: { requiresAuth: true },
    children: [
      {
        path: 'users',
        component: Users,
        beforeEnter: check.checkAccessParameters,
      },
      {
        path: 'roles',
        component: Roles,
        beforeEnter: check.checkAccessRole,
      },
      {
        path: 'companies',
        component: Companies,
        beforeEnter: check.checkAccessCompany,
      }
    ]
  }
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});


router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (store.getters.isAuthenticated || to.fullPath === "/login" || to.name === "Invitation" || to.name === "ResetPassword") {
      next();
      return;
    } else {
      next("/login");
      return;
    }
  }

  if (to.matched.some((record) => record.meta.guest)) {
    if (store.getters.isAuthenticated) {
      next("/");
      return;
    } else{
      next();
      return;
    }
  }

  next();
});

export default router;
