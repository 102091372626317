<template>
  <v-app style="overflow: auto; scrollbar-width: thin; background-color: rgb(248, 249, 250)" color="digibackground">
    <nav-bar :profile="texts.profile" :logout="texts.logout" :statistics="texts.statistics" :parameters="texts.parameters"/>

    <v-main color="digibackground">
      <router-view :key="$route.fullPath"/>
    </v-main>
    <v-footer padless style="align-self: flex-end; background-color: rgb(248, 249, 250)" class="mx-2 my-1 grey--text text--lighten-2">{{ getVersions }}</v-footer>

  </v-app>
</template>

<script>
import Vue from 'vue';
import NavBar from '@/components/NavBar'
import {getJson} from "@/store/get";
import {mapActions, mapGetters} from "vuex";

export default {
  name: 'App',
  components: {
    NavBar
  },

  data() {
    return {
      texts: {
        profile: null,
        logout: null,
        statistics: {
          statistics: "",
          dashboard: "",
          week: "",
          month: "",
          graphics: "",
          exports: "",
        },
        parameters: {
          parameters: "",
          users: "",
          invitations: "",
          roles: "",
        }
      }
    }
  },
  computed: {
    ...mapGetters({ User: "StateUser", RememberMe: "StateRememberMe", Config: "StateConfig" }),
    getVersions() {
      if (this.Config === null) {
        return ''
      } else if (this.Config.frontEndVersion !== undefined) {
        return this.Config.frontEndVersion + "-" + this.Config.backEndVersion
      } else {
        return this.Config.backEndVersion
      }
    }
  },
  created() {
    this.$vuetify.theme.dark = this.User?.theme
    this.getTexts()
  },
  mounted() {
    let versions = this.$store.getters.appVersion.split('.')
    // valeur en dur car fixer à partir de la version 1.5.4.
    if ((parseInt(versions[0]) < 1) ||
        (parseInt(versions[0]) === 1 && parseInt(versions[1]) < 5) ||
        (parseInt(versions[0]) === 1 && parseInt(versions[1]) === 5 && parseInt(versions[2]) < 4)) {
      window.location.reload()
    }

    this.getConfig()
    if (this.$store.getters.StateAuth !== null && this.RememberMe || window.localStorage.getItem('vuex')) {
      let data = {token: JSON.parse(window.localStorage.getItem('vuex'))?.auth.token}
      this.LogIn(data).then((resp) => {
        if (resp === false) {
          this.$vuetify.theme.dark = false
          this.$store.dispatch("LogOut")
            if (this.$route.path !== "/login") {
                this.$router.push("/login").then(() => {})
            }
        }
        window.localStorage.setItem('vuex', window.sessionStorage.getItem('vuex'))
        if (this.$route.fullPath === "/login" || this.$route.fullPath === "/forget-password") {
          this.$router.push("/").then(()=>{
          }).catch(()=>{})
        }
      }).catch ((err) => {
        console.error(err)
      })
    }
    Vue.nextTick(() => {
      try {
        for (let i = 0; i<this.$el.parentNode.parentNode.children[0].children.length; i++) {
          if (this.$el.parentNode.parentNode.children[0].children[i].id === 'icon')
            this.$el.parentNode.parentNode.children[0].children[i].href = this[this.Config?.app]
        }
      } catch (error) {
        console.error(error);
        console.log(this.$el);
      }

      if (this.$route.name !== "Invitation" && this.$route.name !== "Login" && this.$route.name !== "ResetPassword") {
        getJson("/user/0").then(() => {})
      }
    })
  },
  methods: {
    ...mapActions(["LogIn", "LogOut", "SetLogos"]),
    isLoggedIn: function() {
      return this.$store.getters.isAuthenticated;
    },

    getTexts() {
      if (this.User) {
        getJson("/text/app/" + this.User.language.id).then((resp) => {
          if (resp?.status === 200) {
            this.texts = resp.data?.AppViews[0]
            this.texts.statistics = {
              statistics: resp.data?.AppViews[0].statistics,
              dashboard: resp.data?.AppViews[0].dashboard,
              week: resp.data?.AppViews[0].week,
              month: resp.data?.AppViews[0].month,
              graphics: resp.data?.AppViews[0].graphics,
              exports: resp.data?.AppViews[0].exports,
            }
            this.texts.parameters = {
              parameters: resp.data?.AppViews[0].parameters,
              users: resp.data?.AppViews[0].users,
              roles: resp.data?.AppViews[0].roles,
              companies: resp.data?.AppViews[0].companies,
            }
            Vue.nextTick(() => {
              document.title = this.texts.title;
            })
          }
        })
      }
    },
    getConfig(){
      getJson("/config").then((resp) => {
        if (resp?.status === 200) {
          this.$store.commit('setConfig', resp.data.config)
        }
      })
    }
  },

  watch: {
    "User.language": function () {
      this.getTexts()
    }
  },
}
</script>
