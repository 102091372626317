<template>
  <v-dialog v-model="dialog" max-width="60%">
    <template v-slot:activator="{ on, attrs }">
      <v-btn v-bind="attrs" v-on="on"
             depressed outlined
             color="red" class="custom-transform-class text-none"
             :dark="!disableButton" :disabled="disableButton">
<!--        <v-icon left>mdi-account-plus-outline</v-icon>-->
        {{ texts.invalidate }}
      </v-btn>
    </template>
    <v-card>
      <v-card-title class="mb-2 center-text">
        {{ texts.warning_title }}
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text class="my-4">
        {{test}}
      </v-card-text>
      <v-card-text class="my-4">
        <v-alert color="red" elevation="3" type="error">{{texts.warning_revert}}.</v-alert>
      </v-card-text>
      <v-card-text>
        <v-text-field required outlined
                      color="red"
                      v-model="ground" :label="texts.warning_ground"></v-text-field>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions class="mt-2">
        <v-btn depressed large outlined
               color="red" class="text-none custom-transform-class"
               @click="invalidateContract()" :disabled="lockButton()" :dark="!lockButton()">
          {{ texts.warning_confirm }}</v-btn>
        <v-spacer></v-spacer>
        <v-btn depressed large outlined dark
               class="text-none custom-transform-class" color="blue darken-3"
               @click="dialog = false">{{ texts.warning_cancel }}</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters } from "vuex";
import { postJson } from "@/store/post";
import { utcToStandard } from "@/utils";

export default {
  name: "Contract",
  props: ['texts', 'contract', 'calypso_number'],
  data() {
    return {
      dialog: false,
      ground: null,
    };
  },
  computed: {
    ...mapGetters({ Role: "StateRole" }),
    disableButton() {
      return ((this.Role && !this.Role.access_invalidate_contract) || (this.contract.contract_status !== 3 && this.contract.contract_status !== 4))
    },
    test() {
      let csnSplit = this.texts.warning_body.split("${contract_serial_number}").join(this.contract.contract_serial_number)
      csnSplit = csnSplit.split("${contract_validity_end_date}").join(this.DateUtcToStandard(this.contract.usage_end_date))
      return csnSplit
    }
  },
  /*created() {},*/
  methods: {
    lockButton() {
      return this.ground === null || this.ground === "" || this.ground.split(" ").filter((a) => a).length < 1;
    },
    DateUtcToStandard(utc) {
      return utcToStandard(utc);
    },
    invalidateContract() {
      let c = {
        ground: ""+this.ground,
        calypso_app: ""+this.calypso_number,
        client_id: ""+this.$route.params.id_client,
      }
      /*let c = { ground: "test" }*/
      postJson("/actions/invalidate/contract/" + this.contract.contract_serial_number, c).then((resp) => {
        if (resp?.status === 200) {
          this.$emit("invalidate-contract", resp.data)
        } else if (resp?.status === 204) {
          console.log(resp)
        }
      }).catch(() => {})

      this.dialog = false
    },

  },
};
</script>
<style scoped>
.custom-transform-class{
  text-transform: uppercase
}
</style>