<template>
  <v-data-table hide-default-footer
                class="pa-0" :items-per-page="-1"
                :headers="headers" :items="data.slice(1, data.length-1)"  :disable-sort="true"
                :group-by="aggregation">
    <template v-slot:body.prepend="{ headers }">
      <tr v-for="(item, i) in data.slice(0,1)" :key="i">
        <td v-for="(header, headerIndex) in headers" :key="i + '-' + headerIndex">
          <div v-if="'type' in item && item.type === 'separator' && header.value === 'name'" class="my-3 font-weight-bold">
            <div>
              {{ item[header.value].first_line }}
            </div>
            <div>
              {{ item[header.value].second_line }}
            </div>
          </div>
        </td>
      </tr>
    </template>

    <template v-slot:group="{ items, expand }">
      <tr @click="toggle(items[0][aggregation])" :digi="expand">
        <td  class="text-xs-right" style="display: flex; align-content: center">
          <div style="display: block" class="ma-auto">
            <span v-if="User.display_stats_tooltips">
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <span v-bind="attrs" v-on="on">
                    <v-img  width="40" :src="getImgSrc(items[0].contract_tariff_provider)"
                            transition="scale-transition"></v-img>
                  </span>
                </template>
                <span>{{ aggregation }}: {{ items[0][aggregation] }}</span>
              </v-tooltip>
            </span>
            <span v-else>
              <v-img  width="40" :src="getImgSrc(items[0].contract_tariff_provider)"
                      transition="scale-transition"></v-img>
            </span>
          </div>
          <v-icon style="align-self: center" dense>{{items[0].hide? 'mdi-chevron-right': 'mdi-chevron-down' }}</v-icon>
        </td>
        <td v-for="(header, hI) in headers.slice(1)" :key="items[0].contract_tariff_provider + '-' + header.value + '-' + hI">
          <div v-if="header.value !== 'tariff_id'" :class="getClassByItemAndHeader(items[0])" :style="getStyleByItemTypeAndHeader(items[0], header)" :align="header.align">
            {{displayTotalSumValidations(items, header.value)}}
          </div>
          <div v-if="header.value !== 'tariff_id'" :class="getClassByItemAndHeader(items[0])" :style="getStyleByItemTypeAndHeader(items[0], header)" :align="header.align">
            {{displayTotalSumValidationsWithConnection(items, header.value)}}
          </div>
        </td>

      </tr>
      <tr v-for="(item, index) in items" :key="item[aggregation] + '-' + index" v-show="!item.hide" style="background: #f9f9f9;">
        <td v-for="(header, hI) in headers" :key="item[aggregation] + '-' + index + '-' + header.value + '-' + hI">
          <div v-if="hI < 1" :class="getClassByItemAndHeader(item)" :style="getStyleByItemTypeAndHeader(item, header)" :align="header.align">
            <span v-if="User.display_stats_tooltips">
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <span v-bind="attrs" v-on="on">
                  {{ item[header.value] }}
                </span>
              </template>
              <span>company_id: {{ item.company_id }} | contract_tariff_provider: {{ item.contract_tariff_provider }} | tariff_id: {{ item.tariff_id }} | event_network_id: {{ item.event_network_id }}</span>
            </v-tooltip>
            </span>
            <span v-else>
              {{ item[header.value] }}
            </span>
          </div>
          <div v-else>
            <div v-if="header.value === 'tariff_id'" :class="getClassByItemAndHeader(item)" :style="getStyleBasedOnItemAndHeader(item, header)" :align="header.align">
              {{ item[header.value] }}
            </div>
            <div v-if="header.value !== 'tariff_id'" :class="getClassByItemAndHeader(item)" :style="getStyleBasedOnItemAndHeader(item, header)" :align="header.align">
              {{ item[header.value].validations }}
            </div>
            <div v-if="header.value !== 'tariff_id'" :class="getClassByItemAndHeader(item)" :style="getStyleBasedOnItemAndHeader(item, header)" :align="header.align">
              {{ item[header.value].validations_with_connection }}
            </div>
          </div>
        </td>
      </tr>
    </template>

    <template v-slot:body.append="{ headers }">
      <tr v-for="(item, i) in data.slice(-1)" :key="item.type + '-' + i">
        <td v-for="(header, headerIndex) in headers" :key="item.type + '-' + i + '-' + headerIndex">
          <div v-if="header.value !=='logo'">
            <div v-if="header.value.match(/^\d/) || header.value === 'total'">
              <div :class="getClassByItemAndHeader(item)" :style="getStyleByItemTypeAndHeader(item, header)" :align="header.align">
                <div :style="getStyleBasedOnItemAndHeader(item, header)">
                  {{ item[header.value].validations }}
                </div>
                <div :style="getStyleBasedOnItemAndHeader(item, header)">
                  {{ item[header.value].validations_with_connection }}
                </div>
              </div>
            </div>
            <div v-else :class="getClassByItemAndHeader(item)" :style="getStyleByItemTypeAndHeader(item, header)" :align="header.align">
              <div v-if="header.value === 'name'">
                {{ item[header.value]}}
              </div>
              <div v-else-if="header.value === 'total_percentage'">
                <div :style="getStyleBasedOnItemAndHeader(item, header)">
                  100 %
                </div>
                <div :style="getStyleBasedOnItemAndHeader(item, header)">
                  100 %
                </div>
              </div>
            </div>
          </div>
        </td>
      </tr>
    </template>
  </v-data-table>
</template>

<script>
import {mapGetters} from "vuex";
import * as utils from "@/utils";

export default {
  name: "ValidationsAggregatedDataTable",
  props: {
    headers: {
      type: Array,
      value: [],
    },
    rawdata: {
      type: Array,
      value: [],
    },
    contractTariffProviderLogos: {
      type: Array,
      value: [],
    },
    overrideAggregation: {
      type: String,
      value: undefined,
    }
  },
  data() {
    return {
      data: [],
    }
  },
  created() {
    this.data = this.rawdata
    this.data.forEach(el => {
      el.hide = true
    })
  },
  computed: {
    ...mapGetters({ User: "StateUser", Config: "StateConfig"}),
    aggregation() {
      if (this.overrideAggregation !== undefined) {
        return this.overrideAggregation
      } else if (this.Config.displayValidationAggregatedWeekStats !== undefined) {
        return  this.Config.displayValidationAggregatedWeekStats
      } else {
        return "contract_tariff_provider"
      }
    },
  },
  methods: {
    isDate(value) {
      return value.split('-').length === 3
    },
    displayTotalSumValidations(items, key) {
      let sum = 0;
      items.forEach(item => {
        if (item[key]) {
          if (key === 'total_percentage') {
            sum += parseFloat(item[key].validations.slice(0, item[key].validations.length-1).replace(',', '.'))
          } else {
            sum += parseFloat(item[key].validations)
          }
        }
      })
      if (key === 'total_percentage') {
        if (sum > 99.98) {
          sum = 100
        }
        sum = sum.toFixed(2)
        sum += " %"
        sum = sum.replace('.', ',')
      }
      return sum
    },
    displayTotalSumValidationsWithConnection(items, key) {
      let sum = 0;
      items.forEach(item => {
        if (item[key]) {
          if (key === 'total_percentage') {
            sum += parseFloat(item[key].validations_with_connection.slice(0, item[key].validations_with_connection.length-1).replace(',', '.'))
          } else {
            sum += parseFloat(item[key].validations_with_connection)
          }
        }
      })
      if (key === 'total_percentage') {
        if (sum > 99.98) {
          sum = 100
        }
        sum = sum.toFixed(2)
        sum += " %"
        sum = sum.replace('.', ',')
      }
      return sum
    },
    toggle(param) {
      this.data.forEach(el => {
        if (el[this.aggregation] === param) {
          if (el.hide === undefined) {
            el.hide = true
          } else {
            el.hide = !el.hide;
          }
        }
      });
      this.data = JSON.parse(JSON.stringify(this.data))
    },
    getImgSrc(contractTariffProvider) {
      let i = 0
      for (i=0; i<this.contractTariffProviderLogos.length; i++) {
        if (this.contractTariffProviderLogos[i]?.ContractTariffProvider === contractTariffProvider) {
          return this.contractTariffProviderLogos[i].Image
        }
      }
      return utils.networks[this.Config.logo]
    },
    shouldDisplayLogo(item) {
      return item[this.Config.displayValidationAggregatedWeekStats] !== undefined
    },
    getStyleBasedOnItemAndHeader(item, header) {
      let style = "";
      if (header.value === 'total' || header.value === 'total_percentage')
        style += "color: #0275d8;"
      if (header.value !== 'name' && ('type' in item) && (item.type === 'data'))
        style += "font-weight: bold;"
      if (header.value === 'name' && ('type' in item) && (item.type === 'data'))
        style += "font-weight: 400;"
      if (('type' in item) && item.type === "total")
        style += "font-weight: bold;"
      return style
    },
    getStyleByItemTypeAndHeader(item, header)  {
      let style = "";
      if (header.value === 'money_amount' || header.value === 'money_amount_percentage')
        style += "color: #4bce67;"
      else if (header.value === 'total' || header.value === 'total_percentage')
        style += "color: #0275d8;"
      if ((('type' in item) && item.type === 'total') ||
          (header.value.match(/^\d/) && ('type' in item) && item.type === 'data'))
        style += "font-weight: bold;"
      if (('type' in item) && item.type === 'sumup_sales')
        style += "font-style: italic;"
      return style
    },
    getClassByItemAndHeader(item) {
      let classStyle = "";
      if (item && (item.type === 'data' || item.type === 'separator' || item.type === 'total' || item.type === 'installations'))
        classStyle += "font-weight-medium "
      if (('type' in item) && item.type === 'sumup_sales')
        classStyle += "font-italic "
      return classStyle
    }
  },
  watch: {
    rawdata() {
      this.data = this.rawdata
      this.data.forEach(el => {
        el.hide = true
      })
    }
  }
}
</script>