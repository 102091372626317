<template>
  <v-container fluid style="height: 90vh">
    <v-row style="height: 100%">
      <v-col align="center" class="my-10" >
        <div class="my-6">
          <p class="text-h2">{{ texts.title }}</p>
        </div>
        <div class="d-flex justify-center mt-4" style="height: 90%; width: 60%">
          <v-card :loading="loading" class="mx-auto my-12 py-0" width="80%" elevation="0">
            <v-card-text>{{texts.context}}</v-card-text>
            <v-card-text>{{texts.subContext}}</v-card-text>
            <v-card-text>{{ texts.rules }}</v-card-text>
            <v-card-text>
              <v-text-field :label="texts.newPassword" type="password" name="old_password" v-model="newPassword" />
              <v-text-field :label="texts.confirmationPassword" type="password" name="old_password" v-model="confirmationPassword" />
              <v-btn class="mt-4 ml-4 custom-transform-class text-none" outlined large color="blue"
                     type="submit" @click="updatePassword" :disabled="disableButton">{{ texts.save }}</v-btn>
            </v-card-text>
          </v-card>
        </div>
      </v-col>
    </v-row>

    <granted-dialog :show_granted="showGranted" :text="texts.granted" :color="showColor"/>
    <granted-dialog :show_granted="showError" :text="texts.error" :color="errorColor"/>
  </v-container>
</template>

<script>
import GrantedDialog from "@/components/GrantedDialog";
import {postJson} from "@/store/post";
import {getJson} from "@/store/get";
import {password_regex} from "@/utils";

export default {
  name: "Invitation",
  components: {
    GrantedDialog
  },
  data() {
    return {
      showColor: "green accent-4",
      showGranted: false,
      showError: false,
      errorColor: "red accent-4",
      newPassword: null,
      confirmationPassword: null,
      loading: false,
      loadingColor: "blue",

      texts: {
        title: "Invitation",
        newPassword: "New password",
        confirmationPassword: "Confirm password",
        context: "Welcome to Mobeepass Customer Care Module.",
        subContext: "Please set a password to complete your account creation.",
        save: "Set up account",
        login: "Log in",
        granted: "Your account is now ready!",
        error: "Something went wrong, please try later"
      },
    };
    },
  computed: {
    disableButton() {
      return (!(this.newPassword?.match(password_regex))
          || (this.newPassword !== this.confirmationPassword));
    }
  },
  created() {
    this.getText()
  },
  methods: {
    getText() {
      getJson("/text/invitation/"+navigator.language.split('-')[0]).then((resp) => {
        if (resp && resp.status === 200) {
          this.texts = resp.data.InvitationView[0]
        }
      })
    },
    updatePassword() {
      this.loading = this.loadingColor
      let resetPassword = {
        "password": this.newPassword,
        "invitation_token": this.$route.params.token
      }
      postJson("/auth/reset-password", resetPassword).then((resp) => {
        if (resp?.status === 200) {
          this.showGranted = true
          let user = {
            "email": resp.data.user.email,
            "password": resetPassword.newPassword
          }
          this.commit('setUser', user)
        } else {
          this.newPassword = ""
          this.confirmationPassword = ""
          this.showError = true
        }
      }).catch(() => {}).then(() => {
        this.loading = false
        this.newPassword = ""
        this.confirmationPassword = ""
        setTimeout(() => {this.showGranted = false}, 1500)
        setTimeout(() => {this.showError = false}, 1500)

        setTimeout(() => {this.$router.push("/login").then(() => {

        }).catch((err) => {
          console.log(err)
        })}, 1700)

      })
    },
  },
};
</script>

<style scoped>
.custom-transform-class{
  text-transform: uppercase
}
</style>
