import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';
import colors from 'vuetify/lib/util/colors';

Vue.use(Vuetify);

export default new Vuetify({
    theme: {
        themes : {
            light: {
                primary: colors.blue.darken2,
                secondary: colors.grey.darken1,
                accent: colors.pink.darken1,
                error: colors.red.accent3,
                background: colors.indigo.lighten5,
                info: colors.teal.darken1,
                digibackground: colors.shades.white,
                digigraphtext: colors.shades.black,
                plain: colors.grey.lighten4,
                digilogoprimary: colors.blue,
                digiactiondetailstext: colors.shades.black,
                digipurchase: colors.grey.lighten2,
                digisubpurchase: colors.grey.lighten3,
                digiwhite: colors.white,
                digiblack: colors.black,
            },
            dark: {
                primary: colors.blue.darken4,
                background: colors.indigo.base,
                info: colors.teal.lighten1,
                digibackground: colors.shades.black,
                digigraphtext: colors.shades.white,
                plain: colors.grey.darken4,
                digilogoprimary: colors.shades.white,
                digiactiondetailstext: colors.shades.white,
                digipurchase: colors.grey.darken2,
                digisubpurchase: colors.grey.darken3,
                digiwhite: colors.grey.darken2,
                digiblack: colors.white,
            },
        },
    },
});
