<template>
  <v-dialog v-model="dialog" max-width="80%">
    <template v-slot:activator="{ on, attrs }">
      <v-btn v-bind="attrs" v-on="on"
             color="primary" class="text-none custom-transform-class"
             :disabled="isAdmin()" :dark="!isAdmin()" :text="!isAdmin()">
        {{ texts.update }}
      </v-btn>
    </template>
    <v-card>
      <v-card-title class="mb-2 center-text">
        <span class="text-h5">Role: {{ role.name }}</span>
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text class="my-4">
        <v-container>
          <v-row>
            <v-text-field v-model="name"
                          required outlined
                          :label="texts.name"></v-text-field>
          </v-row>
          <v-row>
            <v-col>
              <v-row>
                <v-col>
                  <v-checkbox v-model="access_invalidate_contract"
                              required outlined
                              :label="texts.access_invalidate_contract"/>
                </v-col>
                <v-col>
                  <v-checkbox v-model="access_statistics"
                              required outlined
                              :label="texts.access_statistics"/>
                </v-col>
                <v-col>
                  <v-checkbox v-model="access_advanced_search"
                              required outlined
                              :label="texts.access_advanced_search"/>
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <v-checkbox v-model="access_invalidate_card"
                              required outlined
                              :label="texts.access_invalidate_card"/>
                </v-col>
                <v-col>
                  <v-checkbox v-model="access_graphics"
                              required outlined
                              :label="texts.access_graphics"/>
                </v-col>
                <v-col>
                  <v-checkbox v-model="access_parameters"
                              required outlined
                              :label="texts.access_parameters"/>
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <v-checkbox v-model="access_delete_customer"
                              required outlined
                              :label="texts.access_delete_customer"/>
                </v-col>
                <v-col>
                  <v-checkbox v-model="access_archived_customer"
                              required outlined
                              :label="texts.access_archived_customer"/>
                </v-col>
                <v-col>
                  <v-checkbox v-model="access_role"
                              required outlined
                              :label="texts.access_role" :disabled="!Role.is_admin"/>
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <v-checkbox v-if="Config.pushContract" v-model="access_push_contract"
                              required outlined
                              :label="texts.access_push_contract"/>
                </v-col>
                <v-col>
                  <v-checkbox v-model="access_modify_text"
                              required outlined
                              :label="texts.access_modifying_text"/>
                </v-col>
                <v-col>
                  <v-checkbox v-model="access_customer_card"
                              required outlined
                              :label="texts.access_customer_card"/>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions class="mt-2">
        <v-btn depressed large dark
               class="text-none custom-transform-class" color="blue darken-1"
               @click="updateRole()" >{{ texts.update }}</v-btn>
        <v-btn depressed large dark
               class="text-none custom-transform-class" color="blue darken-1"
               @click="dialog = false">{{ texts.cancel }}</v-btn>
        <v-spacer/>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters } from "vuex";
import {putJson} from "@/store/put";

export default {
  name: "UpdateRole",
  props: ['role', 'texts'],
  data() {
    return {
      grantedColor: "green accent-4",
      errorColor: "red accent-4",
      showGranted: false,
      showError: false,
      loading: false,
      loadingColor: "blue",
      dialog: false,
      name: null,
      access_statistics: null,
      access_searches: null,
      access_imei: null,
      access_invitations: null,
      access_download: null,
      access_parameters: null,
      access_transaction_id: null,
      access_delete_account: null,
      access_advanced_search: null,
      access_card_number_hex: null,
      access_graphics: null,
      access_oversight: null,
      access_invalidate_contract: null,
      access_invalidate_card: null,
      access_role: null,
      access_delete_customer: null,
      access_archived_customer: null,
      access_push_contract: null,
      access_modifying_text: null,
      access_customer_card: null,
    };
  },
  computed: {
    ...mapGetters({ User: "StateUser", Role: "StateRole", Config: "StateConfig" })
  },
  created() {
    this.name = this.role.name
    this.access_statistics = this.role.access_statistics
    this.access_searches = this.role.access_searches
    this.access_imei = this.role.access_imei
    this.access_invitations = this.role.access_invitations
    this.access_download = this.role.access_download
    this.access_parameters = this.role.access_parameters
    this.access_transaction_id = this.role.access_transaction_id
    this.access_delete_account = this.role.access_delete_account
    this.access_advanced_search = this.role.access_advanced_search
    this.access_card_number_hex = this.role.access_card_number_hex
    this.access_graphics = this.role.access_graphics
    this.access_oversight = this.role.access_oversight
    this.access_invalidate_contract = this.role.access_invalidate_contract
    this.access_invalidate_card = this.role.access_invalidate_card
    this.access_role = this.role.access_role
    this.access_delete_customer = this.role.access_delete_customer
    this.access_archived_customer = this.role.access_archived_customer
    this.access_push_contract = this.role.access_push_contract
    this.access_customer_card = this.role.access_customer_card
    this.access_modify_text = this.role.access_modify_text
  },
  methods: {
    isAdmin() {
      return this.role.is_admin
    },
    updateRole() {
      let updatedRole = {
        ID: this.role.ID,
        name: this.name,
        access_statistics: this.access_statistics,
        access_searches: this.access_searches,
        access_imei: this.access_imei,
        access_invitations: this.access_invitations,
        access_download: this.access_download,
        access_parameters: this.access_parameters,
        access_transaction_id: this.access_transaction_id,
        access_delete_account: this.access_delete_account,
        access_advanced_search: this.access_advanced_search,
        access_card_number_hex: this.access_card_number_hex,
        access_graphics: this.access_graphics,
        access_oversight: this.access_oversight,
        access_invalidate_contract: this.access_invalidate_contract,
        access_invalidate_card: this.access_invalidate_card,
        access_role: this.access_role,
        access_delete_customer: this.access_delete_customer,
        access_archived_customer: this.access_archived_customer,
        access_push_contract: this.access_push_contract,
        access_customer_card: this.access_customer_card,
        access_modify_text: this.access_modify_text,
      }

      putJson("/role/" + updatedRole.ID, updatedRole).then((resp) => {
        if (resp && resp.status && resp.status === 200) {
          this.showGranted = true
          this.$emit("role-updated", resp.data)
        } else {
          this.showError = true
        }
      }).catch(() => {}).then(() => {
        this.loading = false
        setTimeout(() => {this.showGranted = false}, 1500)
        setTimeout(() => {this.showError = false}, 1500)
      })

      this.dialog = false
    }
  },
};
</script>
<style scoped>
.custom-transform-class{
  text-transform: uppercase
}
</style>