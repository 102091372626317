<template>
  <v-dialog v-model="dialog" max-width="60%">
    <template v-slot:activator="{ on, attrs }">
      <v-btn depressed v-bind="attrs" v-on="on"
             outlined
             color="digilogoprimary" class="text-uppercase">
        {{ texts.details }}
      </v-btn>
    </template>
    <v-card height="40vh" class="overflow-y-hidden" style="display: flex !important;flex-direction: column;">
      <v-card-title>{{ formatCommand(command, action) }}</v-card-title>
      <v-divider></v-divider>
      <v-card-text>
        <div class="font-weight-bold mt-4" color="digiactiondetailstext">
          {{ action.CreatedAt? DateUtcToStandard(action.CreatedAt): "-" }} -
          {{ action.CreatedAt? HourUtcToStandard(action.CreatedAt): "-" }},
          {{ GetActorName(action) }}
          <span class="font-weight-regular grey--text text--darken-2">({{ GetRoleName(action) }})</span>:
        </div>
      </v-card-text>
      <v-card-text style="flex-grow: 1; overflow: auto;">
        <v-list>
          <v-list-item v-for="(line, i) in FormatGround(action.ground)" :key="i">{{ line }}</v-list-item>
        </v-list>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import {utcHourToStandard, utcToStandard} from "@/utils";
import {formatCommand} from "@/utils";

export default {
  name: "ActionGround",
  props: ['action', 'texts', 'command'],
  data() {
    return {
      dialog: false,
      ground: null,
    };
  },
  methods: {
    GetActorName(action) {
      if (action != null && action.actor != null && action.actor.firstname != null) {
        return action.actor.firstname + ' ' + action.actor.lastname.toUpperCase()
      } else if (action != null && action.actor != null) {
        return action.actor.lastname.toUpperCase()
      } else {
        return '-'
      }
    },
    GetRoleName(action) {
      if (action != null && action.actor != null && action.actor.role != null) {
        return action.actor.role.name
      } else {
        return '-'
      }
    },
    FormatGround(ground) {
      return ground?.split("{ln}")
    },
    DateUtcToStandard(utc) {
      return utcToStandard(utc);
    },
    HourUtcToStandard(utc) {
      return utcHourToStandard(utc);
    },
    formatCommand(command, action) {
      return formatCommand(command, action)
    }
  },
};
</script>