<template>
  <v-hover v-slot:default="{ hover }">
    <v-sheet rounded outlined
             width="100%" height="100%" color="blue" class="pa-0 ma-0">
        <v-card  width="100%" height="100%" class="overflow-y-auto overflow-x-hidden pa-1 mx-0 transition-swing" style="z-index: 5!important;"
                :ripple="{ class: 'blue--text' }" elevation="0"
                @click="updatePanel()">

          <v-icon large style="z-index: 10!important; position: inherit!important;"
                  v-on:click="searchModel()" class="float-right" :color='!isAppleManufacturer() ? "green darken-1" : ""'>{{getManufacturerIcon()}}</v-icon>

          <v-card-text class="py-0 px-0 text-subtitle-1 font-weight-medium blue--text" style="direction: ltr!important;">
            <div style="z-index: 10 !important; position: relative !important; width: 75%;">
              <div v-if="getDeci()">
                <format-clipboard  :text="formatCalypso(wallet.calypsoNumber)" :hide_icon="true" match="40"></format-clipboard>
              </div>
              <div v-else>
                <div style="line-height: 22px"><format-clipboard :text="wallet.calypsoNumber" :hide_icon="true" match="12"></format-clipboard></div>
                <div class="font-weight-regular text-caption" style="line-height: 14px"><format-clipboard :parenthesis="true" :text="formatCalypso(wallet.calypsoNumber)" :hide_icon="true" match="40"></format-clipboard></div>
              </div>
              <div class="font-weight-regular text-caption grey--text text--darken-2" style="line-height: 14px">{{texts.created}}: {{ convertUtc(wallet.creationDate )}}</div>
            </div>
          </v-card-text>

          <v-card-text v-if="checkCustomerMobile()"
                       class="py-0 pt-2 blue--text text-center">
            {{wallet.customerMobile.Manufacturer}} {{wallet.customerMobile.Model}}
          </v-card-text>
          <v-card-text v-else>
            {{ wallet.customerMobile }}
          </v-card-text>

          <v-card-text class="py-0 blue--text text-center">{{ getStatus() }}</v-card-text>

          <v-card-text v-if="invalidatable && access_invalidate_card"
                       class="text-center" style="z-index: 10!important; position: inherit!important;">
            <calypso-app v-if="displayCalypsoInvalidate(wallet.calypsoAppStatus) && isLocalToService"
                         :customer="customer" :texts="texts" :calypso_app="wallet"></calypso-app>
          </v-card-text>
          <!-- V2
          <v-card-text class="py-0 blue--text text-center">{{wallet.purchases}} {{ texts.purchase }}{{ wallet.purchases>1 ? "s" : "" }}</v-card-text>
          <v-card-text class="py-0 blue--text text-center">{{wallet.date}}</v-card-text>
          -->

          <v-overlay dark absolute
                     :z-index="0" :value="hover || selected" color="blue" opacity="0.15"></v-overlay>
        </v-card>
    </v-sheet>
  </v-hover>
</template>

<script>
import {mapGetters} from "vuex";
import { utcToStandard } from "@/utils";
import CalypsoApp from "@/components/customer/Invalidations/CalypsoApp";
import FormatClipboard from "@/components/FormatClipboard";

export default {
  name: "WalletCard",
  props: ["wallet", "texts", "status", "selected", "invalidatable", "customer"],
  components:{
    FormatClipboard,
    CalypsoApp
  },
  data() {
    return {}
  },
  computed: {
    access_invalidate_card() {
      return this.Role && this.Role.access_invalidate_card
    },
    isLocalToService() {
        return this.$route.params.service_id === ""+this.Config.ServiceId
    },
    ...mapGetters({ User: "StateUser", Role: "StateRole", Config: "StateConfig" }),
  },
  methods: {
    displayCalypsoInvalidate(calypsoAppStatus){
      return !(calypsoAppStatus === 70 || calypsoAppStatus === 71 || calypsoAppStatus === 80 || calypsoAppStatus === 81)
    },
    getStatus() {
      let response = ""
      this.status?.forEach((el) => {
        if (el.calypso_status === this.wallet.calypsoAppStatus) {
          response = el.status
        }
      })
      return response
    },
    checkCustomerMobile(){
      return this.wallet.customerMobile && this.wallet.customerMobile.Manufacturer && this.wallet.customerMobile.Model
    },
    updatePanel() {
      this.$emit("clicked", this.wallet.calypsoNumber)
      document.activeElement.blur();
    },
    convertUtc(d) {
      return utcToStandard(d)
    },
    formatCalypso(calypso) {
      return parseInt(calypso, 16).toLocaleString("fr-FR").split(",").join("")
    },
    getDeci() {
      if (this.User) {
        return this.User.hexa === "deci"
      } else {
        return true
      }
    },
    isAppleManufacturer() {
        return this.wallet?.customerMobile?.Os === "ios"
    },
    isAndroidManufacturer() {
      return this.wallet?.customerMobile?.Os === "android"
    },
    getManufacturerIcon() {
      if (this.isAppleManufacturer()) {
        return "mdi-apple"
      } else if (this.isAndroidManufacturer()) {
        return "mdi-android"
      } else {
        return ""
      }
    },
    searchModel() {
      if (this.Config.modelSearchButtonSwitch) {
        window.open("https://google.com/search?q="
            + this.wallet.customerMobile.Manufacturer + "+"
            + this.wallet.customerMobile.Model.replace(" ", "+"), '_blank')
      }
    }
  }
}
</script>