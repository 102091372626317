<template>
	<div>
		<div class="text-center text-h4 mt-6">
			{{ texts.sales_from }} {{ dateToDisplay }} {{ texts.to_lowercase }} {{ dateToDisplay2 }} ({{ texts.grouped_by_interval_of }} {{ currentInterval }} {{ texts.minutes_lowercase }})
		</div>
		<div class="graph_settings" style="margin: 10px auto auto; padding: 10px;" color="digibackground">
			<div>
				<div class="d-inline-block" style="width: 50%">
					<v-menu
						v-model="menu"
            offset-y
            transition="scale-transition" min-width="auto"
						:close-on-content-click="false"
						:nudge-right="40">
						<template v-slot:activator="{ on, attrs }">
							<v-text-field
                  v-bind="attrs" v-on="on"
                  v-model="date"
                  readonly clearable
                  prepend-icon="mdi-calendar"
                  :label="texts.start_date"/>
						</template>
						<v-date-picker
							v-model="date"
							@input="menu = false"/>
					</v-menu>
				</div>
				<div class="d-inline-block" style="width: 50%; padding: 6px">
					<v-menu
						v-model="menu2"
            offset-y
            transition="scale-transition" min-width="auto"
						:close-on-content-click="false"
						:nudge-right="40">
						<template v-slot:activator="{ on, attrs }">
							<v-text-field
                  v-bind="attrs" v-on="on"
                  v-model="date2"
                  readonly clearable
                  prepend-icon="mdi-calendar"
                  :label="texts.end_date"/>
						</template>
						<v-date-picker
							v-model="date2"
							@input="menu2 = false"/>
					</v-menu>
				</div>
			</div>
			<div style="width: 125px; margin: auto">
				<v-text-field v-model="interval" type="number" :suffix="texts.minutes_lowercase" placeholder="20"/>
			</div>
			<div class="text-center">
				<v-btn color="primary"
               :disabled="!isRefreshAvailable"
               @click="getCrossedWeekSalesChartData">{{ texts.refresh }}</v-btn>
			</div>
		</div>
		<CrossedWeekSalesChart :texts="texts" :locale="fixedLocale" :style="'background-color: '+getTheme('digibackground') + '; color: ' + getTheme('digigraphtext')"></CrossedWeekSalesChart>
		<div class="text-center">
			<v-dialog
				v-model="dialog"
				width="500">
				<v-card>
					<v-card-text class="text-h5 text-center pa-3">
						{{ texts.day_limit }}
					</v-card-text>

					<v-divider/>

					<v-card-actions>
						<v-spacer/>
						<v-btn
							color="primary"
							text
							@click="dialog = false">OK</v-btn>
					</v-card-actions>
				</v-card>
			</v-dialog>
		</div>
	</div>
</template>

<script>
import {postJson} from "@/store/post";
import CrossedWeekSalesChart from "@/components/statistics/Graphics/CrossedWeekSalesChart";
import { eventBus } from '@/eventBus'
import {mapGetters} from "vuex";
import {getJson} from "@/store/get";
import {getTheme} from "@/utils";
export default {
	name: 'Graphics',
	components: {CrossedWeekSalesChart},
	data() {
		return {
			texts: {},
			fixedLocale: null,
			rawDate: null,
			dateToDisplay: null,
			date: null,
			rawDate2: null,
			dateToDisplay2: null,
			date2: null,
			menu: false,
			menu2: false,
			currentInterval: "20",
			interval: "20",
			chartData: [],
			oneUTCDay: 86400000,
			dialog: false
		}
	},
	computed: {
		...mapGetters({ User: "StateUser",
			Locale: "StateLocale"}),
		isRefreshAvailable() {
			return ((this.date !== null && this.date.length === 10) && (this.date2 !== null && this.date2.length === 10) && (this.interval.length >= 1))
		}
	},
	created() {
		this.fixedLocale = this.Locale.replace('_', '-')
		this.date = (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10)
		this.date2 = (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10)
		this.rawDate = new Date(this.date)
		this.rawDate2 = new Date(this.date2)
		this.getTexts()
		this.getCrossedWeekSalesChartData()
	},
	methods: {
    getTheme(color) {
      return getTheme(this.$vuetify, color)
    },
		getTexts() {
			if (this.User) {
				getJson("/text/graphic/" + this.User.language.id).then((resp) => {
					if (resp?.status === 200) {
						this.texts = resp.data.GraphicView[0]
						this.texts.to_lowercase = this.texts.to.toLowerCase()
						this.texts.minutes_lowercase = this.texts.minutes.toLowerCase()
					}
				})
			}
		},
		getCrossedWeekSalesChartData() {
			let dataToSend = { "graphic-interval": parseInt(this.interval) }

			if (this.date === null)
				dataToSend['specific-date'] = ""
			else
				dataToSend['specific-date'] = this.date.toString()

			if (this.date2 === null)
				dataToSend['second-specific-date'] = ""
			else
				dataToSend['second-specific-date'] = this.date2.toString()

			postJson("/statistics/graphic", dataToSend).then((resp) => {
				if (resp.status === 200) {
					this.chartData = []
					if (resp.data.graphic !== undefined && resp.data.graphic !== null) {
						let valueIndex = 0
						for (const [key, value] of Object.entries(resp.data.graphic)) {
							let tmpArray = {name: key, data: []}
							let valueIndexAsString = 'value' + valueIndex
							value.forEach(function (item) {
								let tmpDataItem = {}
								tmpDataItem['date'] = new Date(item.datetime)
								tmpDataItem[valueIndexAsString] = item.count
								tmpArray.data.push(tmpDataItem)
							})
							this.chartData.push(tmpArray)
							valueIndex++
						}
					}
					this.currentInterval = this.interval
					this.rawDate = new Date(this.date)
					this.rawDate2 = new Date(this.date2)
					this.updateDateToDisplay()
					this.emitGraphicUpdateChart(this.chartData, this.currentInterval)
				}
			})
		},
		emitGraphicUpdateChart(data) {
			eventBus.$emit('graphicCreateChart', data, this.currentInterval)
		},
		updateDateToDisplay() {
			if (this.fixedLocale !== null) {
				this.dateToDisplay = this.rawDate.toLocaleDateString(this.fixedLocale, {weekday: 'long', day: "2-digit", month:'long', year:'numeric'})
				this.dateToDisplay2 = this.rawDate2.toLocaleDateString(this.fixedLocale, {weekday: 'long', day: "2-digit", month:'long', year:'numeric'})
			}
		},
		areDatesOk() {
			return (this.date !== null && this.date.length === 10) && (this.date2 !== null && this.date2.length === 10)
		}
	},
	watch: {
		"User.language": function() {
			this.fixedLocale = this.Locale.replace('_', '-')
			this.getTexts()
			this.updateDateToDisplay()
		},
		date() {
			if (this.areDatesOk()) {
				let numberOfDays = Math.round((new Date(this.date).getTime() - new Date(this.date2).getTime()) / this.oneUTCDay)
				if (numberOfDays < -7 || numberOfDays > 7) {
					this.date2 = null
				}
			}
			if (this.areDatesOk()) {
				if ((new Date(this.date) - new Date(this.date2)) > 0) {
					let tmp = this.date
					this.date = this.date2
					this.date2 = tmp
				}
			}
		},
		date2() {
			if (this.areDatesOk()) {
				let numberOfDays = Math.round((new Date(this.date).getTime() - new Date(this.date2).getTime()) / this.oneUTCDay)
				if (numberOfDays < -7 || numberOfDays > 7) {
					this.date2 = null
					this.dialog = true
				}
			}
			if (this.areDatesOk()) {
				if ((new Date(this.date) - new Date(this.date2)) > 0) {
					let tmp = this.date
					this.date = this.date2
					this.date2 = tmp
				}
			}
		},
		texts() {
			setTimeout(function() {
				this.emitGraphicUpdateChart(this.chartData, this.currentInterval)
			}.bind(this), 500)
		},
	}
}
</script>

<style scoped>
	@media only screen and (min-width: 100px) {
		.graph_settings {
			width: 400px;
		}
	}
	@media only screen and (min-width: 1000px) {
		.graph_settings {
			width: 550px;
		}
	}
</style>
