<template>
  <div>
  <v-container v-if="User"
               fluid
               style="width: 80%">
    <v-row class="mt-6">
      <v-col class="text-left">
        <div>
          <p class="text-h4">{{ texts.title }}</p>
        </div>
      </v-col>
      <v-col class="text-right">
        <create-user v-on:user-created="createUser($event)" :languages="languages" :texts="texts" :roles="roles" :companies="companies"/>
      </v-col>

    </v-row>
    <v-row>
      <v-col align="center">
        <v-data-table :headers="headers" fixed-header :items="items" :items-per-page="12" :custom-sort="customSort">
          <template v-slot:item.lastname="{ item }">
            {{ item.firstname }} {{ item.lastname.toUpperCase() }}
          </template>
          <template v-slot:item.phone="{ item }">
            <format-clipboard :text="item.phone" match="2"></format-clipboard>
          </template>
          <template v-slot:item.last_connection="{ item }">
            {{ UtcToStandard(item.last_connection)}}
          </template>
          <template v-slot:item.invitation_sent_at="{ item }">
            {{ UtcToStandard(item.invitation_sent_at)}}
          </template>
          <template v-slot:item.invite="{ item }">
            <v-btn color="primary" class="text-none custom-transform-class"
                   :disabled="isAdmin(item) || !Role.access_parameters" :dark="!isAdmin(item) && Role.access_parameters" :text="!isAdmin(item) && Role.access_parameters"
                   @click="inviteUser(item)">
              <v-icon left>mdi-email-send-outline</v-icon>
              {{ texts.invite_user }}</v-btn>
          </template>
          <template v-slot:item.update="{ item }">
            <update-user v-on:user-updated="updateUsers($event)" :texts="texts" :user="item" :languages="languages" :roles="roles" :companies="companies"/>
          </template>
          <template v-slot:item.delete="{ item }">
            <delete-user v-on:user-deleted="deleteUser($event)" :user="item" :texts="texts"/>
          </template>
        </v-data-table>
      </v-col>
    </v-row>

    <granted-dialog :show_granted="showGranted" :text="texts.granted" :color="grantedColor"/>
    <granted-dialog :show_granted="showError" :text="texts.error" :color="errorColor"/>
  </v-container>
  </div>
</template>

<script>
import GrantedDialog from "@/components/GrantedDialog";
import { mapGetters } from "vuex";
import { getJson } from "@/store/get";
import { utcToStandard } from "@/utils"
import UpdateUser from "@/components/parameters/User/UpdateUser";
import CreateUser from "@/components/parameters/User/CreateUser";
import DeleteUser from "@/components/parameters/User/DeleteUser";
import FormatClipboard from "@/components/FormatClipboard";

export default {
  name: "Users",
  components: {
    GrantedDialog,
    UpdateUser,
    CreateUser,
    DeleteUser,
    FormatClipboard
  },
  data() {
    return {
      grantedColor: "green accent-4",
      errorColor: "red accent-4",
      showGranted: false,
      showError: false,
      loading: false,
      loadingColor: "blue",
      languages: null,
      roles: null,
      companies: null,

      items: [],

      texts: {
        title: "All users",
        button_new: "New user",
        user: "User",
        email: "Email",
        company: "Company",
        phone: "Phone",
        role: "Role",
        last_connection: "Last connection",
        granted: "Ok",
        error: "Not ok",
        modify_title: "Modify a user",
        create_title: "Create a user",
        create_user: "Create user",
        invite_user: "Invite user",
        create_invite_user: "Create then invite user",
      },
    };
  },
  computed: {
    ...mapGetters({ User: "StateUser", Role: "StateRole" }),
    headers() {
      return [
        { text: this.texts.user, align: 'start', sortable: true, value: 'lastname' },
        { text: this.texts.email, align: 'start', sortable: true, value: 'email' },
        { text: this.texts.company, align: 'start', sortable: true, value: 'company.name' },
        { text: this.texts.phone, align: 'start', sortable: false, value: 'phone' },
        { text: this.texts.role, align: 'start', sortable: true, value: 'role.name' },
        { text: this.texts.last_connection, align: 'center', sortable: true, value: 'last_connection' },
        { text: this.texts.invitation_sent, align: 'center', sortable: true, value: 'invitation_sent_at' },
        { text: '', align: 'start', sortable: false, value: 'invite' },
        { text: '', align: 'start', sortable: false, value: 'update' },
        { text: '', align: 'start', sortable: false, value: 'delete' },
      ]
    }
  },
  created() {
    this.getLanguages()
    this.getTexts()
    this.getUsers()
    this.getRoles()
    this.getCompanies()
  },
  methods: {
    createUser(user) {
      this.items.push(user)
    },
    UtcToStandard(utc) {
        return utcToStandard(utc)
    },
    isAdmin(user) {
      return user.role.is_admin
    },
    inviteUser(user) {
      getJson("/user/invite/" + user.ID).then((resp) => {
        if (resp && resp.status === 200) {
          this.showGranted = true
        } else {
          this.showError = true
        }
      }).catch(() => {}).then(() => {
        this.loading = false
        setTimeout(() => {this.showGranted = false}, 1500)
        setTimeout(() => {this.showError = false}, 1500)
      })
    },
    deleteUser(id) {
      this.items = this.items.filter(function(el) { return el.ID !== id })
    },
    updateUsers(user) {
      this.items.forEach(el => {
        if (user.ID === el.ID) {
          el.user = user.user
          el.email = user.email
          el.company = user.company
          el.phone = user.phone
          el.role = user.role
          el.role_id = user.role_id
          el.last_connection = user.last_connection
          el.update = user.update
          el.delete = user.delete
          el.firstname = user.firstname
          el.lastname = user.lastname
        }
      })
      this.fixUsers()
    },
    getRole(role) {
      let i = 0
      let response = ""
      if (this.roles == null) {
        return
      }
      for (i; i< this.roles.length; i++) {
        if (this.roles[i].ID === role) {
          response += this.roles[i].name
        }
      }
      return response
    },
    getRoles() {
      getJson("/roles").then((resp) => {
        if (resp.status === 200) {
          this.roles = resp.data
        }
      })
    },
    getCompanies() {
      getJson("/companies").then((resp) => {
        if (resp.status === 200) {
          this.companies = resp.data.companiesList
        }
      })
    },
    getLanguages() {
      getJson("/text").then((resp) => {
        if (resp.status === 200) {
          this.languages = []
          resp.data.Languages.forEach(el => {
            this.languages.push({
              id: el.id,
              text: el.language,
              lang: el.lang
            })
          })
        }
      })
    },
    getTexts() {
      getJson("/text/users/"+this.User.language.id).then((resp) =>{
        if (resp && resp.status === 200) {
          this.texts = resp.data.UsersView[0]
        }
      })
    },
    getUsers() {
      getJson("/users").then((resp) =>{
        if (resp && resp.status === 200) {
          this.items = []
          this.fixUsers(resp.data)
        }
      })
    },
    fixUsers(arr) {
      arr.forEach(el => {
        if (!el.user) {
          el.user = '-'
        }
        if (!el.email) {
          el.email = '-'
        }
        if (!el.company) {
          el.company = '-'
        }
        if (!el.phone) {
          el.phone = '-'
        }
        if (!el.role) {
          el.role = '-'
        }
        if (!el.last_connection) {
          el.last_connection = '-'
        }
        if (!el.update) {
          el.update = '-'
        }
        if (!el.delete) {
          el.delete = '-'
        }
        if (el.email !== 'admin@digimobee.com') {
          this.items.push(el)
        }
      })
    },
    customSort(items, index, isDesc){
      if (items.length === 0 || index.length === 0) {
        return items
      }
      let tmpItems = JSON.parse(JSON.stringify(this.items))
      let tmpIndex = index[0]
      if (!isDesc[0]) {
        tmpItems = tmpItems.sort((a,b) => tmpIndex.split('.').reduce((p,c)=>p&&p[c]||null, a).toLowerCase() > tmpIndex.split('.').reduce((p,c)=>p&&p[c]||null, b).toLowerCase())
      } else {
        tmpItems = tmpItems.sort((a,b) => tmpIndex.split('.').reduce((p,c)=>p&&p[c]||null, b).toLowerCase() > tmpIndex.split('.').reduce((p,c)=>p&&p[c]||null, a).toLowerCase())
      }
      return tmpItems
    }
  },
  watch: {
    "User.language": function () {
      this.getTexts()
    },
  },
};
</script>
<style scoped>
.custom-transform-class{
  text-transform: uppercase
}
</style>