import {putJson} from "@/store/put";
import moment from "moment-timezone";


export const tec = require("@/assets/tec.png");
export const bmc = require("@/assets/bmc.png");
export const stib = require('@/assets/stib.png');
export const sncb = require("@/assets/sncb.png");
export const delijn = require("@/assets/delijn.png");
export const tisseo = require("@/assets/tisseo.png");
export const rla = require("@/assets/rla.png");
export const nam = require("@/assets/nam.png");
export const buss = require("@/assets/networks/buss.png");
export const cars_regionaux = require("@/assets/networks/cars_regionaux.png");
export const couralin = require("@/assets/networks/couralin.png");
export const evalys = require("@/assets/networks/evalys.png");
export const idelis = require("@/assets/networks/idelis.png");
export const libeo = require("@/assets/networks/libeo.png");
export const mobius = require("@/assets/networks/mobius.png");
export const peribus = require("@/assets/networks/peribus.png");
export const rbus = require("@/assets/networks/rbus.png");
export const transcom = require("@/assets/networks/transcom.png");
export const trema = require("@/assets/networks/trema.png");
export const tut = require("@/assets/networks/tut.png");
export const vitalis = require("@/assets/networks/vitalis.png");
export const yelo = require("@/assets/networks/yelo.png");
export const floya  = require("@/assets/floya.png");

export const password_regex = /(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[\W_-])(?=.{14,})/;

export const networks = {
    'tec': tec,
    'bmc': bmc,
    'stib': stib,
    'sncb': sncb,
    'delijn': delijn,
    'tisseo': tisseo,
    'rla': rla,
    'nam': nam,
    'buss': buss,
    'cars_regionaux': cars_regionaux,
    'couralin': couralin,
    'evalys': evalys,
    'idelis': idelis,
    'libeo': libeo,
    'mobius': mobius,
    'peribus': peribus,
    'rbus': rbus,
    'transcom': transcom,
    'trema': trema,
    'tut': tut,
    'vitalis': vitalis,
    'yelo': yelo,
    'floya': floya,
}

export function iso8601ToStandard(iso) {
    if (!iso) {return ""}
    let tmp = iso.split("-")
    return tmp[2]+"/"+tmp[1]+"/"+tmp[0]
}

export function utcToStandard(utc) {
    if (!utc || utc.length < 4 || utc === "0001-01-01T00:00:00Z" || utc === "1997-01-01T00:00:00Z") {return "-"}
    let networkTimeZone = 'Europe/Paris'

    return moment(utc).tz(networkTimeZone).format("DD/MM/YYYY")
}

export function utcHourToStandard(utc) {
    if (!utc || utc.length < 4 || utc === "0001-01-01T00:00:00Z" || utc === "1997-01-01T00:00:00Z") {return "-"}
    let networkTimeZone = 'Europe/Paris'
    return moment(utc).tz(networkTimeZone).format("HH:mm")
}

export function getTheme(t, color) {
    if (t.theme.isDark) {
        return t.theme.themes.dark[color]
    } else {
        return t.theme.themes.light[color]
    }
}

export function canModifyText(role, user) {
    return role?.access_modify_text && user?.modifying_text
}

export function updateText(t, component, field, locale) {
    putJson("/text", {components: component, field: field, locale: locale, value: t[field]}).then((resp) => {
        if (resp?.status === 200) {
            t.texts[field] = t[field]
        }
    })
}

export function formatCommand(command, action=null) {
    let resp = JSON.parse(JSON.stringify(command)).toString()
    resp = resp.split("{purchase-id}")
    if (action !== null && action !== undefined && action.purchase_history_id !== null) {
        resp = resp.join(" "+action.purchase_history_id+" ")
    } else {
        resp = resp.join("")
    }
    return resp
}
