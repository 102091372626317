<template>
  <v-container class="mt-6" style="min-width:85%">
    <v-row>
      <v-col>
        <v-row  >
          <v-col>
            <p class="ma-0 text-h2">
              {{ texts.week_statistics}}
            </p>
            <p class="ma-0 mt-3 text-h2" style="color: #b8b8b8">
              {{ texts.week }} : {{ week }} - {{ date }}
            </p>
          </v-col>
          <v-col align-self="end" style="display: inline">
            <div v-if="useWeekSelector" class="d-inline mr-1">
              <input v-model="weekSelector" type="week" name="weekS" id="week" min="2018-W18">
            </div>
            <div v-else style="display: inline">
              <p class="d-inline mr-1">{{ texts.week }} : </p>
              <input v-model="alternativeWeekSelector" class="white-bg width-30 mr-2 light-border" type="number" maxlength="2">
              <p class="d-inline mr-1" >{{ texts.year }} : </p>
              <input v-model="alternativeYearSelector" class="white-bg width-50 mr-1 light-border" type="number" minlength="4" maxlength="4">
            </div>
            <v-btn outlined
                   style="margin-left: 1px" color="green"
                   :disabled="!searchAvailable"
                   @click="getOffset">{{ texts.search }}</v-btn>
          </v-col>
        </v-row>
        <v-row>
          <v-col class="mt-6">
            <v-btn style="border-top-right-radius: 0; border-bottom-right-radius: 0" color="primary" :outlined="!purchaseButtonActive" @click="getPurchaseData">{{ texts.purchases }}</v-btn>
            <v-btn style="border-left: 0; border-bottom-left-radius: 0; border-top-left-radius: 0" color="primary" :outlined="!validationButtonActive" @click="getValidationData">{{ texts.validations }}</v-btn>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <PurchasesDataTable v-if="purchaseButtonActive && !Config.displayPurchasedAggregatedWeekStats"
                                :headers="headers" :data="data" :contractTariffProviderLogos="contractTariffProviderLogos"/>
            <PurchasesAggregatedDataTable v-else-if="purchaseButtonActive && Config.displayPurchasedAggregatedWeekStats"
                                          :headers="headers" :rawdata="data" :contractTariffProviderLogos="contractTariffProviderLogos"/>
            <ValidationsDataTable v-else-if="validationButtonActive && !Config.displayValidationAggregatedWeekStats && !Config.displayValidationWeekStatsGroupByEventNetworkId"
                                  :headers="headers" :data="data" :contractTariffProviderLogos="contractTariffProviderLogos"/>
            <ValidationsAggregatedDataTable v-else-if="validationButtonActive && Config.displayValidationAggregatedWeekStats && !Config.displayValidationWeekStatsGroupByEventNetworkId"
                                            :headers="headers" :rawdata="data" :contractTariffProviderLogos="contractTariffProviderLogos"/>
            <v-card elevation="0" v-else-if="validationButtonActive && Config.displayValidationWeekStatsGroupByEventNetworkId">
              <v-tabs color="blue" left v-model="t">
                <v-tab v-for="(tab, i) in tabs" :key="i"
                       class=" text-none" :ref="'tabulationId_' + i">{{tab.tab}}</v-tab>
              </v-tabs>
              <v-tabs-items v-model="t">
                <v-tab-item>
                  <ValidationsAggregatedDataTable :headers="headers"
                    :rawdata="data"
                    :contractTariffProviderLogos="contractTariffProviderLogos"
                  />
                </v-tab-item>
                <v-tab-item>
                  <ValidationsAggregatedDataTable :headers="headers"
                    :rawdata="data"
                    :contractTariffProviderLogos="contractTariffProviderLogos"
                    override-aggregation="event_network_id"
                  />
                </v-tab-item>
              </v-tabs-items>
            </v-card>
            <v-overlay dark absolute
                       opacity="0.15" :z-index="0" :value="waiting">
              <v-progress-circular
                  indeterminate
                  color="blue lighten-1"
                  :size="70" :width="5"
              ></v-progress-circular>
            </v-overlay>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-row justify="space-between">
      <v-col cols="2">
        <v-btn outlined class="custom-transform-class text-none" @click="decreaseOffset">
          {{ texts.previous_week }}
        </v-btn>
      </v-col>
      <v-col cols="2" class="text-right">
        <v-btn outlined class="text-none" @click="increaseOffset">
          {{ texts.next_week }}
        </v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>

<style scoped>
	/* Chrome, Safari, Edge, Opera */
	input::-webkit-outer-spin-button,
	input::-webkit-inner-spin-button {
		-webkit-appearance: none;
		margin: 0;
	}

	/* Firefox */
	input[type=number] {
		-moz-appearance: textfield;
	}
	.white-bg {
		background-color: white;
	}
	.width-30 {
		width: 30px;
	}
	.width-50 {
		width: 50px;
	}
	.light-border {
		border: solid 1px grey;
		border-radius: 4px;
		padding-left: 4px;
	}
</style>

<script>
import {postJson} from "@/store/post";
import {getJson} from "@/store/get";
import {mapGetters} from "vuex";
import * as utils from "@/utils";
import PurchasesDataTable from "@/components/statistics/PurchasesDataTable";
import PurchasesAggregatedDataTable from "@/components/statistics/PurchasesAggregatedDataTable";
import ValidationsDataTable from "@/components/statistics/ValidationsDataTable";
import ValidationsAggregatedDataTable from "@/components/statistics/ValidationsAggregatedDataTable";

export default {
  name: 'Week',
  components: {ValidationsDataTable, PurchasesDataTable, PurchasesAggregatedDataTable, ValidationsAggregatedDataTable},
  data () {
    return {
      t: null,
      contractTariffProviderLogos: [],
      texts: {},
      weekSelector: null,
      alternativeYearSelector: null,
      alternativeWeekSelector: null,
      week: 0,
      date: null,
      weekOffset: 0,
      numberFormatCurrencyOptions: { style: 'currency', currency: 'EUR' },
      numberFormatPercentageOptions: { style: 'percent', minimumFractionDigits: 1, maximumFractionDigits: 2 },
      purchaseButtonActive: false,
      validationButtonActive: false,
      headers: [],
      data: [],
      waiting: false,
    }
  },
  created() {
    this.getBrowser()
    if (this.Offset !== 0) {
      this.weekOffset = this.Offset
      this.$store.commit('setOffset', 0)
    }
    this.getTexts()
    this.getPurchaseData()
    this.getContractTariffProviderLogos()
  },
  computed: {
    ...mapGetters({ User: "StateUser", Config: "StateConfig",
      Locale: "StateLocale",
      Offset: "StateOffset"}),
    useWeekSelector() {
      let browser = this.getBrowser()
      return (browser !== "Firefox" && browser !== "Safari")
    },
    searchAvailable() {
      return this.weekSelector !== null || this.areAlternativeWidgetsValid
    },
    areAlternativeWidgetsValid() {
      return (this.alternativeWeekSelector !== null && this.alternativeWeekSelector.length === 2 && this.alternativeYearSelector !== null && this.alternativeYearSelector.length === 4)
    }
    },
  methods: {
    getTexts() {
      if (this.User) {
        getJson("/text/specific_week_statistics/" + this.User.language.id).then((resp) => {
          if (resp?.status === 200) {
            this.texts = resp.data.SpecificWeekStatisticsView[0]
            this.tabs = [
              { tab: this.texts.tab_company },
              { tab: this.texts.tab_event_network_id }
            ]
          }
        })
      }
    },
    getContractTariffProviderLogos() {
      if (!this.Config.displayContractTariffProviderLogo) {
        return
      }
      getJson("/contract-tariff-provider-logos").then((resp) => {
        if (resp?.status === 200) {
          this.contractTariffProviderLogos = resp.data.ContractTariffProviderLogoArray
          this.contractTariffProviderLogos.forEach((el) => {
            el.Image = utils.networks[el.Logo]
          })
        }
      })
    },
    getOffset() {
      if (this.searchAvailable)
        if (this.areAlternativeWidgetsValid) {
          this.weekSelector = this.alternativeYearSelector + "-W" +this.alternativeWeekSelector
        }
      postJson("/statistics/week-offset", {"year-week": this.weekSelector}).then((resp) => {
        if (resp?.status === 200)
          this.weekOffset = resp.data.statistics.date_information.offset
      })
    },
    getPurchaseData() {
      this.waiting = true
      postJson("/statistics/specific-week-purchases", { "language": this.Locale.substring(0,2), "offset": this.weekOffset }).then((resp) => {
        if (resp?.status === 200) {
          this.purchaseButtonActive = true
          this.validationButtonActive = false
          const newLocale = this.Locale.replace('_', '-')

          this.week = resp.data.statistics.date_information.week
          let date = resp.data.statistics.date_information.year.toString() + '-' + resp.data.statistics.date_information.month.toString() + '-01'
          this.date = new Date(date).toLocaleDateString(newLocale, {month:'long', year:'numeric'})

          this.data = [{ name: this.texts.installations, type: "installations" }]
          let sumUpPart = { name: this.texts.total, type: 'total' }
          let totalSalesByDay = { name: this.texts.purchases_by_day, type: 'sumup_sales'}
          this.headers = [{ text: '', value: 'name' }]

          if (this.Config.displayContractTariffProviderLogo && this.Config.displayPurchasedAggregatedWeekStats !== undefined) {
            this.headers.push({text: '', value: 'logo'})
          }
          if (this.Config.displayArticleCodeStats) {
            this.headers.push({text: this.texts.article_code, value: 'article_code'})
          }

          resp.data.statistics.specific_week_statistics.day_information.forEach(function(item) {
            let headerToPush = {
              align: 'center',
              class:'font-weight-regular'
            }
            let daylong = new Intl.DateTimeFormat(this.User.language.locale.replace('_', '-'), {weekday: 'long'}).format(new Date(item.date));
            daylong = daylong[0].toUpperCase() + daylong.slice(1);
            headerToPush.text = daylong + " " + new Date(item.date).toLocaleDateString(newLocale)
            headerToPush.value = item.date
            this.headers.push(headerToPush)

            sumUpPart[item.date] = 0.0

            totalSalesByDay[item.date] = Intl.NumberFormat(newLocale).format(item.total_sales)

            this.data[0][item.date] = item.installations
          }.bind(this))

          this.headers.push(
              { text: '', value: 'total', align: 'center'},
              { text: '', value: 'total_percentage', align: 'center'},
              { text: '', value: 'money_amount', align: 'center'},
              { text: '', value: 'money_amount_percentage', align: 'center'}
          )
          this.data.push({
            name: this.texts.products_bought,
            'total': this.texts.total,
            'total_percentage': this.texts.total + ' %',
            'money_amount': this.texts.amount,
            'money_amount_percentage': this.texts.amount + ' %'
          })

          if (resp.data.statistics.specific_week_statistics.items != null) {
            let js_this = this // le this dans le sort n'est pas le this global, il est écrasé. Du coup on coutourne.
            let items_sorted = resp.data.statistics.specific_week_statistics.items.sort(function (a, b) {
              if (js_this.Config.displayPurchasedAggregatedWeekStats)
                return a[js_this.Config.displayPurchasedAggregatedWeekStats] > b[js_this.Config.displayPurchasedAggregatedWeekStats];
              else
                return a.contract_tariff_provider * 1000 + a.contract_tariff_provider > b.contract_tariff_provider * 1000 + b.display_priority;
            })

            items_sorted.forEach((item) => {
              let dataPart = { type: 'data'}
              dataPart.name = item.name
              for (const [key, value] of Object.entries(item.sales_information)) {
                dataPart[key] = Intl.NumberFormat(newLocale).format(value)
              }
              dataPart.article_code = item.article_code
              dataPart.contract_tariff_provider = item.contract_tariff_provider
              dataPart.company = item.company
              dataPart.company_id = item.company_id
              dataPart.tariff_id = item.tariff_id
              dataPart.total = Intl.NumberFormat(newLocale).format(item.total)
              dataPart.money_amount = Intl.NumberFormat(newLocale, this.numberFormatCurrencyOptions).format(item.money_amount)
              dataPart.total_percentage = Intl.NumberFormat(newLocale, this.numberFormatPercentageOptions).format(item.total_percentage)
              dataPart.money_amount_percentage = Intl.NumberFormat(newLocale, this.numberFormatPercentageOptions).format(item.money_amount_percentage)
              if (dataPart.total !== '0') {
                this.data.push(dataPart)
              }
            })
          }
          sumUpPart.total = Intl.NumberFormat(newLocale).format(resp.data.statistics.specific_week_statistics.total_sales)
          sumUpPart.money_amount = Intl.NumberFormat(newLocale, this.numberFormatCurrencyOptions).format(resp.data.statistics.specific_week_statistics.total_money_amount)
          this.data.push(totalSalesByDay)
          for (const [key, value] of Object.entries(sumUpPart)) {
            if (key.match(/^\d{4}-\d{2}-\d{2}$/) && !String(value).includes('€')) {
              resp.data.statistics.specific_week_statistics.day_information.forEach((day_information) => {
                if (day_information.date === key) {
                  sumUpPart[key] = Intl.NumberFormat(newLocale, this.numberFormatCurrencyOptions).format(day_information.money_amount)
                }
              })
            }
          }

          this.data.push(sumUpPart)
          this.data.forEach(el => {
            el.hide = true
          })
        }
      })
      this.waiting = false
    },
    getValidationData() {
      this.waiting = true
      postJson("/statistics/specific-week-validations", { "language": this.Locale, "offset": this.weekOffset }).then((resp) => {
        if (resp?.status === 200) {
          this.purchaseButtonActive = false
          this.validationButtonActive = true
          const newLocale = this.Locale.replace('_', '-')

          this.week = resp.data.statistics.date_information.week
          let date = resp.data.statistics.date_information.year.toString() + '-' + resp.data.statistics.date_information.month.toString() + '-01'
          this.date = new Date(date).toLocaleDateString(newLocale, {month: 'long', year:'numeric'})

          let sumUpPart = { name: this.texts.total, type: 'total' }
          this.headers = [{ text: '', value: 'name' }]

          if (this.Config.displayContractTariffProviderLogo && !this.Config.displayValidationAggregatedWeekStats) {
            this.headers.push({ text: '', value: 'logo'})
          }

          if (this.Config.displayTariffIdStats) {
            this.headers.push({text: this.texts.tariff_id, value: 'tariff_id', align: 'center'})
          }

          this.data = [{name: {first_line: this.texts.first_validation, second_line: this.texts.validation_with_connection }, total: this.texts.total, total_percentage: this.texts.total + ' %', type: 'separator' }]
          resp.data.mdu_specific_week_statistics.day_information.forEach(function (item) {
            let headerToPush = {align: 'center'}
            headerToPush.text = new Date(item.date).toLocaleDateString(newLocale)
            headerToPush.value = item.date
            this.headers.push(headerToPush)

            sumUpPart[item.date] = {validations: Intl.NumberFormat(newLocale).format(item.total_validation), validations_with_connection: Intl.NumberFormat(newLocale).format(item.total_validation_with_connection)}
          }.bind(this))

          this.headers.push(
              { text: '', value: 'total', align: 'center'},
              { text: '', value: 'total_percentage', align: 'center'})

          for (let i =0; i < resp.data.mdu_specific_week_statistics.products_information.length; i++) {
            for (let j = 0; j < resp.data.ProductsArray.length; j++) {
              if (resp.data.mdu_specific_week_statistics.products_information[i][this.Config.displayValidationAggregatedWeekStats] === resp.data.ProductsArray[j][this.Config.displayValidationAggregatedWeekStats]
                  && resp.data.mdu_specific_week_statistics.products_information[i].tariff_id === resp.data.ProductsArray[j].tariff_id) {
                resp.data.mdu_specific_week_statistics.products_information[i].display_priority = resp.data.ProductsArray[j].display_priority
              }
            }
          }

          let js_this = this // le this dans le sort n'est pas le this global, il est écrasé. Du coup on coutourne.
          if (resp.data.mdu_specific_week_statistics.products_information != null) {
            let products_information_sorted = resp.data.mdu_specific_week_statistics.products_information.sort(function (a, b) {
              return a[js_this.Config.displayValidationAggregatedWeekStats] * 1000 + a.display_priority > b[js_this.Config.displayValidationAggregatedWeekStats] * 1000 + b.display_priority;
            })

            products_information_sorted.forEach((item) => {
              let dataPart = { type: 'data' }
              dataPart.name = item.product_name
              dataPart.tariff_id = item.tariff_id
              for (const [key, value] of Object.entries(item.validation_information)) {
                dataPart[key] = { validations: Intl.NumberFormat(newLocale).format(value) }
              }
              for (const [key, value] of Object.entries(item.validation_with_connection_information)) {
                dataPart[key].validations_with_connection = Intl.NumberFormat(newLocale).format(value)
              }
              dataPart.total = { validations: 0, validations_with_connection: 0}
              dataPart.total_percentage = { validations: 0, validations_with_connection: 0}

              dataPart.total.validations = Intl.NumberFormat(newLocale).format(item.total_validation)
              dataPart.total_percentage.validations = Intl.NumberFormat(newLocale, this.numberFormatPercentageOptions).format(item.percentage_validation / 100.0)
              dataPart.total.validations_with_connection = Intl.NumberFormat(newLocale).format(item.total_validation_with_connection)
              dataPart.total_percentage.validations_with_connection = Intl.NumberFormat(newLocale, this.numberFormatPercentageOptions).format(item.percentage_validation_with_connection / 100.0)
              dataPart.contract_tariff_provider = item.contract_tariff_provider
              dataPart.company_id = item.company_id
              dataPart.application_id = item.application_id
              dataPart.event_network_id = item.event_network_id

              if (dataPart.total.validations !== '0' || dataPart.total.validations_with_connection !== '0') {
                this.data.push(dataPart)
              }
            })
          }
          sumUpPart.total = { validations: 0, validations_with_connection: 0 }
          sumUpPart.total.validations = Intl.NumberFormat(newLocale).format(resp.data.mdu_specific_week_statistics.total_validation)
          sumUpPart.total.validations_with_connection = Intl.NumberFormat(newLocale).format(resp.data.mdu_specific_week_statistics.total_validation_with_connection)

          this.data.push(sumUpPart)
        }
      })
      this.waiting = false
    },
    increaseOffset() {
      ++this.weekOffset
    },
    decreaseOffset() {
      --this.weekOffset
    },
    getBrowser() {
      let ua= navigator.userAgent;
      let tem;
      let M = ua.match(/(opera|chrome|safari|firefox|msie|trident(?=\/))\/?\s*(\d+)/i) || [];
      if(/trident/i.test(M[1])){
        tem=  /\brv[ :]+(\d+)/g.exec(ua) || [];
        return 'IE '+(tem[1] || '');
      }
      if(M[1]=== 'Chrome'){
        tem= ua.match(/\b(OPR|Edge)\/(\d+)/);
        if(tem!= null) return tem.slice(1).join(' ').replace('OPR', 'Opera');
      }
      M = M[2]? [M[1]]: [navigator.appName, '-?'];
      if ((tem= ua.match(/version\/(\d+)/i))!= null) {
        M.splice(1, 1, tem[1]);
      }
      return M.join(' ');
    }
  },
  watch: {
    weekOffset() {
      if (this.purchaseButtonActive)
        this.getPurchaseData()
      else if (this.validationButtonActive )
        this.getValidationData()
    },
    "User.language": function () {
      this.getTexts()
    }
  }
}
</script>
