import { render, staticRenderFns } from "./ValidationsDataTable.vue?vue&type=template&id=2a0b46d2&scoped=true"
import script from "./ValidationsDataTable.vue?vue&type=script&lang=js"
export * from "./ValidationsDataTable.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2a0b46d2",
  null
  
)

export default component.exports