<template>
<v-container style="max-width: 100vw !important; min-height: 90vh !important">
  <v-row style="height: 90vh !important">
    <v-col class="pa-2">
      <v-card elevation="0" style="height: 85vh !important">
        <v-card-title class="justify-center">Conf</v-card-title>

        <v-container fluid style="height: 80vh !important" class="pa-0">
        <v-row dense style="height: 80vh! important">
          <v-col>
            <div style="display: flex; flex-flow: column wrap; height:70vh !important;">
            <v-card class="ma-1" max-width="280" elevation="0" outlined v-for="conf in display.slice(1, -1)" :key="conf[0]">
              <v-container>
                <v-row>
                  <v-col cols="auto" class="text-left">{{ conf[0] }}</v-col>
                  <v-col class="text-right">{{ conf[1] }}</v-col>
                </v-row>
              </v-container>
            </v-card>
            </div>
            <div>
              <v-card class="ma-1" max-width="95%" elevation="0" outlined v-for="conf in display.slice(-1)" :key="conf[0]">
                <v-container>
                  <v-row>
                    <v-col cols="2" class="text-left">{{ conf[0] }}</v-col>
                    <v-col class="text-left">{{ conf[1] }}</v-col>
                  </v-row>
                </v-container>
              </v-card>
            </div>
          </v-col>

          <v-col>
            <v-text-field v-model="config.copyConfUrl" outlined label="copy config url" @keydown.enter="updateConfig"></v-text-field>
            <v-row>
              <v-col>
                <v-card outlined>
                  <v-card-text class="font-weight-regular black--text">Fetch config from copyConfUrl (except copyConfUrl)</v-card-text>
                  <v-card-actions class="justify-center">
                    <v-btn class="ma-0" block color="red darken-2" @click="fetchConfigUrl" dark>go fetch</v-btn>
                  </v-card-actions>
                </v-card>
              </v-col>
              <v-col>
                <v-card outlined>
                  <v-card-text class="font-weight-regular black--text">Fetch languages and views from copyConfUrl</v-card-text>
                  <v-card-text class="font-weight-regular black--text"><v-btn class="ma-1" color="indigo darken-4" @click="hardFetchViewsUrl" dark>Hard Copy</v-btn>: overwrite data (may lose data). Does not copy action_type (link to actions).</v-card-text>
                  <v-card-text class="font-weight-regular black--text"><v-btn disabled class="ma-1" color="blue   darken-4" @click="softFetchViewsUrl">Soft Copy</v-btn>: add differences but doesn't update already existing data</v-card-text>
                  <v-card-text class="font-weight-regular black--text"><v-btn disabled class="ma-1" color="light-blue" @click="smartFetchViewsUrl">Smart Copy</v-btn>: add differences and update existing data</v-card-text>
                </v-card>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
        </v-container>
      </v-card>
    </v-col>
  </v-row>
</v-container>
</template>

<script>
import { getJson } from "@/store/get";
import { postJson } from "@/store/post";

export default {
  name: "Conf",
  data() {
    return {
      config: { copyConfUrl: "" },
      display: [],
    }
  },
  created() {
    getJson("/config").then((resp) => {
      if (resp?.status === 200) {
        this.config = resp.data.config
        this.display = Object.entries(this.config)
      }
    })
  },
  methods: {
    updateConfig() {
      console.log(this.config)
      postJson("/config", this.config).then((resp) => {
        if (resp?.status === 200) {
          console.log(resp)
        }
      })
    },
    fetchConfigUrl() {
      this.updateConfig()
      postJson("/config-fetch", null).then((resp) => {
        console.log(resp)
      })
    },
    hardFetchViewsUrl() {
      this.fetchViewsUrl({copyMode: "hard"})
    },
    softFetchViewsUrl() {
      this.fetchViewsUrl({copyMode: "soft"})
    },
    smartFetchViewsUrl() {
      this.fetchViewsUrl({copyMode: "smart"})
    },
    fetchViewsUrl(data) {
      postJson("/views-fetch", data).then((resp) => {
        if (resp?.status === 200) {
          console.log(resp)
        }
      })
    }
  },
  watch:{
    "config.copyConfUrl" () {
      console.log(this.config.copyConfUrl)
      this.display = Object.entries(this.config)
    }
  }
}
</script>

<style scoped>

</style>



