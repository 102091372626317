<template>
  <v-dialog v-if="Role.access_push_contract"
            v-model="dialog"
            persistent
            max-width="60%">
    <template v-slot:activator="{ on, attrs }">
      <v-btn v-bind="attrs" v-on="on"
             plain depressed
             color="primary" class="text-none"
             :dark="!disableButton" :disabled="disableButton">
        <v-icon class="rotateR90 mr-3">mdi-bookmark-plus-outline</v-icon>
        <v-icon class="rotate90 mr-3">mdi-bookmark-plus-outline</v-icon>
        <div class="translateX40">{{ texts.push_contract_button }}</div>
      </v-btn>
    </template>
    <v-card>
      <v-card-title class="mb-2 center-text teal--text">
        {{ texts.push_contract_title }}
      </v-card-title>
      <v-card-text>
        <v-textarea v-model="ground"
                    auto-grow clearable outlined
                    :label="texts.push_contract_ground"
                    rows="1" background-color="digiwhite" color="primary"/>
      </v-card-text>
      <v-card-text>
        <v-row align="center">
          <v-col cols="6">
            <v-select
              v-model="select"
              :items="items"
              :label="texts.push_contract_select"
              return-object
              item-text="article_name"
              single-line/>
          </v-col>
          <v-col cols="2">
            <v-select
              v-model="number"
              :items="numberArray"/>
          </v-col>
          <v-col cols="auto">
            <v-btn depressed plain
                   class="text-none custom-transform-class" color="primary"
                  @click="addContract()" :disabled="lockAddButton()" :dark="!lockAddButton()">
              {{ texts.push_contract_add }}
            </v-btn>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-text>
        <v-list>
          <v-list-item v-for="(item, i) in basket" :key="i">
            <v-col cols="4">{{item.article.article_name}}</v-col>
            <v-col cols="1" class="text-center">
              <v-btn icon color="primary darken-1" @click="addBasket(i)" x-small><v-icon dark>mdi-plus-circle-outline</v-icon></v-btn>
            </v-col>
            <v-col cols="2" class="text-center">{{item.number}}</v-col>
            <v-col cols="1" class="text-center">
              <v-btn icon color="red darken-1" @click="deleteBasket(i)" x-small><v-icon dark>mdi-minus-circle-outline</v-icon></v-btn>
            </v-col>
          </v-list-item>
        </v-list>
      </v-card-text>
      <v-card-actions>
        <v-btn depressed
               class="text-none custom-transform-class" color="primary"
               :loading="loading"
               @click="pushContract()" :disabled="lockButton()" :dark="!lockButton()">
          {{ texts.push_contract_validate }}</v-btn>

        <v-btn depressed plain dark
               class="text-none custom-transform-class" color="blue darken-3"
               @click="cancel">{{ texts.push_contract_cancel }}</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters } from "vuex";
import { postJson } from "@/store/post";
import { getJson } from "@/store/get";
import { pushContractBus } from "@/components/customer/pushContractBus";

export default {
  name: "PushContract",
  props: ["customer", "texts", "wallets"],
  data() {
    return {
      loading: false,
      dialog: false,
      ground: "",
      select: null,
      number: null,
      items: [],
      numberArray: [1],
      basket: [],
    }
  },
  created() {
    this.reset()
    this.getGT()
  },
  computed: {
    ...mapGetters({User: "StateUser", Role: "StateRole", Config: "StateConfig"}),
    disableButton() {
      return ((this.Role && !this.Role.access_push_contract))
    },
  },
  methods: {
    addBasket(index) {
      this.basket[index].number++
    },
    deleteBasket(index) {
      this.basket[index].number--
      if (this.basket[index].number === 0) {
        this.basket.splice(index, 1)
      }
    },
    getGT() {
      getJson("/contracts/tariff-list").then((resp) => {
        if (resp?.status === 200) {
          this.items = resp.data.articles
        }
      })
    },
    reset() {
      this.number = this.numberArray[0]
      this.select = null
      if (this.Config.pushBasket) {
        this.numberArray = [1,2,3,4,5,6,7,8,9,10]
      } else {
        this.numberArray = [1]
      }
    },
    cancel() {
      this.ground = ""
      this.dialog = false
      this.basket = []
    },
    lockButton() {
      return this.basket.length === 0 || this.ground.length < 5
    },
    lockAddButton(){
      return this.select === null || (!this.Config.pushBasket && this.basket.length === 1)
    },
    validatePushAndExit() {
      this.loading = false
      this.reset()
      pushContractBus.$emit('pushContract')
      this.cancel()
    },
    pushContract() {
      this.loading = true
      postJson("/actions/push-contract",
      {
              language: navigator.language.split('-')[0],
              basket: this.basket,
              ground: this.ground,
              'service-id': this.$route.params.service_id,
              'client-id': parseInt(this.$route.params.client_id),
              'calypso-app': this.wallets[0].calypsoNumber,
            }
          ).then((resp) => {
        if (resp?.status === 200) {
          setTimeout(() => {
            this.validatePushAndExit()
          }, 2000)
        } else {
          console.log(resp)
        }
      }).catch(() => {}).then(() => {
        setTimeout(() => {this.showGranted = false}, 1500)
        setTimeout(() => {this.showError = false}, 1500)
      })
    },
    addContract() {
      this.basket.push({
        'article': this.select,
        'number': this.number,
      })
      this.updateBasket()
      this.reset()
    },
    updateBasket() {
      let tmpBasket = JSON.parse(JSON.stringify(this.basket))
      this.basket = []
      tmpBasket.forEach((el) => {
        let isIn = false
        for(let i=0; i<this.basket.length; i++) {
          if (this.basket[i].article.article_code === el.article.article_code &&
              this.basket[i].article.contract_tariff_provider === el.article.contract_tariff_provider) {
            isIn = true
            this.basket[i].number += el.number
          }
        }
        if (!isIn) {
          this.basket.push(JSON.parse(JSON.stringify(el)))
        }
      })
    }
  },
  watch: {
    "User.language": function () {
      this.getGT()
    },
  },
}
</script>

<style scoped>
.translateX40 {
  transform: translateX(-35px);
}
.rotate90 {
  -webkit-transform:rotate(90deg);
  -moz-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  -o-transform: rotate(90deg);
  transform: rotate(90deg) translateY(40px);
}
.rotateR90 {
  -webkit-transform:rotate(-90deg);
  -moz-transform: rotate(-90deg);
  -ms-transform: rotate(-90deg);
  -o-transform: rotate(-90deg);
  transform: rotate(-90deg);
}
</style>
