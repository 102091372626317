<template>
  <v-card class="overflow-y-auto px-0 py-1 mx-0 transition-swing" elevation="0">
    <v-card-text class="px-0">
      <v-data-table
          :headers="headers"
          :items="items"
          :items-per-page="-1"
          sort-by="validation_date"
          sort-desc
          :no-data-text="texts.no_data"
          :footer-props="{
               'items-per-page-text':texts.items_per_page,
               'pageText': texts.page_text
          }">

				<template v-slot:item.event_network_id="{ item }">
            <v-img alt="Logo" contain :src="getImgSrc(item.event_network_id)" transition="scale-transition" width="40"/>
				</template>
        <template v-slot:item.event_code="{ item }">
          <v-chip small label dark
                  class="font-weight-medium" :color="getTypeColor(item)">
            {{ getType(item) }}
          </v-chip>
        </template>
        <template v-slot:item.validation_date="{ item }">
          <div class="font-weight-medium">{{ getDate(item.event_date_stamp) }} - {{ getHour(item.event_time_stamp) }}</div>
        </template>
        <template v-slot:item.wallet="{ }">
          <div class="font-weight-medium blue--text">{{ calypsoNumber }}</div>
        </template>
        <template v-slot:item.contract="{ item }">
          <v-chip small label dark
                  class="font-weight-medium" color="cyan darken-2">
            {{ item.contract }}
          </v-chip>
        </template>
        <template v-slot:item.tariff_id="{ item }">
          {{ item.tariff_id }}
        </template>
        <template v-slot:item.contract_serial_number="{ item }">
          <format-clipboard :text="item.contract_serial_number" match="3"></format-clipboard>
        </template>
        <template v-slot:item.passengers="{ item }">
          <div>{{ getPassenger(item) }}</div>
        </template>
        <template v-slot:item.counter_after="{ item }">
          <div>{{ item.counter_after }}</div>
        </template>

      </v-data-table>
    </v-card-text>
  </v-card>
</template>

<script>
import {getJson} from "@/store/get";
import {postJson} from "@/store/post";
import {mapGetters} from "vuex";
import FormatClipboard from "@/components/FormatClipboard";

export default {
  name: "Validations",
  props: ["calypsoNumber", "displayed"],
  components:{
    FormatClipboard
  },
  data() {
    return {
      texts: {},
      eventCodeList: [],
      eventResultList: [],
      headers: [],
      items: [],
			tec: require("@/assets/tec.png"),
      bmc: require("@/assets/bmc.png"),
			stib: require('@/assets/stib.png'),
			sncb: require("@/assets/sncb.png"),
			delijn: require("@/assets/delijn.png"),
      tisseo: require("@/assets/tisseo.png"),
      rla: require("@/assets/rla.png"),
    }
  },
  created() {
    this.getTexts()
    this.getValidation()
  },
  computed: {
    ...mapGetters({ User: "StateUser", Locale: "StateLocale", Config: "StateConfig", ValidationSwitch: 'StateValidationSwitch' }),
  },
  methods: {
    getDate(_date) {
      if (_date == null) {return}
      let s = _date.split("-").reverse()
      s[0] = s[0].slice(0,2)
      return s.join("/")
    },
    getHour(_hour) {
      return _hour
    },
    createDate(_date) {
      return new Date(_date)
    },
    getTypeColor(item) {
      if (item?.validation_type === "R") {
        return "red darken-2"
      }
      return "green darken-1"
    },
    getType(item) {
      let i = 0;
      if (item?.validation_type === "A") {
        for (i=0; i< this.eventCodeList.length; i++) {
          if (""+this.eventCodeList[i].eventCode === item.event_code) {
            return this.eventCodeList[i].eventText
          }
        }
        return item.event_code
      } else if (item?.validation_type === "R") {
        for (i=0; i< this.eventResultList.length; i++) {
          if (""+this.eventResultList[i].eventResult === item.event_result) {
            return this.eventResultList[i].eventText
          }
        }
        return item.event_result
      }
    },
    getPassenger(item) {
      if (item?.validation_type === "R") {
        return ""
      }
      if (item?.event_code === "17" || item?.event_code === "65" || item?.event_code === "241" || item?.event_code === "1") {
        if (item?.event_total_journeys != null && item?.event_total_journeys !== '') {
          if (this.Config?.passengerOffset != null) {
            return parseInt(item.event_total_journeys) + this.Config.passengerOffset
          } else {
            return parseInt(item.event_total_journeys)
          }
        } else {
          return 0
        }
      } else if (item?.event_code === "22" || item?.event_code === "70" ||  item?.event_code === "246") {
        if (item?.event_interchange_passengers != null && item?.event_interchange_passengers !== '') {
          if (this.Config?.passengerOffset != null) {
            return parseInt(item.event_interchange_passengers) + this.Config.passengerOffset
          } else {
            return parseInt(item.event_interchange_passengers)
          }
        } else {
          return 0
        }
      } else {
        return item?.event_code
      }
    },
    getTexts(){
      if (this.User) {
        getJson("/text/validations/" + this.User.language.id).then((resp) => {
          if (resp?.status === 200) {
            this.texts = resp.data.ValidationsView[0]
            this.eventCodeList = resp.data.ValidationEventList
            this.eventResultList = resp.data.ValidationEventResultList
            this.headers= [
              { text: this.texts.type, align: 'start', sortable: true, value: 'event_code'/*, width: "10%"*/ },
              { text: this.texts.validation_date, align: 'start', sortable: true, value: 'validation_date'/*, width: "10%" */},
              { text: this.texts.contract, align: 'start', sortable: true, value: 'contract'/*, width: "14%" */},
                ]
            if (this.Config.tariffId) {
              this.headers.push({ text: this.texts.tariff_id, align: 'center', sortable: true, value: 'tariff_id'/*, width: "14%" */},)
            }
            this.headers.push({ text: this.texts.serial_number, align: 'center', sortable: true, value: 'contract_serial_number'/*, width: "14%" */},)
            if (this.Config.validationsEventNetwork) {
              this.headers.unshift({ text: this.texts.event_network_id, align: 'start', sortable: false, value: 'event_network_id'})
            }
            if (this.Config.validationsPassenger) {
              this.headers.push({ text: this.texts.passengers, align: 'center', sortable: true, value: 'passengers'/*, width: "14%" */},)
            }
            if (this.Config.counterAfter) {
              this.headers.push({ text: this.texts.counter_after, align: 'center', sortable: false, value: 'counter_after'/*, width: "14%" */},)
            }
          }
        })
      }
    },
    getValidation() {
      if (this.calypsoNumber !== null && this.User) {
        let locale = "en_EN"
        if (this.Locale) {
          locale = this.Locale.substring(0,2)
        }

        postJson('/validations/'+this.$route.params.service_id+'/' + this.calypsoNumber + '/' + locale, {"invalidation": this.ValidationSwitch}).then((resp) => {
          if (resp?.status === 200) {
            this.items = []
            if (resp.data == null) {
              return
            }
            resp.data.forEach((el) => {
              if (!this.ValidationSwitch || el.validation_type === "A") {
                el.validation_date =  new Date(Date.UTC(el.event_date_stamp.slice(0,4), el.event_date_stamp.slice(5,7),
                    el.event_date_stamp.slice(8,10), el.event_time_stamp.slice(0,2),
                    el.event_time_stamp.slice(3,5), el.event_time_stamp.slice(6,8)))
                this.items.push(el)
              }
            })
          }
        })
      }
    },
		getImgSrc(eventNetworkId) {
			if (this.Config.validationsEventNetwork) {
				if (eventNetworkId === "1")
					return this.sncb
				else if (eventNetworkId === "2")
					return this.stib
				else if (eventNetworkId === "3")
					return this.delijn
				else if (eventNetworkId === "4")
					return this.tec
        else
          return this[this.Config.logo]
			}
		},
  },
  watch: {
    "User.language": function () {
      this.getTexts()
      this.getValidation()
    },
    calypsoNumber() {
      this.getValidation()
    },
    displayed() {
      if (this.displayed) {
        this.getValidation()
      }
    },
    ValidationSwitch() {
      this.getValidation()
    },
  },
}
</script>

<style scoped>

</style>
