<template>
  <v-dialog v-model="dialog" max-width="60%">
    <template v-slot:activator="{ on, attrs }">
      <v-btn v-bind="attrs" v-on="on"
             color="primary" class="text-none custom-transform-class"
             :disabled="isAdmin()" :dark="!isAdmin()" :text="!isAdmin()">
        <v-icon left>mdi-account-cog-outline</v-icon>
        {{ texts.modify }}
      </v-btn>
    </template>
    <v-card>
      <v-card-title class="mb-2">
        <span class="text-h5">{{ texts.modify_title }}</span>
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text class="my-4">
        <v-container>
          <v-row>
            <v-col cols="6">
              <v-text-field v-model="tmpUser.email"
                            required outlined
                            :label="texts.email"/>
            </v-col>
            <v-col cols="6">
              <v-select v-model="tmpUser.company"
                        required outlined
                        :label="texts.company" :items="getCompanies"/>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="6">
              <v-text-field v-model="tmpUser.firstname"
                            required outlined
                            :label="texts.firstname"/>
            </v-col>
            <v-col cols="6">
              <v-text-field v-model="tmpUser.lastname"
                            required outlined
                            :label="texts.lastname"/>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="6">
              <v-text-field v-model="tmpUser.phone"
                            required outlined
                            :label="texts.phone"/>
            </v-col>
            <v-col cols="6">
              <v-select v-model="tmpUser.language"
                        required outlined
                        :label="texts.language_label" :items="languages"/>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-select v-model="tmpUser.role"
                        required outlined
                        :label="texts.role"
                        :items="getRoles()"/>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions class="mt-2">
        <v-btn depressed large dark
               class="text-none custom-transform-class" color="blue darken-1"
               @click="updateUser()">{{ texts.modify }}</v-btn>
        <v-spacer></v-spacer>
        <v-btn depressed large dark
               class="text-none custom-transform-class" color="blue darken-1"
               @click="dialog = false">{{ texts.cancel }}</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters } from "vuex";
import { putJson } from "@/store/put";
import {GetLanguage, GetRoleId, GetLanguageId, GetRole, GetRoles, GetCompanies} from "./common";

export default {
  name: "UpdateUser",
  props: ['user','languages', 'roles', 'texts', 'companies'],
  data() {
    return {
      grantedColor: "green accent-4",
      errorColor: "red accent-4",
      showGranted: false,
      showError: false,
      loading: false,
      loadingColor: "blue",

      dialog: false,

      tmpRoles: null,
      tmpCompanies: null,
      tmpUser: {
        id: null,
        email: null,
        lastname: null,
        firstname: null,
        company: null,
        phone: null,
        language: null,
        role_id: null,
        role: null,
      }
    };
  },
  computed: {
    ...mapGetters({ User: "StateUser" }),
    getCompanies() {
      return GetCompanies(this.tmpCompanies)
    }
  },
  created() {
    this.tmpUser = JSON.parse(JSON.stringify(this.user))
    this.tmpUser.company = this.tmpUser.company.id
    setTimeout(()=> {
      this.tmpRoles = this.roles
      this.tmpCompanies = this.companies
    }, 20)

    setTimeout(() => {
      this.tmpUser.language = GetLanguage(this.languages, this.user)
      this.tmpUser.role =  GetRole(this.tmpRoles, this.user)
    }, 40)
  },

  methods: {
    getRoles(){
      let result = []
      let i = 0;
      if (!this.tmpRoles) {
        return
      }
      for(i; i < this.tmpRoles.length; i++) {
        if (!this.tmpRoles[i].is_admin) {
          result.push(this.tmpRoles[i])
        }
      }
      return GetRoles(result)
    },
    isAdmin() {
      return this.user.role.is_admin
    },
    updateUser() {
      let updatedUser = {
        id: this.user.ID,
        email: this.tmpUser.email,
        company_id: this.tmpUser.company,
        firstname: this.tmpUser.firstname,
        lastname: this.tmpUser.lastname,
        phone: this.tmpUser.phone,
        language: GetLanguageId(this.languages, this.tmpUser, this.user),
        role_id: GetRoleId(this.tmpRoles, this.tmpUser),
      }
      putJson("/user/" + updatedUser.id, updatedUser).then((resp) => {
         if (resp && resp.status === 200) {
           this.showGranted = true
           this.$emit("user-updated", resp.data)
         } else {
           this.showError = true
         }
       }).catch(() => {}).then(() => {
         this.loading = false
         setTimeout(() => {this.showGranted = false}, 1200)
         setTimeout(() => {this.showError = false}, 1200)
      })

      this.dialog = false
    }
  },
  watch: {
    user() {
      this.tmpUser = JSON.parse(JSON.stringify(this.user))
      this.tmpUser.company = this.tmpUser.company.id
      setTimeout(()=> {
        this.tmpRoles = this.roles
        this.tmpCompanies = this.companies
      }, 20)

      setTimeout(() => {
        this.tmpUser.language = GetLanguage(this.languages, this.user)
        this.tmpUser.role =  GetRole(this.tmpRoles, this.user)
      }, 40)
    },
    roles() {
      this.tmpRoles = this.roles
    },
    companies() {
      this.tmpCompanies = this.companies
    }
  }
};
</script>
<style scoped>
.custom-transform-class{
  text-transform: uppercase
}
</style>