<template>
  <v-card class="overflow-y-auto px-0 py-1 mx-0 transition-swing" elevation="0">
    <v-card-text class="px-0">
      <v-data-table ref="blibou"
          :headers="headers"
          :items="items"
          :items-per-page="15"
          show-expand
          :expanded.sync="expanded"
          item-key="purchase_id"
          :single-expand="false"
          sort-by="purchase_date"
          sort-desc
          :no-data-text="texts.no_data"
          :footer-props="{
               'items-per-page-text':texts.items_per_page,
               'pageText': texts.page_text
          }">

        <template v-slot:item.purchase_id="{ item }" class="px-0">
          <v-chip label
                  class="font-weight-medium"  color="plain"
                  :ref="'purchase_id_'+item.purchase_id">{{ item.purchase_id }}</v-chip>
        </template>
        <template v-slot:item.purchase_date="{ item }">
          <div label
               class="font-weight-medium" color="white"
               :ref="'purchase_date_'+item.purchase_id">{{ DateUtcToStandard(item.purchase_date) }} - {{HourUtcToStandard(item.purchase_date)}}</div>
        </template>
        <template v-slot:item.calypso_app_number="{ item }">
          <div class="font-weight-medium blue--text"
               :ref="'calypso_app_number_'+item.purchase_id">
            <span v-if="getDeci()">{{formatCalypso(item.calypso_app_number)}}</span>
            <span v-else>
              {{ item.calypso_app_number }}
            </span>
          </div>
        </template>
        <template v-slot:item.transaction_status="{ item }">
          <v-chip small label
                  class="font-weight-medium"
                  :ref="'transaction_status_'+item.purchase_id"
                  :color="getTransactionStatusColor(item.transaction_status)"
                  :dark="getTransactionStatusDark(item.transaction_status)">
            {{ texts.transactionStatusList[item.transaction_status] }}
          </v-chip>
        </template>
        <template v-slot:item.product="{ item }">
          <div v-if="item.orders.length === 1"
               class="font-weight-medium"
               :ref="'product_'+item.purchase_id">{{ item.orders[0] ? item.orders[0].article_name: "-"}}</div>
          <div v-else
               class="font-weight-medium"
               :ref="'product_'+item.purchase_id">{{ getNumberProduct(item) }}</div>
        </template>
        <template v-slot:item.payment_method="{ item }">
          <div :ref="'payment_method_'+item.purchase_id">{{ item.payment_method ? texts.paymentMethodList[item.payment_method]: "-" }}</div>
        </template>
        <template v-slot:item.price="{ item }">
          <div :ref="'price_'+item.purchase_id">{{ item.price !== null ? displayMoney(item.price) + "€": "-" }}</div>
        </template>
        <template v-slot:item.remote_id_transaction_ref="{ item }">
          <div :ref="'remote_id_transaction_ref_'+item.purchase_id">{{ item.remote_id_transaction_ref }}</div>
        </template>
        <template v-slot:item.transaction_id="{ item }">
          <div :ref="'transaction_id_'+item.purchase_id">{{ item.transaction_id ? item.transaction_id: "-"}}</div>
        </template>
        <template v-slot:item.authorization_number="{ item }">
          <div :ref="'authorization_number_'+item.purchase_id">{{ item.authorization_number ? item.authorization_number: "-"}}</div>
        </template>
        <template v-slot:item.contract_number="{ item }">
          <div :ref="'contract_number_'+item.purchase_id">{{ item.contract_number ? item.contract_number: "-"}}</div>
        </template>
        <template v-slot:item.justif="{ item }">
          <div :ref="'justif_'+item.purchase_id">
            <v-btn depressed outlined
                   color="primary" class="text-capitalize"
                   @click="downloadPDF(item)">
              {{ texts.justif }}
            </v-btn>
          </div>
        </template>

        <template v-slot:item.data-table-expand="{ expand, item }">
          <td v-if="item.orders.length > 1"
              class="px-0"
              :ref="'expand_'+item.purchase_id">
            <v-btn icon
                   @click="realExpand(expand, item)">
              <v-icon v-if="!item.isExpanded">mdi-chevron-right</v-icon>
              <v-icon v-else>mdi-chevron-down</v-icon>
            </v-btn>
          </td>
        </template>

        <template v-slot:expanded-item="{ headers, item }">
          <td v-if="item.orders.length > 1 && item.isExpanded" :colspan="headers.length" class="px-0">
            <v-data-table color="digisubpurchase"
              :headers="sub_headers"
              hide-default-footer
              :items="item.orders">

              <template v-slot:item.len="{ item }">
                <div :ref="'len_'+item.article_id">{{ item.len }}</div>
              </template>

              <template v-slot:item.price_amount="{ item }">
                <div :ref="'price_amount_'+item.article_id">{{ item.price_amount ? displayMoney(item.price_amount) + "€": "-" }}</div>
              </template>
            </v-data-table>
          </td>
        </template>

      </v-data-table>
    </v-card-text>
  </v-card>
</template>

<script>
import Vue from "vue";
import {getJson} from "@/store/get";
import {postJson} from "@/store/post";
import {utcHourToStandard, utcToStandard} from "@/utils";
import {mapGetters} from "vuex";
import {pushContractBus} from "@/components/customer/pushContractBus";

export default {
  name: "Purchases",
  props: ["calypsoNumber", "displayed"],
  data() {
    return {
      expanded: [],
      items: [],
      texts: {
        associated: '',
      }
    }
  },
  created() {
    this.init()
    pushContractBus.$on("pushContract", () => {
      this.init()
    })
  },
  computed: {
    ...mapGetters({ User: "StateUser", Locale: "StateLocale", Config: "StateConfig" }),
    headers() {
      let h = [
        { text: "", align: 'center', sortable: false, value: "data-table-expand", width: "4%", class: "px-2" },
        { text: this.texts.purchaseId, align: 'start', sortable: true, value: 'purchase_id', width: "7%", class: "px-2" },
        { text: this.texts.purchaseDate, align: 'start', sortable: true, value: 'purchase_date', width: "11%", class: "px-2" },
        { text: this.texts.calypsoAppNumber, align: 'start', sortable: true, value: 'calypso_app_number', width: "11%", class: "px-2" },
        { text: this.texts.product, align: 'start', sortable: false, value: 'product', width: "6%", /*class: "px-2"*/ },
        { text: this.texts.transactionStatus, align: 'start', sortable: true, value: 'transaction_status', width: "11%", class: "px-2" },
        { text: this.texts.payment, align: 'start', sortable: true, value: 'payment_method', width: "15%", class: "px-2" }
      ]

      h.push({ text: this.texts.price, align: 'end', sortable: true, value: 'price', width: "6%", /*class: "px-2"*/ })
      if (this.Config.remoteTransactionRef) {
        h.push({ text: this.texts.remoteIdTransactionRef, align: 'end', sortable: false, value: 'remote_id_transaction_ref', class: "px-2" })
      }
      h.push({ text: this.texts.transactionId, align: 'start', sortable: true, value: 'transaction_id', width: "9%", class: "px-2" })
      h.push({ text: this.texts.authorizationNumber, align: 'start', sortable: false, value: 'authorization_number', width: "11%", class: "px-2" })
        // M1030 { text: this.texts.contractNumber, align: 'start', sortable: false, value: "contract_number", width: "9%", class: "px-2" },

      if (this.Config.pdfJustif) {
        h.push({ text: "", align: 'center', sortable: false, value: 'justif', class: "px-2" })
      }
      return h
    },
    sub_headers() {
      return [
        {text: null, sortable: false, width: "33%"},
        { text: this.texts.number, align: 'start', sortable: false, value: 'len', width: "7%"},
        { text: this.texts.product, align: 'start', sortable: true, value: 'article_name', width: "22%"},
        { text: this.texts.unit_price, align: 'end', sortable: true, value: 'price_amount', width: "9%"},
        {text: null, sortable: false, width: "29%"},
      ]
    }
  },
  methods: {
    init() {
      this.getTexts()
      this.getPurchases()
    },
    digiUpdate() {
      this.init()
    },
    downloadPDF(item) {
      let data = {
        "purchased_id": item.purchase_id
      }
      postJson("/pdf", data, 'blob').then((resp) => {
        if (resp?.status === 200) {
          this.createPDF(resp.data, "justificatif_"+item.purchase_id+".pdf")
        }
      })
    },
    createPDF(content, filename) {
      let fileURL = window.URL.createObjectURL(new Blob([content], {type: "applications/pdf;charset=UTF-8;"}));
      let fileLink = document.createElement('a');
      fileLink.href = fileURL;
      fileLink.setAttribute('download', filename);
      document.body.appendChild(fileLink);
      fileLink.click();
    },
    formatCalypso(calypso) {
      return parseInt(calypso, 16).toLocaleString("fr-FR").split(",").join(" ").replace(" ", "")
    },
    getDeci() {
      if (this.User) {
        return this.User.hexa === "deci"
      } else {
        return true
      }
    },
    updatePadding(){
      setTimeout(()=>{
        Vue.nextTick(() => {
          this.items?.forEach((el) => {
            //Faire une liste de string fait accèder aux objets JS, pas aux elements du DOM... Du coup a la main...
            let expand = this.$refs["expand_"+el.purchase_id]
            let purchase_id = this.$refs["purchase_id_"+el.purchase_id]
            let purchase_date = this.$refs["purchase_date_"+el.purchase_id]
            let calypso_app_number = this.$refs["calypso_app_number_"+el.purchase_id]
            //let product = this.$refs["product_"+el.purchase_id]
            let transaction_status = this.$refs["transaction_status_"+el.purchase_id]
            let payment_method = this.$refs["payment_method_"+el.purchase_id]
            //let price = this.$refs["price_"+el.purchase_id]
            let transaction_id = this.$refs["transaction_id_"+el.purchase_id]
            let remote_id_transaction_ref = this.$refs["remote_id_transaction_ref_"+el.purchase_id]
            let authorization_number = this.$refs["authorization_number_"+el.purchase_id]
            let contract_number = this.$refs["contract_number_"+el.purchase_id]
            let justif = this.$refs["justif_"+el.purchase_id]
            let yolo = [
              expand,
              purchase_id,
              purchase_date,
              calypso_app_number,
              transaction_status,
              //product,
              payment_method,
              //price,
              transaction_id,
              remote_id_transaction_ref,
              authorization_number,
              contract_number,
              justif,
            ]

            if (yolo) {
              yolo.forEach((yo) =>{
                let par
                if (yo) {
                  if (yo.parentNode != null) {
                    par= yo.parentNode
                  } else {
                    par = yo.$el.parentNode
                  }
                  par.classList.add("px-2")
                }
              })
            }
          })
        })
      }, 1000)
    },
    getNumberProduct(item){
      let resp = 0;
      item.orders.forEach((el) => {
        resp += el.len
      })
      return resp
    },
    displayMoney(cts) {
      let resp = ""+cts/100
      if (resp.indexOf('.') !== -1 && resp.indexOf('.') === resp.length-2) {
        resp += "0"
      }
      return resp
    },
    DateUtcToStandard(utc) {
      return utcToStandard(utc);
    },
    HourUtcToStandard(utc) {
      return utcHourToStandard(utc);
    },
    realExpand(expand, item) {
      item.isExpanded = !item.isExpanded
      expand(item.isExpanded)
    },
    getTransactionStatusColor(status){
      if (this.texts?.transactionStatusView)
        return this.texts?.transactionStatusView[status]?.color
      else
        return "white"
    },
    getTransactionStatusDark(status){
      if (this.texts?.transactionStatusView)
        return this.texts?.transactionStatusView[status]?.dark
      else
        return "false"
    },
    getToggleDisplayDetails(details){
      return  !details
    },
    toggleDisplayDetails(item, value) {
      let indexOfItem = this.items.indexOf(item)
      for (let index = indexOfItem+1; index < this.items.length; index ++) {
        if (this.items[index].type === 'purchase_detail') {
          this.items[index].display = value
        } else if (this.items[index].type === 'purchase') {
          break
        }
      }
      item.display_details = value
    },

    getTexts(){
      if (this.User) {
        getJson("/text/purchases/" + this.User.language.id).then((resp) => {
          if (resp?.status === 200) {
            this.texts = JSON.parse(JSON.stringify(resp.data.PurchasesView[0]))

            this.texts.transactionStatusList = {}
            this.texts.paymentMethodList = {}
            this.texts.transactionStatusView = {}

            if (resp?.data?.TransactionStatusList !== null) {
              resp.data.TransactionStatusList.forEach((el) => {
                this.texts.transactionStatusList[el.statusId] = el.statusText
              })
            }

            if (resp?.data?.PaymentMethod !== null) {
              resp.data.PaymentMethod.forEach((el) => {
                this.texts.paymentMethodList[el.paymentMethodId] = el.label
              })
            }

            if (resp.data.TransactionStatusView !== null) {
              resp.data.TransactionStatusView.forEach((el) => {
                this.texts.transactionStatusView[el.transaction_status] = {color: el.color, dark: el.dark}
              })
            }
          }
        }).catch(()=>{})
      }
    },
    getPurchases(){
      if (this.calypsoNumber !== null && this.User) {
        let locale = "en_EN"
        if (this.Locale) {
          locale = this.Locale.substring(0,2)
        }
        getJson("/purchases/"+this.$route.params.service_id+"/calypso/" + this.calypsoNumber + "/" + locale).then((resp) => {
          if (resp?.status === 200) {
            this.items = []
            if (resp.data["purchased-calypso"] !== null) {
              resp.data["purchased-calypso"].forEach((el) => {
                let obj = {
                  purchase_id: el.purchased_history_id,
                  purchase_date: el.purchase_date,
                  calypso_app_number: el.calypso_number,
                  //product: ,
                  transaction_status: el.transaction_status,
                  payment_method: el.payment_method,
                  remote_id_transaction_ref: el.remote_id_transaction_ref,
                  price: el.price.Int64,
                  transaction_id: el.id_transaction_ref.String,
                  authorization_number: el.authorization_number.String,
                  //contract_number: ,
                  type: 'purchase',
                  orders: [],
                  isExpanded: false,
                }

                resp.data.purchased_history_orders.forEach((order) => {
                  if (obj.purchase_id === order.purchased_history_id) {
                    obj.orders.push(order)
                  }
                })

                if (obj.orders.length > 0) {
                    this.items.push(obj)
                }
              })
            }
            for (let i=0; i<this.items.length; i++) {
              let pureOrders = []
              for (let j=0; j<this.items[i].orders.length; j++) {
                let valid = true
                for (let k=0; k<pureOrders.length; k++) {
                  //if (this.items[i].orders[j].article_name === pureOrders[k].article_name) {
                  if (this.items[i].orders[j].identifier_contract.String === pureOrders[k].identifier_contract.String) {
                    valid = false
                    pureOrders[k].len++
                  }
                }
                if (valid) {
                  pureOrders.push({
                    article_id: this.items[i].orders[j].article_id,
                    article_name: this.items[i].orders[j].article_name,
                    contract_sale_load_counter: this.items[i].orders[j].contract_sale_load_counter.Int64,
                    end_validity_date: this.items[i].orders[j].end_validity_date.Time,
                    identifier_contract: this.items[i].orders[j].identifier_contract.String,
                    price_amount: this.items[i].orders[j].price_amount,
                    purchased_history_id: this.items[i].orders[j].purchased_history_id,
                    rebuildable: this.items[i].orders[j].rebuildable,
                    start_validity_date: this.items[i].orders[j].start_validity_date.Time,
                    len: 1,
                  })
                }
              }

              this.items[i].orders = pureOrders
            }

          }
        })
      }
      this.updatePadding()
    },
  },
  watch: {
    "User.language": function () {
      this.getTexts()
      this.getPurchases()
    },
    "calypsoNumber": function () {
      if (this.displayed) {
        this.getPurchases()
      }
    },
    "displayed": function () {
      if (this.displayed) {
        this.getPurchases()
      }
    }
  },
}
</script>

<style scoped>
</style>