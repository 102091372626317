<template>
    <v-data-table hide-default-footer
                  class="pa-0" :items-per-page="-1"
                  :headers="headers" :items="data"  :disable-sort="true">
        <template v-slot:item="{ item }">
            <tr>
                <td v-for="(header, headerIndex) in headers" :key="headerIndex">
                  <div v-if="header.value !=='logo'">
                    <div v-if="header.value.match(/^\d/)">
                        <div :class="getClassByItemAndHeader(item)" :align="header.align" :style="getStyleByItemTypeAndHeader(item, header)">
                            {{ item[header.value] }}
                        </div>
                    </div>
                    <div v-else :class="getClassByItemAndHeader(item)" :align="header.align" :style="getStyleByItemTypeAndHeader(item, header)">
                        {{ item[header.value]}}
                    </div>
                  </div>
                  <div v-else>
                    <div v-if="shouldDisplayLogo(item)">
                      <v-img width="40" :src="getImgSrc(item.contract_tariff_provider)" transition="scale-transition"></v-img>
                    </div>
                  </div>
                </td>
            </tr>
        </template>
    </v-data-table>
</template>

<script>
import {mapGetters} from "vuex";
import * as utils from "@/utils";

export default {
  name: "PurchasesDataTable",
  props: {
    headers: {
      type: Array,
      value: [],
    },
    data: {
      type: Array,
      value: [],
    },
    contractTariffProviderLogos: {
      type: Array,
      value: [],
    }
  },
  data () {
    return {
    }
  },
  computed: {
    ...mapGetters({Config: "StateConfig"})
  },
  methods: {
    getImgSrc(contractTariffProvider) {
      let i = 0
      for (i=0; i<this.contractTariffProviderLogos.length; i++) {
        if (this.contractTariffProviderLogos[i]?.ContractTariffProvider === contractTariffProvider) {
          return this.contractTariffProviderLogos[i].Image
        }
      }
      return utils.networks[this.Config.logo]
    },
    shouldDisplayLogo(item) {
      return item.contract_tariff_provider !== undefined
    },
    getStyleByItemTypeAndHeader(item, header)  {
      let style = "";
      if (header.value === 'money_amount' || header.value === 'money_amount_percentage')
        style += "color: #4bce67;"
      else if (header.value === 'total' || header.value === 'total_percentage')
        style += "color: #0275d8;"
      if ((('type' in item) && item.type === 'total') ||
          (header.value.match(/^\d/) && ('type' in item) && item.type === 'data'))
        style += "font-weight: bold;"
      if (('type' in item) && item.type === 'sumup_sales')
        style += "font-style: italic;"
      return style
    },
    getClassByItemAndHeader(item) {
      let classStyle = "";
      if (item && (item.type === 'data' || item.type === 'separator' || item.type === 'total' || item.type === 'installations'))
        classStyle += "font-weight-medium "
      if (('type' in item) && item.type === 'sumup_sales')
        classStyle += "font-italic "
      return classStyle
    }
  }
}
</script>

<style scoped>

</style>
