const axios = require('axios').default;

import {getJson} from '@/store/get';
import {postJson} from "@/store/post";
import {login, logout} from '@/store/login.js';

const state = {
  user: null,
  token: null,
  locale: null,
  role: null,
  search: null,
  logos: null,
  one: null,
  two: null,
  birth: null,
  remember: false,
  contractSwitch: false,
  validationSwitch: false,
  connectCRM: false,
  config: null,
  packageVersion: process.env.PACKAGE_VERSION || '0',
};

const getters = {
  StateUser: (state) => state.user,
  StateToken: (state) => state.token,
  StateLocale: (state) => state.locale,
  StateRole: (state) => state.role,
  StateLogos: (state) => state.logos,
  StateSearch: (state) => state.search,
  StateSearchOne: (state) => state.one,
  StateSearchTwo: (state) => state.two,
  StateSearchBirth: (state) => state.birth,
  isAuthenticated: (state) => !!state.user,
  StateRememberMe: (state) => state.remember,
  StateContractSwitch: (state) => state.contractSwitch,
  StateValidationSwitch: (state) => state.validationSwitch,
  StateConnectCRM: (state) => state.connectCRM,
  StateConfig: (state) => state.config,
  appVersion: (state) => {
    return state.packageVersion
  },
};

const actions = {
  async Register({dispatch}, form) {
    await axios.post('/register', form)
    let UserForm = new FormData()
    UserForm.append('username', form.username)
    UserForm.append('password', form.password)
    await dispatch('LogIn', UserForm)
  },

  async LogIn({commit}, user) {
    return login({commit}, user)
  },

  async UpdateSearch({commit}, search) {
    commit("setSearch", search)
  },
  async SetLogos({commit}, logos) {
    commit("setLogos", logos)
  },
  async UpdateSearchOne({commit}, search) {
    commit("setSearchOne", search)
  },
  async UpdateSearchTwo({commit}, search) {
    commit("setSearchTwo", search)
  },
  async UpdateSearchBirth({commit}, search) {
    commit("setSearchBirth", search)
  },

  async GetUser({ commit }) {
    await getJson('/user').then((data) => {
      commit("setUser", data.data)
    })
  },

  async LogOut({ commit }) {
    console.log("on logout")
    commit("logout", null)
    logout({commit})
  },

  async ResetPassword({commit}, user) {
    commit;
    return postJson('/auth/forget-password', user)
  }
};

const mutations = {
  setLocale(state, locale) {
    state.locale = locale
  },
  setUser(state, user) {
    state.user = user;
  },
  setToken(state, token) {
    state.token = token
  },
  setRole(state, role) {
    state.role = role
  },
  setLogos(state, logos) {
    state.logos = logos
  },
  setSearch(state, search) {
    state.search = search
  },
  setSearchOne(state, search) {
    state.one = search
  },
  setSearchTwo(state, search) {
    state.two = search
  },
  setSearchBirth(state, search) {
    state.birth = search
  },
  logout(state, user) {
    state.user = user;
  },
  setRemember(state, remember) {
    state.remember = remember;
  },
  setContractSwitch(state, contractSwitch) {
    state.contractSwitch = contractSwitch;
  },
  setValidationSwitch(state, validationSwitch) {
    state.validationSwitch = validationSwitch;
  },
  setConnectToCRM(state, connectToCRM) {
    state.connectCRM = connectToCRM;
  },
  setConfig(state, config) {
    state.config = config
  }
};

export default {
  state,
  getters,
  actions,
  mutations,
};



