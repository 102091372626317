<template>
  <v-card class="overflow-y-auto px-0 py-1 mx-0 transition-swing" elevation="0">
    <v-card-text class="px-0">
      <v-data-table
          :headers="headers"
          :items="items"
          :items-per-page="10"
          sort-by="CreatedAt"
          sort-desc
          :no-data-text="texts.no_data"
          :footer-props="{
               'items-per-page-text':texts.items_per_page,
               'pageText': texts.page_text
          }">
        <template v-slot:item.CreatedAt="{ item }">
          <div label
               class="font-weight-bold" color="digiactiondetailstext">
            {{ item.CreatedAt? DateUtcToStandard(item.CreatedAt): "-" }} -
            {{ item.CreatedAt? HourUtcToStandard(item.CreatedAt): "-" }}
          </div>
        </template>
        <template v-slot:item.user="{ item }">
          <div class="font-weight-bold" color="digiactiondetailstext">{{ getActorName(item.actor) }}</div>
        </template>
        <template v-slot:item.role="{ item }">
          <div>
            {{ getActorRole(item.actor) }}
          </div>
        </template>
        <template v-slot:item.action_type.id="{ item }">
          <div class="font-weight-bold">
            {{ getFormattedCommand(item) ? getFormattedCommand(item) : "-" }}
            <span v-if="hexa" class="blue--text text--darken-2 font-weight-medium">
              {{ item.calypso_app ? "("+item.calypso_app+")" : "" }}
            </span>
            <span v-else class="blue--text text--darken-2 font-weight-medium">
              {{ item.calypso_app ? "("+parseInt(item.calypso_app, 16)+")" : "" }}
            </span>
            <span class="blue--text font-weight-regular">
              {{ item.csn ? "("+item.csn+")" : "" }}
            </span>
          </div>
        </template>
        <template v-slot:item.details="{ item }">
            <action-ground :action="item" :texts="texts" :command="getCommand(item) ? getCommand(item) : '-'"></action-ground>
        </template>
        <template v-slot:item.status="{  }">
          <div><v-icon color="green darken-1">mdi-check-circle</v-icon></div>
        </template>
      </v-data-table>
    </v-card-text>
  </v-card>
</template>

<script>
import {getJson} from "@/store/get";
import {postJson} from "@/store/post";
import {mapGetters} from "vuex";
import {utcToStandard, utcHourToStandard} from "@/utils";
import ActionGround from "@/components/customer/ActionGround";
import Vue from "vue";
import {formatCommand} from "@/utils";

export default {
  name: "Actions",
  props: ["calypsoNumber", "displayed"],
  components: {
    ActionGround
  },
  data() {
    return {
      texts: {},
      headers: [],
      items: [],
      labels: [],
    }
  },
  created() {
    this.getTexts()
    Vue.nextTick(() => {
      this.getAction()
    })
  },
  computed: {
    ...mapGetters({ User: "StateUser" }),
    hexa() {
      return this.User && this.User.hexa === 'hexa'
    }
  },
  methods: {
    getActorName(actor) {
      if (actor != null && actor.firstname != null) {
        return actor.firstname + ' ' + actor.lastname.toUpperCase()
      }
      else if (actor != null && actor.lastname != null) {
        return actor.lastname.toUpperCase()
      }
      return "-"
    },
    getActorRole(actor) {
      if (actor != null && actor.role != null) {
        return actor.role.name
      }
      return "-"
    },
    formatCommand(command, action) {
      return formatCommand(command, action)
    },
    DateUtcToStandard(utc) {
      return utcToStandard(utc);
    },
    HourUtcToStandard(utc) {
      return utcHourToStandard(utc);
    },
    getTexts(){
      if (this.User) {
        getJson("/text/actions/" + this.User.language.id).then((resp) => {
          if (resp && resp.status === 200) {
            this.texts = resp.data.ActionsViews[0]
            this.headers= [
              { text: this.texts.send_date, align: 'start', sortable: true, value: 'CreatedAt' },
              { text: this.texts.user, align: 'start', sortable: true, value: 'user' },
              { text: this.texts.role, align: 'start', sortable: true, value: 'role' },
              { text: this.texts.command, align: 'start', sortable: true, value: 'action_type.id' },
              { text: this.texts.details, align: 'center', sortable: false, value: 'details' },
              { text: this.texts.status, align: 'center', sortable: false, value: 'status' },
            ]
          }
        })
      }
    },
    getAction(){
      if (this.calypsoNumber && this.User) {
        let data = {
          'service-id': this.$route.params.service_id,
          'client-id': parseInt(this.$route.params.client_id),
          'calypso-app': this.calypsoNumber,
          'language': ''+this.User.language.id,
        }
        postJson("/actions", data).then((resp) => {
          if (resp.status === 200) {
            this.items = resp.data.actions
            this.labels = resp.data.action_labels
          }
        })
      }
    },
    getCommand(item){
      let resp = ""
      if (this.labels !== null) {
        this.labels.forEach((el)=>{
          if (item.action_type.id && item.action_type.id !== 0){
            if (el.ActionTypeId === item.action_type.id) {
              resp = JSON.parse(JSON.stringify(el.label))
            }
          } else {
            if (el.action_type.type === item.action_type.type) {
              resp = JSON.parse(JSON.stringify(el.label))
            }
          }
        })
      }
      return resp
    },
    getFormattedCommand(item) {
      return formatCommand(this.getCommand(item), item)
    }
  },
  watch: {
    "User.language": function () {
      this.getTexts()
      this.getAction()
    },
    "calypsoNumber": function () {
      this.getAction()
    },
    "displayed": function () {
      if (this.displayed) {
        this.getAction()
      }
    }
  },
}
</script>

<style scoped>

</style>