<template>
  <v-card height="100%" class="overflow-y-auto px-0 py-1 mx-0 transition-swing" elevation="0">
    <v-tabs color="blue" left v-model="t">
      <v-tab v-for="(tab, i) in tabs" :key="i"
             class=" text-none" :ref="'tabulationId_' + i"
             @click="triggerUpdate(i)">{{tab.tab}}</v-tab>

      <v-switch v-if="displayContractsSwitch()"
                v-model="contractSwitch"
                hide-details hide-spin-buttons
                class="ma-2 float-right v-input--reverse" style="position: absolute; right: 0px;"
                :label="contractSwitch? texts.switch_on: texts.switch_off" :loading="switchLoading"/>
      <v-switch v-if="displayValidationSwitch()"
                v-model="validationSwitch"
                hide-details hide-spin-buttons
                class="ma-2 float-right v-input--reverse" style="position: absolute; right: 0px;"
                :label="validationSwitch? texts.validation_switch_on: texts.validation_switch_off" :loading="switchLoading"/>
    </v-tabs>
    <v-tabs-items v-model="t">
      <v-tab-item v-for="(tab, i) in tabs" :key="i">
          <component :calypso-number="calypsoNumber" :is="tab.component" :displayed="display[i]"></component>
        </v-tab-item>
    </v-tabs-items>
  </v-card>
</template>

<script>
import {mapGetters} from "vuex";
import Purchases from "@/components/customer/Purchases";
import Contracts from "@/components/customer/Contracts";
import Validations from "@/components/customer/Validations";
import Actions from "@/components/customer/Actions";
import {getJson} from "@/store/get";
import { bus } from "@/components/customer/bus";

export default {
  name: "TabPanel",
  props: ["wallet"],
  components:{
    Purchases,
    Contracts,
    Validations,
    Actions
  },
  data() {
    return {
      t: null,
      display: [true, false, false],
      calypsoNumber: null,
      tabs: [],
      contractSwitch: true,
      validationSwitch: false,
      switchLoading: false,
      texts: null,
    }
  },
  created() {
    if (this.Config.keyTab) {
      window.addEventListener("keyup", this.keyup);
    }
    this.contractSwitch = this.ContractSwitch
    this.validationSwitch = this.ValidationSwitch
    bus.$on("updateCalypsoNumber", (data) => {
      this.calypsoNumber = data
    })
    this.getTexts()

    this.tabs = [{ tab: 'Purchases', component: Purchases }]
    if (this.Config.contractsTab) {
      this.tabs.push({ tab: 'Contracts', component: Contracts })
      this.display.push(false)
    }
    this.tabs.push({ tab: 'Validations', component: Validations })
    this.tabs.push({ tab: 'Actions', component: Actions })
  },
	destroyed() {
    if (this.Config.keyTab) {
      window.removeEventListener("keyup", this.keyup);
    }
	},
  computed: {
    ...mapGetters({ User: "StateUser", ContractSwitch: "StateContractSwitch", ValidationSwitch: "StateValidationSwitch", Config: "StateConfig" }),
  },
  methods: {
    keyup(event) {
			if (event.key === 'Tab') {
				this.t = (this.t+1) % this.tabs.length
				const refName = 'tabulationId_' + this.t;
				this.$refs[refName][0].$el.focus()
			}
		},
    displayContractsSwitch() {
      return this.Config.contractsTab && this.Config.contractsSwitch && this.display[1]
    },
    displayValidationSwitch() {
      if (this.Config.contractsTab) {
        return this.Config.validationsSwitch && this.display[2]
      } else {
        return this.Config.validationsSwitch && this.display[1]
      }
    },
    triggerUpdate(id){
      for(let i=0; i<this.display.length;i++) {
        this.display[i] = false
      }
      this.display[id] = true
    },
    getTexts(){
      if (this.User) {
        getJson("/text/tab_panel/" + this.User.language.id).then((resp) => {
          if (resp?.status === 200) {
            this.texts = resp.data.TabPanelView[0]
            this.tabs = []
            this.tabs.push({id: 0, tab: resp.data.TabPanelView[0].purchases, component: Purchases})
            if (this.Config.contractsTab) {
              this.tabs.push({id: 1, tab: resp.data.TabPanelView[0].contracts, component: Contracts})
            }
            this.tabs.push({id: 2, tab: resp.data.TabPanelView[0].validations, component: Validations})
            this.tabs.push({id: 3, tab: resp.data.TabPanelView[0].actions, component: Actions})
          }
        })
      }
    },
  },
  watch: {
    "User.language": function () {
      this.getTexts()
    },
    contractSwitch() {
      this.$store.commit('setContractSwitch', this.contractSwitch)
    },
    validationSwitch() {
      this.$store.commit('setValidationSwitch', this.validationSwitch)
    }
  },
}
</script>
