<template>
  <v-dialog v-model="dialog" max-width="60%" v-if="Role.access_invalidate_card">
    <template v-slot:activator="{ on, attrs }">
      <div>
        <v-btn v-bind="attrs" v-on="on"
               depressed small outlined
               color="red" class="custom-transform-class text-none" style="background-color: white!important;">
          {{ texts.warning_title_calypso }}
        </v-btn>
      </div>
    </template>
    <v-card>
      <v-card-title class="mb-2 center-text">
        {{ texts.warning_title_calypso }}
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text class="my-4">
        {{formatText}}
      </v-card-text>
      <v-card-text class="my-4">
        <v-alert color="red" elevation="3" type="error">{{texts.warning_revert}}.</v-alert>
      </v-card-text>
      <v-card-text>
        <v-text-field required outlined
                      color="red" v-model="ground" :label="texts.warning_ground"></v-text-field>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions class="mt-2">
        <v-btn depressed large outlined
               class="text-none custom-transform-class" color="red"
               @click="invalidateCalypsoApp()" :disabled="lockButton()" :dark="!lockButton()">
          <!--          <v-icon left>mdi-account-arrow-right-outline</v-icon>-->
          {{ texts.warning_confirm }}</v-btn>
        <v-spacer></v-spacer>
        <v-btn depressed large outlined
               class="text-none custom-transform-class" color="blue darken-3"
               @click="dialog = false" dark>{{ texts.warning_cancel }}</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters } from "vuex";
import { postJson } from "@/store/post";
import { utcToStandard } from "@/utils";

export default {
  name: "CalypsoApp",
  props: ['texts', 'calypso_app', 'customer'],
  data() {
    return {
      dialog: false,
      ground: null,
    };
  },
  computed: {
    ...mapGetters({ User: "StateUser", Role: "StateRole" }),
    formatText() {
      let cal = this.calypso_app.calypsoNumber
      if (this.User.hexa === "deci") {
        cal = parseInt(cal, 16)
      }
      let csnSplit = this.texts.warning_body_calypso.split("${calypso_app}").join(cal)
      csnSplit = csnSplit.split("${firstname}").join(this.customer["first-name"])
      csnSplit = csnSplit.split("${lastname}").join(this.customer["last-name"])
      csnSplit = csnSplit.split("${client_id}").join(''+this.customer["client_id"])
      return csnSplit
    }
  },
  /*created() {},*/
  methods: {
    lockButton() {
      return this.ground === null || this.ground === "" || this.ground.split(" ").filter((a) => a).length < 1;
    },
    DateUtcToStandard(utc) {
      return utcToStandard(utc);
    },
    invalidateCalypsoApp() {
      let ground = {
        ground: this.ground,
        client_id: ""+this.customer.client_id
      }
      postJson("/actions/invalidate/calypso-app/" + this.calypso_app.calypsoNumber, ground).then((resp) => {
        if (resp?.status === 200) {
          this.$emit("invalidate-card", resp.data)
        } else if (resp?.status === 204) {
          console.log(resp)
        }
      }).catch(() => {})

      this.dialog = false
    },

  },
};
</script>
<style scoped>
.custom-transform-class{
  text-transform: uppercase
}
</style>