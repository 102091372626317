<template>
<v-menu v-if="Role.access_push_contract || Role.access_delete_customer"
        v-model="dialog"
        offset-y
        :nudge-width="200" :close-on-content-click="false" :nudge-left="100">
<template v-slot:activator="{ on, attrs }">
  <v-btn v-bind="attrs" v-on="on"
         x-small plain
         color="primary" class="custom-transform-class text-none px-0" style="background-color: white!important; min-width: 0px!important;">
    <v-icon>mdi-dots-vertical</v-icon>
  </v-btn>
</template>
<v-card>
  <v-card-text>
    <v-row v-if="Config.pushContract"><push-contract :wallets="wallets" :customer="customer" :texts="texts"/></v-row>
    <v-row><customer v-if="Role.access_delete_customer" :texts="texts" :client="customer"/></v-row>
  </v-card-text>
</v-card>
</v-menu>
</template>

<script>
import {mapGetters} from "vuex";
import PushContract from "@/components/customer/PushContract";
import Customer from "@/components/customer/Invalidations/Customer";

export default {
  name: "CustomerActions",
  props: ["customer", "texts", "wallets"],
  components: {
    PushContract,
    Customer,
  },
  data() {
    return {
      dialog: false,
    }
  },
  created() {
  },
  computed: {
    ...mapGetters({User: "StateUser", Role: "StateRole", Config: "StateConfig"}),
  },
  methods: {

  },
}
</script>