<template>
  <div>
    <v-container v-if="User"
                 fluid
                 style="width: 50%">
      <v-row class="mt-6">
        <v-col class="text-left">
          <div>
            <p class="text-h4">{{ texts.title }}</p>
          </div>
        </v-col>
      </v-row>

      <v-row>
        <v-col align="center">
          <v-data-table :headers="headers" hide-default-footer :items-per-page="-1" :items="companies">
            <template v-slot:item.update="{ item }">
              <v-btn depressed
                     color="primary" class="custom-transform-class text-none"
                     @click="displayUpdateDialog(true, item)">
                <v-icon left>mdi-plus</v-icon>
                {{ texts.update }}
              </v-btn>
            </template>
          </v-data-table>
        </v-col>
      </v-row>

      <granted-dialog :show_granted="showGranted" :text="texts.granted" :color="grantedColor"/>
      <granted-dialog :show_granted="showError" :text="texts.error" :color="errorColor"/>

      <v-dialog v-model="updatedDialog" max-width="80%">
        <v-card>
          <v-card-title class="mb-2 center-text">
            {{ texts.update }}
          </v-card-title>
          <v-divider></v-divider>
          <v-card-text class="my-4">
            <v-container>
              <v-row>
                <v-text-field v-model="updatedCompany.name" required outlined :label="texts.name"></v-text-field>
              </v-row>
              <v-row>
                <v-col class="pa-0">
                  <v-autocomplete multiple small-chips deletable-chips outlined full-width
                              v-model="updatedCompany.companies"
                              :items="companiesList"
                              item-value="CompanyId"
                              :label="texts.combobox"></v-autocomplete>
                </v-col>
              </v-row>
              <v-row v-if="Config.filterStatsByCompanyContractTariffProvider">
                <v-col class="pa-0">
                  <v-container fluid class="pa-0">
                    <v-combobox
                        v-model="updatedCompany.contractTariffProviderList"
                        :hint="texts.hint"
                        :label="texts.label"
                        hide-selected outlined
                        multiple persistent-hint
                        small-chips deletable-chips
                    >
                      <template v-slot:no-data>
                        <v-list-item>
                          <v-list-item-content>
                            <v-list-item-title>
                              Tapez <code>entrée</code> pour en créer un nouveau.
                            </v-list-item-title>
                          </v-list-item-content>
                        </v-list-item>
                      </template>
                    </v-combobox>
                  </v-container>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions class="mt-2">
            <v-btn depressed large dark
                   class="text-none custom-transform-class" color="blue darken-1"
                   @click="updateCompany()">{{ texts.update }}</v-btn>
            <v-btn depressed large dark
                   class="text-none custom-transform-class" color="blue darken-1"
                   @click="updatedDialog = false">{{ texts.cancel }}</v-btn>
            <v-spacer></v-spacer>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-container>
  </div>
</template>

<script>
import GrantedDialog from "@/components/GrantedDialog";
import { mapGetters } from "vuex";
import { getJson } from "@/store/get";
import { postJson } from "@/store/post";

export default {
  name: "Companies",
  components: {
    GrantedDialog,
  },
  data() {
    return {
      grantedColor: "green accent-4",
      errorColor: "red accent-4",
      showGranted: false,
      showError: false,
      loading: false,
      loadingColor: "blue",

      updatedDialog: false,
      updatedCompany: {
        companies: null,
        contractTariffProviderList: null,
      },

      companies: [],
      company_application_join: [],
      companiesList: [],
      companyContractTariffProviderList: [],

      texts: {
        title: "All companies",
        update: "Update company",
        granted: "Ok",
        error: "Not ok",
        cancel: "Cancel",
        name: "Name",
        combobox: "Label",
        filterStatsByCompanyContractTariffProvider: "FilterStatsByCompanyContractTariffProvider",
        hint: "Liste des contract_tariff_provider que l'entreprise peut voir dans les statistiques hebdo et mensuelle",
        label: "Ajouter des contract_tariff_provider",
      },
    };
  },
  computed: {
    ...mapGetters({ User: "StateUser", Config: "StateConfig" }),
    headers() {
      return [
        { text: 'Name', align: 'start', sortable: false, value: 'name' },
        { text: '', align: 'end', sortable: false, value: 'update' },
      ]
    }
  },
  created() {
    this.getCompanies()
  },
  methods: {
    displayUpdateDialog(display, item) {
      this.updatedDialog = display
      this.updatedCompany = JSON.parse(JSON.stringify(item))
      this.updatedCompany.companies = item.companies
      this.updatedCompany.contractTariffProviderList = item.contractTariffProviderList
    },
    updateCompany() {
      let updatedContractTariffProviderList = []
      for(let i=0; i<this.updatedCompany.contractTariffProviderList.length; i++) {
        this.updatedCompany.contractTariffProviderList[i] = parseInt(this.updatedCompany.contractTariffProviderList[i])
        if (!isNaN(this.updatedCompany.contractTariffProviderList[i])) {
          updatedContractTariffProviderList.push(this.updatedCompany.contractTariffProviderList[i])
        }
      }
      this.updatedCompany.contractTariffProviderList = updatedContractTariffProviderList

      postJson("/companies/company-application", this.updatedCompany).then((resp) => {
        if (resp?.status === 200) {
          this.showGranted = true
          this.getCompanies()
        } else {
          this.showError = true
        }
      }).catch(() => {}).then(() => {
        this.loading = false
        setTimeout(() => {
          this.showGranted = false
          this.showError = false
          this.updatedDialog = false
        }, 800)
      })
    },
    deleteRole(id) {
      this.items = this.items.filter(function(el) { return el.id !== id })
    },
    getCompanies() {
      getJson("/companies").then((resp) =>{
        if (resp?.status === 200) {
          this.companies = resp.data.companiesList
          this.companyContractTariffProviderList = resp.data.companyContractTariffProviderList
          this.companiesList = resp.data.companies
          this.companies?.forEach((el) => {
            el.companies = []
            el.contractTariffProviderList = []
            for(let i=0; i < resp.data.companyCompanyList.length; i++) {
              if (el.id === resp.data.companyCompanyList[i].company_id) {
                el.companies.push(resp.data.companyCompanyList[i].company_mvad_id)
              }
            }
            for(let i=0; i<this.companyContractTariffProviderList?.length; i++) {
              if (el.id === this.companyContractTariffProviderList[i].companyID) {
                el.contractTariffProviderList.push(this.companyContractTariffProviderList[i].contractTariffProvider)
              }
            }

          })
          this.companiesList?.forEach((el) => {
            el.text = "["+el.CompanyId+"] "+el.Label
          })
        }
      })
    }
  }
};
</script>
<style scoped>
.custom-transform-class{
  text-transform: uppercase
}
</style>