<template>
  <div v-if="isLoggedIn">
    <v-app-bar app color="digibackground">
      <router-link to="/">
        <v-btn text>
          <v-img contain
                 alt="Tec Logo" transition="scale-transition" width="40"
                 :src="getImgSrc()"/>
        </v-btn>
      </router-link>

      <!-- Statistics -->
      <v-menu v-if="access_statistics" bottom offset-y :nudge-width="100" open-on-hover transition="slide-y-transition">
        <template v-slot:activator="{ on, attrs }">
          <v-btn v-bind="attrs" v-on="on"
                 large text
                 class="custom-transform-class text-none">
            {{statistics.statistics}}
            <v-icon color="black">mdi-menu-down</v-icon>
          </v-btn>
        </template>

        <v-list flat class="px-2">
          <v-list-item class="px-1">
            <v-btn small block depressed plain large
                   class="mx-2 text--black px-0 custom-transform-class text-none justify-start" to="/statistics/dashboard">{{statistics.dashboard}}
            </v-btn>
          </v-list-item>
          <v-list-item class="px-1">
            <v-btn small block depressed plain large
                   class="mx-2 text--black px-0 custom-transform-class text-none justify-start" to="/statistics/week">{{statistics.week}}
            </v-btn>
          </v-list-item>
          <v-list-item class="px-1">
            <v-btn small block depressed plain large
                   class="mx-2 text--black px-0 custom-transform-class text-none justify-start" to="/statistics/month">{{statistics.month}}
            </v-btn>
          </v-list-item>
          <v-list-item class="px-1" v-if="access_graphics">
            <v-btn small block depressed plain large
                   class="mx-2 text--black px-0 custom-transform-class text-none justify-start" to="/statistics/graphics">{{statistics.graphics}}
            </v-btn>
          </v-list-item>
          <v-list-item class="px-1" v-if="access_export">
            <v-btn small block depressed plain large
                   class="mx-2 text--black px-0 custom-transform-class text-none justify-start" to="/statistics/exports">{{statistics.exports}}
            </v-btn>
          </v-list-item>
        </v-list>
      </v-menu>

      <!-- Parameters -->
      <v-menu v-if="access_parameters || access_role || access_company"
              bottom offset-y open-on-hover
              :nudge-width="100"
              transition="slide-y-transition">
        <template v-slot:activator="{ on, attrs }">
          <v-btn v-bind="attrs" v-on="on"
                 large text
                 class="custom-transform-class text-none">
            {{parameters.parameters}}
            <v-icon color="black">mdi-menu-down</v-icon>
          </v-btn>
        </template>

        <v-list flat class="px-2">
          <v-list-item class="px-1" v-if="access_parameters">
            <v-btn small block depressed plain large
                   class="mx-2 text--black px-0 custom-transform-class text-none justify-start" to="/parameters/users">{{parameters.users}}
            </v-btn></v-list-item>
          <v-list-item class="px-1"  v-if="access_role">
            <v-btn small block depressed plain large
                   class="mx-2 text--black px-0 custom-transform-class text-none justify-start" to="/parameters/roles">{{parameters.roles}}
            </v-btn></v-list-item>
          <v-list-item class="px-1"  v-if="access_company">
            <v-btn small block depressed plain large
                   class="mx-2 text--black px-0 custom-transform-class text-none justify-start" to="/parameters/companies">{{parameters.companies}}
            </v-btn></v-list-item>
        </v-list>
      </v-menu>

      <v-divider v-if="isRouteSearchable" vertical inset role="presentation"></v-divider>

      <search v-if="isRouteSearchable" :nav_bar="true"></search>

      <v-spacer></v-spacer>


      <v-btn v-if="is_admin" plain rounded class="custom-transform-class text-none mx-2" to="/conf">
        Conf
      </v-btn>

      <language></language>

      <v-menu offset-y bottom open-on-hover
              rounded="md" transition="slide-y-transition">
        <template v-slot:activator="{ attrs, on }">
          <v-chip v-bind="attrs" v-on="on"
                  outlined
                  class="font-weight-medium" style="min-width: 160px!important;">
            {{ getUserInitials }}
            <v-icon color="black">mdi-menu-down</v-icon>
          </v-chip>
        </template>

        <v-list flat>
          <v-list-item>{{ getUserEmail }}</v-list-item>
          <v-list-item>
            <v-list-item-icon class="my-4 mx-2">
              <v-icon dense>mdi-account</v-icon>
            </v-list-item-icon>
            <v-btn small plain
                   class="ml-3 text--black" to="/user">{{ profile }}</v-btn>
          </v-list-item>
          <v-list-item>
            <v-list-item-icon class="my-4 mx-2">
              <v-icon dense>mdi-location-exit</v-icon>
            </v-list-item-icon>
            <v-btn small plain
                   class="ml-3 text--black"
                   @click="logOut">{{ logout }}</v-btn>
          </v-list-item>
        </v-list>
      </v-menu>

    </v-app-bar>
  </div>
</template>

<script>
import {mapGetters} from "vuex";
import Language from "@/components/Language";
import Search from "@/components/Search";

export default {
  name: "NavBar",
  components: {
    Language,
    Search,
  },
  props: ['profile', 'logout', 'statistics', 'parameters', 'exports'],
  data() {
    return {
      bmc: require("@/assets/bmc.png"),
      delijn: require("@/assets/delijn.png"),
      sncb: require("@/assets/sncb.png"),
      stib: require('@/assets/stib.png'),
      tec: require("@/assets/tec.png"),
      tisseo: require("@/assets/tisseo.png"),
      rla: require("@/assets/rla.png"),
      nam: require("@/assets/nam.png"),
    }
  },
  computed: {
    is_admin() {
      return this.Role && this.Role.is_admin
    },
    access_role() {
      return this.Role && this.Role.access_role
    },
    access_parameters() {
      return this.Role && this.Role.access_parameters
    },
    access_statistics() {
      return this.Role && this.Role.access_statistics
    },
    access_graphics() {
      return this.Role && this.Role.access_graphics
    },
    access_export() {
      return this.Role && this.Role.access_export
    },
    access_company() {
      return this.Role && this.Role.access_company
    },
    ...mapGetters({ Role: "StateRole", Config: "StateConfig" }),
    isRouteSearchable() {
      return this.$route.name !== "Home"
    },
    isLoggedIn: function() {
      return this.$store.getters.isAuthenticated;
    },
    getUserEmail: function(){
      if (this.$store.getters.StateUser) {
        return this.$store.getters.StateUser.email;
      } else {
        return "";
      }
    },
    getUserInitials: function() {
      if (this.$store.getters.StateUser) {
        return this.$store.getters.StateUser.firstname + " " + this.$store.getters.StateUser.lastname;
      } else {
        return "";
      }
    }
  },
  methods: {
    async logOut() {
      this.$vuetify.theme.dark = false
      await this.$store.dispatch("LogOut")
      this.$router.push("/login").then(() => {})
    },
    getImgSrc() {
      return this[this.Config?.logo]
    },
  },
};
</script>

<style>
.custom-transform-class{
  text-transform: uppercase
}
#nav {
  padding: 30px;
}

#nav a {
  font-weight: bold;
  color: #2c3e50;
}

a:hover {
  cursor: pointer;
}

#nav a.router-link-exact-active {
  color: #42b983;
}
</style>
