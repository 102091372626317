<template>
	<div id="chartdiv" style="margin: 50px 100px 100px; height: 400px"/>
</template>

<script>
	import * as am4core from "@amcharts/amcharts4/core";
	import * as am4charts from "@amcharts/amcharts4/charts";
	import am4themes_animated from "@amcharts/amcharts4/themes/animated";
	import {eventBus} from '@/eventBus'

	am4core.addLicense("CH229413502");

	export default {
		name: "CrossedWeekSalesChart",
		props: {
			texts: null,
			locale: null
		},
		data() {
			return {
				xychart: null
			}
		},
		mounted() {
			eventBus.$on('graphicCreateChart', this.createChart)
		},
		methods: {
			createChart(chartData, granularity) {
				am4core.useTheme(am4themes_animated);

				if (this.xychart !== null) {
					this.xychart.dispose()
				}

				let chart = am4core.create("chartdiv", am4charts.XYChart);

				chart.colors.list = [
					am4core.color("#FF0000"),
					am4core.color("#FFFB00"),
					am4core.color("#17FF00"),
					am4core.color("#00FBFF"),
					am4core.color("#0000FF"),
					am4core.color("#FF00E4"),
					am4core.color("#FFCD00")
				];

				var dateAxis = chart.xAxes.push(new am4charts.DateAxis());
				dateAxis.baseInterval = {
					"timeUnit": "minute",
					"count": granularity
				};
				dateAxis.tooltipDateFormat = "HH:mm";
				dateAxis.title.text = this.texts.hour
				let valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
				valueAxis.title.text = this.texts.sales

				let index = 0
				chartData.forEach((item) => {
					let series = chart.series.push(new am4charts.LineSeries());
					series.dataFields.valueY = "value" + index;
					series.dataFields.dateX = "date";
					series.name = new Date(item.name).toLocaleDateString(this.locale, {weekday: "long", day: "2-digit", month: "long", year:"numeric"});
					series.tooltipText = this.texts.sales_from + " {name}: [bold]{valueY}[/]";
					//series.fill = am4core.color("red")
					//series.stroke = am4core.color("blue")

					let segment = series.segments.template;
					segment.interactionsEnabled = true;

					let hoverState = segment.states.create("hover");
					hoverState.properties.strokeWidth = 3;

					let dimmed = segment.states.create("dimmed");
					dimmed.properties.stroke = am4core.color("#dadada");

					segment.events.on("over", function(event) {
						processOver(event.target.parent.parent.parent);
					});

					segment.events.on("out", function(event) {
						processOut(event.target.parent.parent.parent);
					});

					for (let itemIndex = 0; itemIndex < item.data.length; itemIndex++) {
						item.data[itemIndex].date = new Date(item.data[itemIndex].date)
					}
					series.data = item.data;
					index++
				})

				chart.legend = new am4charts.Legend();
				chart.legend.useDefaultMarker = true;
				chart.legend.position = "top";
				chart.legend.scrollable = false;
				let markerTemplate = chart.legend.markers.template;
				markerTemplate.width = 25;
				markerTemplate.height = 10;

				chart.legend.markers.template.states.create("dimmed").properties.opacity = 0.9;
				chart.legend.labels.template.states.create("dimmed").properties.opacity = 0.9;

				chart.cursor = new am4charts.XYCursor();
				chart.cursor.lineY.opacity = 0;
				chart.cursor.lineX.opacity = 0;

				chart.legend.itemContainers.template.events.on("over", function(event) {
					processOver(event.target.dataItem.dataContext);
				})

				chart.legend.itemContainers.template.events.on("out", function(event) {
					processOut(event.target.dataItem.dataContext);
				})

				function processOver(hoveredSeries) {
					hoveredSeries.toFront();

					hoveredSeries.segments.each(function(segment) {
						segment.setState("hover");
					})

					//hoveredSeries.legendDataItem.marker.setState("default"); // Uncommenting remove the "unselected" effect
					//hoveredSeries.legendDataItem.label.setState("default");

					chart.series.each(function(series) {
						if (series !== hoveredSeries) {
							series.segments.each(function(segment) {
								segment.setState("dimmed");
							})
							series.bulletsContainer.setState("dimmed");
							series.legendDataItem.marker.setState("dimmed");
							series.legendDataItem.label.setState("dimmed");
						}
					});
				}

				function processOut() {
					chart.series.each(function(series) {
						series.segments.each(function(segment) {
							segment.setState("default");
						})
						series.bulletsContainer.setState("default");
						//series.legendDataItem.marker.setState("default"); // Uncommenting remove the "unselected" effect
						//series.legendDataItem.label.setState("default");
					});
				}

				this.xychart = chart
			},
		},
		destroyed() {
			if (this.xychart !== null)
				this.xychart.dispose()
			eventBus.$off('graphicCreateChart')
		}
	}
</script>

<style scoped>

</style>
