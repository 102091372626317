<template>
  <div class="custom mr-2">
    <v-menu rounded="md" offset-y transition="slide-y-transition" bottom left open-on-hover>
      <template v-slot:activator="{ attrs, on }">
        <v-chip v-bind="attrs" v-on="on"
                outlined
                class="font-weight-medium mt-2" :style="configFlag? '': 'min-width: 75px!important;'">
          <img v-if="configFlag"
               width="16" height="12"
              :src="getImgSrc(getIdLanguage())" :srcset="getImgSrcSet(getIdLanguage())" :alt="getImgAlt(getIdLanguage())">
          <p v-else class="mb-0">{{selectedLanguage}}</p>
        </v-chip>
      </template>

      <v-list flat>
        <v-list-item v-for="language in languages" :key="language.id">
          <v-btn text
                 class="text-capitalize"
                 @click="updateLanguage(language.language)">
            <img v-if="configFlag"
                 width="16" height="12"
                 :src="getImgSrc(language)" :srcset="getImgSrcSet(language)" :alt="getImgAlt(language)">
            <p v-else class="ma-auto">{{language.language}}</p>
          </v-btn>
        </v-list-item>
      </v-list>
    </v-menu>
  </div>
</template>

<script>
import {postJson} from "@/store/post";
import {getJson} from "@/store/get";
import {mapGetters} from "vuex";

export default {
  name: "Language",

  data() {
    return {
      items: [],
      languages: null,
    }
    },
  computed: {
    ...mapGetters({User: "StateUser", Config: "StateConfig"}),
    configFlag() {
      return this.Config.flags
    },
    selectedLanguage() {
      let response = ""
      if (this.languages) {
        this.languages.forEach(el => {
          let selLang = {
            text: el.language.charAt(0).toUpperCase() + el.language.slice(1),
            disabled: false,
            divider: false,
            flag: el.flag,
          }
          if (el.id === this.User.language_id) {
            response = el.language.charAt(0).toUpperCase() + el.language.slice(1)
          }
          this.items.push(selLang)
        })
      }
      return response
    }
    },
  created() {
    getJson("/text").then((resp) => {
      if (resp?.status === 200) {
        this.languages = resp.data.Languages
      }
    })
  },
  methods: {
    getImgSrc(item) {
      let flag = item.flag ? item.flag : item;
      return "https://flagcdn.com/16x12/" + flag + ".png"
    },
    getImgSrcSet(item) {
      return "https://flagcdn.com/32x24/"+ item.flag +".png 2x, https://flagcdn.com/48x36/"+ item.flag +".png 3x"
    },
    getImgAlt(item) {
      return item.text
    },
    getIdLanguage(){
      let resp = {flag: "gb"}
      if (this.languages == null) {
        return resp
      }
      this.languages.forEach((language) => {
        if (language.id === this.User.language_id) {
          resp = language.flag
        }
      })
      return resp
    },
    updateLanguage(language) {
      this.languages.forEach(el => {
        if (el.language.toLowerCase() === language.toLowerCase()) {
          postJson("/user/lang", {lang: el.id}).then((resp) => {
            if (resp && resp.status === 200) {
              this.User.language_id = resp.data.user.language.id
              this.User.language = resp.data.user.language
              this.$store.commit('setLocale', resp.data.locale)
            }
          })
        }
      })
    },
  }
}
</script>

<style scoped>
.custom {
  position: relative;
  top: -14px;
  height:20px;
  width:auto;
  background-color: rgba(248, 249, 250, 0);
}
</style>
