import store from "@/store/index";
import {vueAppAPI} from '@/env'
const axios = require('axios').default;

export function globalJson(method, url, data, responseType= null) {
    return axios({
        method: method,
        url: vueAppAPI + url,
        data: data,
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Digimobee ' + store.getters.StateToken
        },
        responseType: responseType,
        maxContentLength: Infinity,
        maxBodyLength: Infinity,
    })
}
