import store from "@/store";

function checkGlobalAccess (to, from, next, access) {
    if (store.getters.StateUser.role[access]) {
        next();
    } else {
        next("/");
    }
}

export function checkAccessParameters (to, from, next) {
    return checkGlobalAccess(to, from, next, 'access_parameters')
}

export function checkAccessRole (to, from, next) {
    return checkGlobalAccess(to, from, next, 'access_role')
}

export function checkAccessCompany (to, from, next) {
    return checkGlobalAccess(to, from, next, 'access_company')
}

export function checkAccessStatistics (to, from, next) {
    return checkGlobalAccess(to, from, next, 'access_statistics')
}

export function checkAccessGraphics (to, from, next) {
    return checkGlobalAccess(to, from, next, 'access_graphics')
}

export function checkAccessExport (to, from, next) {
    return checkGlobalAccess(to, from, next, 'access_export')
}

export function checkAccessCustomerCard (to, from, next) {
    return checkGlobalAccess(to, from, next, 'access_customer_card')
}
