const state = {
    offset: 0
};

const getters = {
    StateOffset: (state) => state.offset,
};

const mutations = {
    setOffset(state, newOffset) {
        state.offset = newOffset
    }
};

export default {
    state,
    getters,
    mutations,
};
