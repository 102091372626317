<template>
  <v-dialog v-model="getShowGranted"
            persistent
            transition="dialog-top-transition" max-width="800">
    <v-card>
      <v-toolbar max-height="15" :color="getColor"></v-toolbar>
      <v-card-text>
        <div class="text-center text-h2 pa-8">{{ getText }}</div>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "GrantedDialog",
  props: ['show_granted', 'text', 'color'],
  computed:{
    getShowGranted() {
      return this.show_granted
    },
    getText() {
      return this.text
    },
    getColor() {
      return this.color
    },
  },
  methods: {
    setShowGranted(value) {
      this.show_granted = value
    }
  }
}
</script>
