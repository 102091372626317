<template>
  <v-container fluid v-if="User">
    <v-row>
      <v-col align="center">
        <div class="mt-6">
          <p class="text-h2">{{ texts.title }}<v-text-field v-model="title" :label="texts.title" filled v-if="canModifyText" style="width: 30%" /> :
            <span class="text-capitalize">{{ User.firstname }} </span>
            <span class="text-uppercase">{{ User.lastname }}</span>
          </p>
        </div>
        <div>
          <v-card :loading="loading" class="mx-auto my-12 py-0" max-width="40%" elevation="0">
            <v-card-text>
              <v-row>
                <v-col>
                  {{ texts.label_calypso }}<v-text-field v-model="label_calypso" :label="texts.label_calypso" filled v-if="canModifyText" style="width: 30%" />
                  <v-select v-model="selectedHexa" :items="items" outlined dense @change="updateHexa"/>
                </v-col>
                <v-col v-if="easter">{{ texts.label_theme }}<v-select v-model="selectedTheme" :items="itemsTheme" outlined dense @change="updateTheme"/></v-col>
                <v-col v-if="access_modify_text">
                  {{ texts.label_modifying_text }}<v-text-field v-model="label_modifying_text" :label="texts.label_modifying_text" filled v-if="canModifyText" style="width: 30%" />
                  <v-select v-model="selectedText" :items="[false, true]" outlined dense @change="updateModifyText"/>
                </v-col>
              </v-row>
              <v-text-field v-model="oldPassword" :label="texts.oldPassword" type="password" name="old_password"/>
              <v-text-field v-model="newPassword" :label="texts.newPassword" type="password" name="old_password"/>
              <v-text-field v-model="confirmationPassword" :label="texts.confirmationPassword" type="password" name="old_password"/>
              <v-btn class="mt-4 text-capitalize" outlined large color="blue"
                     type="submit" @click="updatePassword" :disabled="disableButton">{{ texts.save }}</v-btn>
            </v-card-text>
          </v-card>
        </div>
      </v-col>
    </v-row>

    <granted-dialog :show_granted="showGranted" :text="texts.granted" :color="showColor"/>
    <granted-dialog :show_granted="showError" :text="texts.error" :color="errorColor"/>
  </v-container>
</template>



<script>
import GrantedDialog from "@/components/GrantedDialog";
import { mapGetters } from "vuex";
import { postJson } from "@/store/post";
import { getJson } from "@/store/get";
import {canModifyText, updateText} from "@/utils";

export default {
  name: "User",
  components: {
    GrantedDialog,
  },
  data() {
    return {
      items: ["Hexa", "Deci"],
      itemsTheme: ["Light", "Dark"],
      languages: null,
      showColor: "green accent-4",
      showGranted: false,
      showError: false,
      errorColor: "red accent-4",
      oldPassword: null,
      newPassword: null,
      confirmationPassword: null,
      loading: false,
      loadingColor: "blue",
      selectedHexa: null,
      selectedTheme: null,
      selectedText: null,

      title: null,
      label_calypso: null,
      label_modifying_text: null,
      texts: {
        language: null,
        title: null,
        oldPassword: null,
        newPassword: null,
        confirmationPassword: null,
        save: null,
        granted: null,
        error: null
      },
      keyboardMap: {},
      easter: false,
    };
  },
  computed: {
    ...mapGetters({ User: "StateUser", Role: "StateRole" }),
    disableButton() {
      return (this.oldPassword?.length < 2
          || this.newPassword !== this.confirmationPassword
          || !this.newPassword?.match(/(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{14,})/));
    },
    access_modify_text() {
      return this.Role?.access_modify_text
    },
    canModifyText() {
      return canModifyText(this.Role, this.User)
    }
  },
  created() {
    window.addEventListener("keydown", this.keydown);
    window.addEventListener("keyup", this.keyup);
    this.getTexts()
    if (!this.User?.theme) {
      this.selectedTheme = this.itemsTheme[0]
    } else {
      this.selectedTheme = this.itemsTheme[1]
    }
    setTimeout(() => {
      this.selectedHexa = this.User?.hexa.charAt(0).toUpperCase() + this.User?.hexa.slice(1)
      this.selectedText = this.User?.modifying_text
    }, 0)
  },
  destroyed() {
    window.removeEventListener("keydown", this.keydown);
    window.removeEventListener("keyup", this.keyup);
  },
  methods: {
    keyup(event) {
      this.keyboardMap[event.key] = false
    },
    keydown(event) {
      this.keyboardMap[event.key] = true
      if (this.keyboardMap['d'] && this.keyboardMap['j'] && this.keyboardMap['m'] && this.keyboardMap['b']) {
        this.easter = !this.easter
      }
    },
    getTexts() {
      getJson("/text/user/"+this.User?.language.id).then((resp) =>{
        if (resp?.status === 200) {
          this.texts = resp.data.UserView[0]
        }
      })
    },
    updatePassword() {
      this.loading = this.loadingColor
      let updatePwd = {
        "email": this.User?.email,
        "oldPassword": this.oldPassword,
        "newPassword": this.newPassword,
        "confirmationPassword": this.confirmationPassword
      }
      postJson("/auth/update-password", updatePwd).then((resp) => {
        if (resp?.status === 200) {
          this.showGranted = true
          let user = {
            "email": updatePwd.email,
            "password": updatePwd.newPassword
          }
          this.commit('setUser', user)
        } else {
          this.oldPassword = ""
          this.newPassword = ""
          this.confirmationPassword = ""
          this.showError = true
        }
      }).catch(() => {}).then(() => {
        this.loading = false
        this.oldPassword = ""
        this.newPassword = ""
        this.confirmationPassword = ""
        setTimeout(() => {this.showGranted = false}, 1500)
        setTimeout(() => {this.showError = false}, 1500)
      })
    },
    updateHexa() {
      const hex_dec = this.selectedHexa.charAt(0).toLowerCase() + this.selectedHexa.slice(1)
      postJson("/user/hexa", {hexa: hex_dec}).then((resp) => {
        if (resp?.status === 200) {
          this.$store.commit('setUser', resp.data.user)
        }
      })
    },
    updateTheme() {
      postJson("/user/theme", {theme: this.selectedTheme !== this.itemsTheme[0]}).then((resp) => {
        if (resp?.status === 200) {
          this.$vuetify.theme.dark = this.selectedTheme !== this.itemsTheme[0]
          this.User.theme = this.selectedTheme !== this.itemsTheme[0]
        }
      })
    },
    updateModifyText() {
      postJson("/user/modifying-text", {modifying_text: this.selectedText}).then((resp) => {
        if (resp?.status === 200) {
          this.User.modifying_text = this.selectedText
        }
      })
    }
  },
  watch: {
    "User.language": function () {
      this.getTexts()
    },
    title() {
      updateText(this, "user", "title", this.User?.language_id)
    },
    label_calypso() {
      updateText(this, "user", "label_calypso", this.User?.language_id)
    },
    label_modifying_text() {
      updateText(this, "user", "label_modifying_text", this.User?.language_id)
    }
  }
}
</script>
